var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.filled && _vm.value
      ? _c("div", [
          _c("audio", {
            attrs: {
              src: _vm.value,
              controls: "controls",
              controlslist: "nodownload",
              oncontextmenu: "return false",
            },
          }),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    icon: "el-icon-delete",
                    size: "mini",
                  },
                  on: { click: _vm.clearFilled },
                },
                [_vm._v("重录")]
              ),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "record-box" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                width: "100%",
                "align-items": "center",
              },
            },
            [
              _vm.state === 0 || _vm.state === 1 || _vm.playing
                ? _c("div", { staticClass: "line-box" }, [
                    _c("div", {
                      staticClass: "line-item",
                      style: "height: " + _vm.line[0] + "%",
                    }),
                    _c("div", {
                      staticClass: "line-item",
                      style: "height: " + _vm.line[1] + "%",
                    }),
                    _c("div", {
                      staticClass: "line-item",
                      style: "height: " + _vm.line[2] + "%",
                    }),
                  ])
                : _vm._e(),
              _vm.state === 2 && !_vm.playing
                ? _c("div", { staticClass: "line-box" }, [
                    _c("i", {
                      staticClass: "el-icon-success",
                      staticStyle: { color: "#1aac1a", "font-size": "20px" },
                    }),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "title-box" }, [
                _c("span", [_vm._v(_vm._s(_vm.msg))]),
              ]),
            ]
          ),
          _c("div", { staticStyle: { "font-size": "24px" } }, [
            _vm._v(_vm._s(_vm.formatSec())),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("el-button", {
                staticStyle: { "font-size": "32px" },
                attrs: {
                  disabled: _vm.state === 2,
                  type: _vm.state === 1 ? "danger" : "primary",
                  size: "large",
                  icon: "el-icon-mic",
                  circle: "",
                },
                on: { click: _vm.startRecord },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "opt-box" },
            [
              _c("el-button", {
                attrs: {
                  disabled: _vm.state !== 2,
                  type: _vm.state === 2 ? "danger" : "default",
                  plain: "",
                  icon: "el-icon-delete-solid",
                  circle: "",
                },
                on: { click: _vm.clearRecord },
              }),
              _c("el-button", {
                attrs: {
                  disabled: _vm.state !== 2,
                  type: _vm.state === 2 ? "primary" : "default",
                  icon: _vm.playing
                    ? "el-icon-video-pause"
                    : "el-icon-caret-right",
                  plain: "",
                  circle: "",
                },
                on: { click: _vm.playRecord },
              }),
              _c("el-button", {
                attrs: {
                  disabled: _vm.state !== 2,
                  loading: _vm.loading,
                  type: _vm.state === 2 ? "success" : "default",
                  plain: "",
                  icon: "el-icon-upload",
                  circle: "",
                },
                on: { click: _vm.saveRecord },
              }),
            ],
            1
          ),
          _vm.tips
            ? _c(
                "div",
                { staticStyle: { "font-size": "12px", "padding-top": "20px" } },
                [_vm._v(_vm._s(_vm.tips))]
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }