<template>
  <div class="img-container">
    <div v-for="(item,index) in dataList" :key="index" class="img-box">
      <el-image
        :src="dataKey?item[dataKey]:item"
        :preview-src-list="imageList"
        style="width: 200px; height: 150px; background: #000"
        fit="scale-down"/>
      <div class="img-append">
        <slot :item="item" name="append" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImagePreview',
  // 组件入参
  props: {
    dataList: Array,
    dataKey: String
  },
  data() {
    return {
      imageList: []
    }
  },
  watch: {

    // 检测查询变化
    dataList: {
      handler() {
        this.genPreview()
      },
      deep: true
    }
  },
  created() {
    this.genPreview()
  },
  methods: {

    /**
     * 添加数据跳转
     */
    genPreview() {
      // 列表中的数据
      const key = this.dataKey

      if (this.dataList && this.dataList.length> 0) {
        for (let i = 0; i<this.dataList.length; i++) {
          const item = this.dataList[i]
          if (!key) {
            this.imageList.push(item)
          } else {
            this.imageList.push(item[key])
          }
        }
      }
    }

  }
}
</script>

<style scoped>

.img-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.img-box{
  width: 200px;
  height: 176px;
  background: #eee;
  line-height: 2px !important;
  overflow: hidden;
  margin-bottom: 10px;
}

.img-append{
  line-height: 26px !important;
  text-align: center;
  font-size: 12px;
  color: #666;
}
</style>
