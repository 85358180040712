<template>
  <div style="padding-right: 20px">

    <div style="padding-bottom: 5px;" class="flex-bt">
      <div>
        <el-button icon="el-icon-plus" plain circle size="mini" type="primary" @click="showBatchAdd"/>
        <el-button icon="el-icon-delete" plain circle size="mini" type="danger" @click="handleDelete"/>
      </div>

      <el-button icon="el-icon-refresh" plain circle size="mini" type="info" @click="clearCheck"/>
    </div>

    <el-divider />

    <el-tree
      ref="tree"
      :data="treeData"
      :expand-on-click-node="false"
      :default-checked-keys="[checkedId]"
      :check-strictly="true"
      empty-text="点击上方+来创建目录"
      draggable
      check-on-click-node
      show-checkbox
      node-key="id"
      default-expand-all
      highlight-current
      @node-drag-end="handleDragEnd"
      @node-click="checkChange">
      <div slot-scope="{ node, data }" class="tree-node">
        <div class="title">
          <el-input v-model="data.title" size="mini" @change="handleUpdate(data.id, data.title)"/>
        </div>
        <div class="opt">
          <i class="el-icon-plus" title="添加下级" @click="addSubDir(data.id)"/>
        </div>

      </div>
    </el-tree>


    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="批量添加章节"
      width="500px"
    >

      <el-form ref="postForm" :model="postForm" :rules="rules" label-position="top" label-width="120px">

        <el-form-item label="章节数据" prop="data">
          <el-input v-model="postForm.data" type="textarea" rows="10" placeholder="支持批量添加，每个层级进行换行，树结构使用'-'标识，'-'表示2级目录，'--'表示3级目录，依次类推" />
          <el-button type="text" icon="el-icon-search" size="mini" @click="fillDemo">填充演示数据</el-button>
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>
import { saveBatch, dirTree, deleteDir, updateDir, addDir, sortDir } from '@/api/media/catalog'

export default {
  name: 'MediaCatalogEdit',
  components: { },
  props: {
    spaceId: { type: String }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      postForm: {
        data: ''
      },
      rules: {},
      demoData: '一级目录\n' +
        '-二级目录\n' +
        '--三级目录',
      treeData: [],
      checkedId: null
    }
  },

  watch: {
    // 属性变化
    spaceId: {
      handler() {
        this.showDirTree()
      }
    },
    // 属性变化
    dirId: {
      handler(val) {
        this.checkedId = val
      }
    },
    // 属性变化
    checkedId: {
      handler(val) {
        this.$emit('checked', val)
      }
    }
  },

  created() {
    this.checkedId = this.dirId
    this.showDirTree()
  },

  methods: {

    // 加载树结构
    showDirTree() {
      dirTree({ id: this.spaceId }).then(res => {
        this.treeData = res.data
      })
    },

    // 批量添加
    showBatchAdd() {
      this.dialogVisible = true
    },

    // 填充演示数据
    fillDemo() {
      this.postForm.data = this.demoData
    },

    // 保存数据
    handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.postForm.spaceId = this.spaceId
        saveBatch(this.postForm).then(res => {
          this.$message.success('目录添加成功！')
          this.treeData = res.data
          this.postForm.data = ''
          this.dialogVisible = false
        })
      })
    },
    handleDelete() {
      // 获得全部选中的节点
      const ids = []
      const allNodes = this.$refs.tree.getCheckedNodes(false, false)

      if (allNodes.length === 0) {
        this.$message.error('请先选择要删除的章节！')
        return
      }

      for (let i = 0; i<allNodes.length; i++) {
        ids.push(allNodes[i].id)
      }
      const data = { spaceId: this.spaceId, ids: ids }
      // 保存菜单权限
      deleteDir(data).then(() => {
        this.$message.success('删除成功！')
        this.showDirTree()
      })
    },
    handleUpdate(id, title) {
      updateDir({ id: id, title: title }).then(() => {
        this.$message.success('修改成功！')
      })
    },
    addSubDir(parentId) {
      this.$prompt('添加下级类目', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        addDir({ parentId: parentId, spaceId: this.spaceId, title: value }).then(() => {
          this.$message.success('目录添加成功！')
          this.showDirTree()
        })
      })
    },
    checkChange(data) {
      this.checkedId = data.id
    },
    clearCheck() {
      this.checkedId = ''
      // 移除高亮效果
      const current = document.querySelector('.is-current')
      if (current) {
        current.className = 'el-tree-node is-expanded is-focusable'
      }
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log('draggingNode: ', draggingNode)
      console.log('dropNode: ', dropNode, dropType)
      const data = { formId: draggingNode.data.id, toId: dropNode.data.id, type: dropType }
      console.log('++++++ post data', data)

      sortDir(data).then(() => {
        this.$message.success('排序成功！')
        this.showDirTree()
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-tree-node__content {
  line-height: 32px;
  height: 40px;
}

.tree-node{
  display: flex;
  align-items: center;
  width: 100%;
}

.tree-node > .title{
  flex-grow: 1;
  font-size: 14px;
  font-weight: 500;
  color: #9E2622;
}

.tree-node > .opt{
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  color: #666;
}
</style>
