<template>

  <div class="flex-bt" style="line-height: 28px">
    <div style="flex-grow: 1;">
      <div class="font-title">
        <a class="c-link" @click="toDetail(data.courseId)">{{ data.courseName }}</a>
      </div>
      <div class="font-small">
        {{ data.fileName }}
      </div>
    </div>
    <div style="width: 200px; text-align: right">
      <el-link type="danger" icon="el-icon-d-arrow-right" @click="toLearn(data.courseId, data.fileId)">继续学习</el-link>
      <div class="font-small">{{ data.updateTime || data.createTime }}</div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'LearnPsCard',
  props: {
    data: Object
  },
  data() {
    return {
    }
  },
  methods: {
    toLearn(courseId, fileId) {
      this.$router.push({ name: 'CourseLearn', query: { courseId, fileId, focus: 'last' }})
    },

    toDetail(courseId) {
      this.$router.push({ name: 'CourseView', params: { courseId: courseId }})
    }
  }
}
</script>

<style scoped>

</style>
