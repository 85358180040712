<template>

  <div>

    <!-- 类目及轮播图 -->
    <div style="display: flex;">
      <home-catalog/>
      <home-banner/>
    </div>

    <!-- 推荐课程 -->
    <div>
      <home-course />
    </div>

    <!-- 讲师及公告 -->
    <div style="display: flex;">
      <home-lecturer />
      <home-notice/>
    </div>


  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import HomeBanner from '@/views/pages/home/components/HomeBanner/index.vue'
import HomeCatalog from '@/views/pages/home/components/HomeCatalog/index.vue'
import HomeCourse from '@/views/pages/home/components/HomeCourse/index.vue'
import HomeLecturer from '@/views/pages/home/components/HomeLecturer/index.vue'
import HomeNotice from '@/views/pages/home/components/HomeNotice/index.vue'

export default {
  name: 'Home',
  components: { HomeNotice, HomeLecturer, HomeCourse, HomeCatalog, HomeBanner },
  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  created() {

  },
  methods: {
    showBanner() {

    }
  }
}
</script>

<style scoped>


</style>

