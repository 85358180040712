var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "guide", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "flex-end" } },
              [
                _c("h3", { staticStyle: { "flex-grow": "1" } }, [
                  _vm._v("统计总览"),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "12px",
                      "padding-bottom": "5px",
                      color: "#666",
                    },
                  },
                  [_vm._v("系统版本：" + _vm._s(_vm.version))]
                ),
              ]
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-card", { staticClass: "data-out" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-around",
                    },
                  },
                  [
                    _vm.siteData.props.moduleExam && _vm.stat.examCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("考试场数"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "ExamList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.examCount,
                                      duration: 1500,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.moduleExam && _vm.stat.repoCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("题库数量"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "RepoList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.repoCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.moduleExam && _vm.stat.quCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("试题总数"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "QuList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.quCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.moduleCourse && _vm.stat.courseCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("课程总数"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "CourseList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.courseCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.moduleCourse && _vm.stat.fileCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("课件总数"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "CourseFileList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.fileCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.moduleBattle && _vm.stat.battleCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("竞赛数量"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "BattleList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.battleCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.siteData.props.moduleCert && _vm.stat.grantCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("发证数量"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "CertGrantList" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.grantCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.stat.userCount
                      ? _c("div", { staticClass: "data-box" }, [
                          _c("div", { staticClass: "data-title" }, [
                            _vm._v("用户总数"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "data-content" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "SysUser" } } },
                                [
                                  _c("count-to", {
                                    attrs: {
                                      "start-val": 0,
                                      "end-val": _vm.stat.userCount,
                                      duration: 2000,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }