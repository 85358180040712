<template>

  <el-select
    v-model="currentValue"
    :remote-method="fetchData"
    filterable
    remote
    clearable
    placeholder="选择或搜索课程"
    class="filter-item"
    size="small"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in dataList"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>

</template>

<script>

import { fetchUserPaging } from '@/api/course/course'

export default {
  name: 'UserCourseSelect',
  props: {
    multi: Boolean,
    value: String,
    openType: Number
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      }
    }
  },
  created() {
    this.currentValue = this.value
    this.fetchData()
  },
  methods: {

    fetchData(v) {
      const params = { current: 1, size: 100, params: { openType: this.openType, title: v }}
      fetchUserPaging(params).then(res => {
        this.dataList = res.data.records
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>
