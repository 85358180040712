import { post } from '@/utils/request'


/**
 * 批量更改账户
 * @param data
 */
export function batchUpdate(data) {
  return post('/api/lecturer/account/batch-update', data)
}

/**
 * 金额发放
 * @param data
 */
export function giveAmount(data) {
  return post('/api/lecturer/account/give-amount', data)
}

/**
 * 金额扣减
 * @param data
 */
export function takeAmount(data) {
  return post('/api/lecturer/account/take-amount', data)
}

/**
 * 账户总览
 * @param data
 */
export function accountPreview() {
  return post('/api/lecturer/account/preview', {})
}


/**
 * 提交提现操作
 * @param data
 */
export function cashApply(data) {
  return post('/api/lecturer/account/cash', data)
}
