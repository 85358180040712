var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fav != null
    ? _c(
        "span",
        {
          style: "font-size: " + _vm.size + "; cursor: pointer",
          attrs: { title: "收藏本课程" },
          on: { click: _vm.handleFav },
        },
        [
          _vm.fav
            ? _c("svg-icon", { attrs: { "icon-class": "favor_yes" } })
            : _c("svg-icon", { attrs: { "icon-class": "favor_no" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }