<template>

  <div>

    <el-dialog
      :title="type===0?'解绑手机':'绑定手机'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @close="handleClose"
    >
      <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="100px">

        <el-form-item prop="mobile" label="手机号码">
          <el-input
            v-model="postForm.mobile"
            :disabled="type===0"
            style="width: 100%"
            placeholder="手机号码"
          />
        </el-form-item>

        <el-form-item prop="smsCode" label="短信验证码">
          <sms-input ref="sms" v-model="postForm" :type="type===1?1:2" />
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="type === 1" :loading="loading" type="primary" @click="doBind">确认绑定</el-button>
        <el-button v-if="type === 0" :loading="loading" type="danger" @click="unBind">确认解绑</el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>

import { bindMobile, unBindMobile } from '@/api/sys/user/bind'

export default {
  name: 'BindMobile',
  components: { },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mobile: String,
    // 1绑定，0解绑
    type: Number
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      postForm: {
        mobile: '',
        smsCode: '',
        captchaKey: '',
        captchaValue: ''
      },
      rules: {
        smsCode: [{ required: true, message: '短信验证码不能为空' }],
        mobile: [{ required: true, message: '手机号不能为空' }]
      }
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    },
    mobile: {
      handler(val) {
        this.postForm.mobile = val
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {

    // 绑定手机
    doBind() {
      this.$refs.postForm.validate().then((valid) => {
        if (!valid) {
          return
        }

        this.loading = true

        bindMobile(this.postForm).then(() => {
          this.handleSuccess('手机号绑定成功！')
        }).catch(() => {
          this.loading = false
        })
      })
    },

    // 解绑手机
    unBind() {
      this.$refs.postForm.validate().then((valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        unBindMobile(this.postForm).then(() => {
          this.handleSuccess('手机号解绑成功！')
        }).catch(() => {
          this.loading = false
        })
      })
    },

    handleClose() {
      this.$emit('update:visible', false)
    },

    handleSuccess(msg) {
      this.loading = false
      this.$message.success(msg)
      this.$emit('update:visible', false)
      this.$emit('success')
      this.postForm.mobile = ''
      this.postForm.smsCode = ''
      this.postForm.captchaKey = ''
      this.postForm.captchaValue = ''
    }

  }
}
</script>

<style scoped>

</style>
