// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/web/cert-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.cert-box[data-v-0183f548]{\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;\n  background-size: 100% 100%;\n  border: #e3a949 1px solid;\n  padding: 10px;\n  border-radius: 5px;\n  margin: -10px;\n}\n[data-v-0183f548] .grid-box-inner{\n  border: none;\n}\n\n", ""]);
// Exports
module.exports = exports;
