import { post } from '@/utils/request'

export function fetchShowDetail(id) {
  return post('/api/mall/goods/detail', { id: id })
}

export function mapSku(data) {
  return post('/api/mall/goods/map-sku', data)
}

export function fetchDetail(id) {
  return post('/api/mall/goods/admin/detail', { 'id': id })
}

export function saveData(data) {
  return post('/api/mall/goods/admin/save', data)
}

// 根据参数或值查询SKU列表
export function listSku(data) {
  return post('/api/mall/goods/admin/list-sku', data)
}
