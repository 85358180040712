import { post } from '@/utils/request'

// 后台发货
export function sendGoods(data) {
  return post('/api/mall/order/admin/send', data)
}

export function fetchDetail(id) {
  return post('/api/mall/order/admin/detail', { id: id })
}

export function previewOrder(data) {
  return post('/api/mall/order/preview', data)
}

export function submitOrder(data) {
  return post('/api/mall/order/submit', data)
}

export function fetchExpress(id) {
  return post('/api/mall/order/express', { id: id })
}

export function confirmOrder(id) {
  return post('/api/mall/order/confirm', { id: id })
}

export function cancelOrder(id) {
  return post('/api/mall/order/cancel', { ids: [id] })
}

export function adminCancel(ids) {
  return post('/api/mall/order/admin/cancel', { ids: ids })
}

export function deleteOrder(id) {
  return post('/api/mall/order/delete', { ids: [id] })
}

export function adminUpdate(data) {
  return post('/api/mall/order/admin/update', data)
}

export function orderDetail(id) {
  return post('/api/mall/order/detail', { id: id })
}

