<template>

  <el-select
    v-model="currentValue"
    :disabled="disabled"
    :remote-method="fetchData"
    filterable
    size="small"
    remote
    clearable
    placeholder="选择或搜索讲师"
    class="filter-item"
    style="width: 100%;"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in dataList"
      :key="item.id"
      :label="item.realName"
      :value="item.id"
    />
  </el-select>

</template>

<script>

import { paging } from '@/api/lecturer/lecturer'

export default {
  name: 'LecturerSelect',
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      }
    }
  },
  created() {
    this.currentValue = this.value
    this.fetchData('')
  },
  methods: {

    fetchData(e) {
      const params = { current: 1, size: 100, params: { realName: e }}
      paging(params).then(response => {
        this.dataList = response.data.records
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>
