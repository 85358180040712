import { post } from '@/utils/request'

/**
 * 获取订单状态
 * @param id
 * @returns {Promise}
 */
export function checkOrder(id) {
  return post('/api/pay/order/detail', { id: id })
}
