<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="选择课件"
    width="80%"
    top="5vh"
    @close="handleClose"
  >

    <div style="display: flex; padding: 0 20px">
      <div style="width: 300px">
        <el-card>
          <dic-tree
            ref="tree"
            v-model="listQuery.params.catId"
            dic-code="course_file_catalog" />
        </el-card>
      </div>
      <div style="flex-grow: 1; padding-left: 20px; overflow-x: auto">


        <data-table
          ref="pagingTable"
          :options="options"
          :list-query="listQuery"
          @select-changed="handleSelected"
        >
          <template #filter-content>
            <el-input v-model="listQuery.params.title" style="width: 200px" placeholder="搜索课件名称" class="filter-item" size="small" />
            <dic-list-select v-model="listQuery.params.fileType" dic-code="course_file_type" title="课件类型"/>
            <dic-list-select v-model="listQuery.params.state" dic-code="course_state" title="课件状态"/>
            <el-button :disabled="selectedList.length === 0" type="primary" size="small" class="filter-item" style="float:  right" @click="handleConfirm">{{ selectedLabel }}</el-button>
          </template>

          <template #data-columns>

            <el-table-column
              label="课件名称"
              prop="title"
              show-overflow-tooltip
            />

            <el-table-column
              label="课件大小"
              align="center"
              prop="fileSize"
              width="120px"
            />

            <el-table-column
              label="课件时长"
              align="center"
              width="120px"
            >

              <template v-slot="scope">
                <span v-if="scope.row.duration===0">无</span>
                <span v-else>
                  <sec-format :value="scope.row.duration"/>
                </span>
              </template>

            </el-table-column>

            <el-table-column
              label="课件类型"
              align="center"
              prop="fileType_dictText"
              width="120px"
            />

            <el-table-column
              label="状态"
              align="center"
              width="120px"
            >

              <template v-slot="scope">
                <el-tag v-if="scope.row.state===0" size="mini" type="success">正常</el-tag>
                <el-tag v-if="scope.row.state===1" size="mini" type="warning">转码中</el-tag>
                <el-tag v-if="scope.row.state===2" size="mini" type="danger">转码失败</el-tag>
              </template>

            </el-table-column>

          </template>

        </data-table>

      </div>

    </div>
  </el-dialog>

</template>

<script>
export default {
  name: 'CourseFileDialog',
  props: {
    excludes: Array,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      dialogVisible: false,
      selectedLabel: '请勾选课件',

      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: '0',
          excludes: []
        }
      },

      // 选定的数据列表
      selectedList: [],

      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        // 列表请求URL
        listUrl: '/api/course/file/paging',
        // 是否支持选定
        selectable: this.checkSelectable
      }
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
        if (this.dialogVisible && this.$refs.pagingTable !== undefined) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
      },
      deep: true
    },

    selectedList: {
      handler(val) {
        if (val.length > 0) {
          this.selectedLabel = '确认' + val.length + '项'
        } else {
          this.selectedLabel = '请勾选课件'
        }
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {

    // 选定回调
    handleSelected(obj) {
      this.selectedList = obj.objs
    },

    // 关闭回调
    handleClose() {
      this.$emit('update:visible', false)
    },

    // 确认选定
    handleConfirm() {
      this.$emit('update:visible', false)
      this.$emit('select', this.selectedList)
      this.$refs.pagingTable.clearSelection()
    },

    checkSelectable(row) {
      return row.state === 0
    }

  }
}
</script>

<style scoped>

  ::v-deep
  .el-dialog__body{
    padding: 0;
  }
</style>
