var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail != null && _vm.detail.id != null
    ? _c(
        "div",
        [
          _c("div", { staticClass: "title-box" }, [
            _c(
              "div",
              { staticClass: "img" },
              [_c("el-image", { attrs: { src: _vm.detail.cover } })],
              1
            ),
            _c("div", { staticClass: "desc" }, [
              _c("div", { staticClass: "flex-bt" }, [
                _c("div", { staticStyle: { "line-height": "30px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "font-title",
                      staticStyle: {
                        "padding-bottom": "6px",
                        "font-size": "20px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.detail.title))]
                  ),
                  _c("div", { staticClass: "font-second" }, [
                    _vm._v("共" + _vm._s(_vm.detail.fileCount) + "节课"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "font-second c-link",
                      on: {
                        click: function ($event) {
                          return _vm.toLecturerHome(_vm.detail.lecturerId)
                        },
                      },
                    },
                    [_vm._v(" 讲师：" + _vm._s(_vm.detail.lecturerId_dictText))]
                  ),
                ]),
                _c(
                  "div",
                  [_c("course-fav", { attrs: { "course-id": _vm.detail.id } })],
                  1
                ),
              ]),
              _c("div", { staticClass: "price-box" }, [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("价格")]),
                  _c("div", { staticClass: "price-show" }, [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("￥"),
                    ]),
                    _vm._v(_vm._s(_vm.detail.price)),
                  ]),
                ]),
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("有效期")]),
                  _c("div", { staticClass: "black2" }, [
                    _vm._v("自购买起"),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#C6302B", "font-weight": "700" },
                      },
                      [_vm._v(_vm._s(_vm.detail.expireDays))]
                    ),
                    _vm._v("天内"),
                  ]),
                ]),
              ]),
              _vm.buyCheck
                ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _vm.buyCheck.hasBuy
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "180px" },
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toLearn("")
                                  },
                                },
                              },
                              [
                                _vm._v(" 去学习 "),
                                _c("i", {
                                  staticClass:
                                    "el-icon-video-play el-icon--right",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "180px" },
                                attrs: { type: "danger" },
                                on: { click: _vm.handleBuy },
                              },
                              [
                                _vm._v(" 立即购买"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-shopping-cart-1 el-icon--right",
                                }),
                              ]
                            ),
                            _vm.buyCheck.tryLearn
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "180px" },
                                    attrs: { type: "warning" },
                                    on: { click: _vm.toLearn },
                                  },
                                  [
                                    _vm._v(" 立即试学"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-thumb el-icon--right",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "detail-box" }, [
            _c(
              "div",
              { staticClass: "files" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.tabActive,
                      callback: function ($$v) {
                        _vm.tabActive = $$v
                      },
                      expression: "tabActive",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "课程介绍", name: "detail" } },
                      [
                        _c("div", { staticClass: "content" }, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.formatRichText(
                                  _vm.detail.content
                                )
                              ),
                            },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "课程目录", name: "dir" } },
                      _vm._l(_vm.detail.dirList, function (dir) {
                        return _c("course-dir-show", {
                          key: dir.id,
                          attrs: {
                            data: dir,
                            level: 1,
                            "course-id": _vm.courseId,
                          },
                          on: { select: _vm.toLearn },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "课程评价", name: "score" } },
                      [
                        _c("course-score", {
                          attrs: { "course-id": _vm.courseId },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "help" },
              [
                _c(
                  "el-collapse",
                  { attrs: { value: "1" } },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "如何查看已购课程？", name: "1" } },
                      [
                        _c("div", [
                          _vm._v(
                            "您可以在课程详情页直接跳转到学习，或者在个人中心左侧菜单，点击已购课程进行查看和学习；"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "哪里可以试学课程？", name: "2" } },
                      [
                        _c("div", [
                          _vm._v(
                            "在课程详情页面，如果出现了试学标志，则表示当前课程支持试学，您可以通过试学确定是否适合学习该课程。"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "课程过期了怎么办？", name: "3" } },
                      [
                        _c("div", [
                          _vm._v(
                            "课程有效期一般为1年，到期后将无法学习，一般情况下，您需要再次购买，当然，您可以联系客服获取一个专属的优惠券。"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("course-buy", {
            attrs: { visible: _vm.payVisible, "course-id": _vm.courseId },
            on: {
              "update:visible": function ($event) {
                _vm.payVisible = $event
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }