import { post } from '@/utils/request'

/**
 * 推送
 * @param data
 * @returns {Promise}
 */
export function saveBatch(data) {
  return post('/api/home/recommend/save-batch', data)
}


/**
 * 保存单个
 * @param data
 * @returns {Promise}
 */
export function save(data) {
  return post('/api/home/recommend/save', data)
}

/**
 * 查找课程类型的推荐
 * @param data
 * @returns {Promise}
 */
export function listCourse(data) {
  return post('/api/home/recommend/list-course', data)
}

/**
 * 查找讲师类型的推荐
 * @param data
 * @returns {Promise}
 */
export function listLecturer(data) {
  return post('/api/home/recommend/list-lecturer', data)
}


