<template>

  <span>
    <svg-icon v-if="value==='video'" title="视频文件" icon-class="file-video"/>
    <svg-icon v-else-if="value==='pdf'" icon-class="file-pdf"/>
    <svg-icon v-else-if="value==='office'" icon-class="file-office"/>
    <svg-icon v-else-if="value==='image'" icon-class="file-image"/>
    <svg-icon v-else icon-class="file-other"/>
  </span>
</template>

<script>


export default {
  name: 'FileIconShow',
  props: {
    value: String
  },
  data() {
    return {

    }
  }
}
</script>
