<template>

  <span v-if="fav!=null" :style="`font-size: ${size}; cursor: pointer`" title="收藏本课程" @click="handleFav">
    <svg-icon v-if="fav" icon-class="favor_yes"/>
    <svg-icon v-else icon-class="favor_no"/>
  </span>

</template>

<script>
import { findFav, saveFav } from '@/api/course/fav'

export default {
  name: 'CourseFav',
  props: {
    courseId: String,
    size: {
      type: String,
      default: '26px'
    }
  },
  data() {
    return {
      fav: null
    }
  },

  watch: {
    courseId: {
      handler() {
        this.initState()
      }
    }
  },

  created() {
    this.initState()
  },

  methods: {

    // 初始化状态
    initState() {
      findFav(this.courseId).then(res => {
        this.fav = !!(res.data && res.data.id)
      })
    },

    // 收藏或取消
    handleFav() {
      // 保存数据
      saveFav(this.courseId).then(() => {
        this.fav = !this.fav
      })
    }

  }
}
</script>

