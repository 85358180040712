var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务提供商", prop: "provider" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "provider", $$v)
                    },
                    expression: "postForm.provider",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "qiniu" } }, [
                    _vm._v("默认服务商"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.provider === "qiniu"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "AppId", prop: "data.appId" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "SecretId" },
                        model: {
                          value: _vm.postForm.data.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "appId", $$v)
                          },
                          expression: "postForm.data.appId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AccessKey", prop: "data.accessKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "SecretId" },
                        model: {
                          value: _vm.postForm.data.accessKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKey", $$v)
                          },
                          expression: "postForm.data.accessKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretKey", prop: "data.secretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "SecretKey" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储Bucket", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "回放存储的bucket名称，必须要求为【华东-浙江】区域的",
                        },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问域名", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "以https://或http://开头的本地接口域名或CDN加速域名，以/结尾",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "录制质量", prop: "data.preset" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "rtc_video_preset" },
                        model: {
                          value: _vm.postForm.data.preset,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "preset", $$v)
                          },
                          expression: "postForm.data.preset",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注信息" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "备注信息" },
                        model: {
                          value: _vm.postForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "remark", $$v)
                          },
                          expression: "postForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                icon: "el-icon-folder",
                type: "primary",
                size: "small",
              },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存配置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }