import { post } from '@/utils/request'

export function saveBatch(data) {
  return post('/api/course/dir/save-batch', data)
}

export function dirTree(data) {
  return post('/api/course/dir/dir-tree', data)
}

export function deleteDir(data) {
  return post('/api/course/dir/delete', data)
}

export function updateDir(data) {
  return post('/api/course/dir/update', data)
}

export function addDir(data) {
  return post('/api/course/dir/add', data)
}

export function sortDir(data) {
  return post('/api/course/dir/sort', data)
}
