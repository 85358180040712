var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    ref: "pagingTable",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("dic-list-select", {
              staticStyle: { width: "100px" },
              attrs: { "dic-code": "pay_state_simple", title: "支付状态" },
              model: {
                value: _vm.listQuery.params.payState,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "payState", $$v)
                },
                expression: "listQuery.params.payState",
              },
            }),
            _c("dic-list-select", {
              staticStyle: { width: "100px" },
              attrs: { "dic-code": "bill_state", title: "账单状态" },
              model: {
                value: _vm.listQuery.params.billState,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "billState", $$v)
                },
                expression: "listQuery.params.billState",
              },
            }),
            _c("date-time-picker", {
              staticClass: "filter-item",
              attrs: {
                "start-time": _vm.listQuery.params.startTime,
                "end-time": _vm.listQuery.params.endTime,
                "start-holder": "下单时间",
              },
              on: {
                "update:startTime": function ($event) {
                  return _vm.$set(_vm.listQuery.params, "startTime", $event)
                },
                "update:start-time": function ($event) {
                  return _vm.$set(_vm.listQuery.params, "startTime", $event)
                },
                "update:endTime": function ($event) {
                  return _vm.$set(_vm.listQuery.params, "endTime", $event)
                },
                "update:end-time": function ($event) {
                  return _vm.$set(_vm.listQuery.params, "endTime", $event)
                },
              },
            }),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { size: "small", placeholder: "搜索标题" },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: {
                "show-overflow-tooltip": "",
                label: "标题",
                prop: "title",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "下单时间", prop: "createTime" },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "下单用户",
                prop: "userId_dictText",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "总金额",
                prop: "amount",
                width: "120px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "结算比例", width: "120px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(scope.row.settleRate) + " % ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "预计收益",
                prop: "settleAmount",
                width: "120px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                label: "账单状态",
                prop: "billState_dictText",
                width: "120px",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "订单状态", width: "120px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.payState_dictText))]),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }