var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.postForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "userName", $$v)
                          },
                          expression: "postForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户角色", prop: "roles" } },
                    [
                      _c("role-select", {
                        model: {
                          value: _vm.postForm.roles,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "roles", $$v)
                          },
                          expression: "postForm.roles",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属部门", prop: "deptCode" } },
                    [
                      _c("depart-tree-select", {
                        model: {
                          value: _vm.postForm.deptCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "deptCode", $$v)
                          },
                          expression: "postForm.deptCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "auto-complete": "off",
                          "show-password": "",
                          type: "password",
                          placeholder: "不修改请留空",
                          size: "small",
                        },
                        model: {
                          value: _vm.postForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "password", $$v)
                          },
                          expression: "postForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  !_vm.postForm.id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small", type: "text" },
                            model: {
                              value: _vm.postForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.postForm, "mobile", $$v)
                              },
                              expression: "postForm.mobile",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "手机绑定" } },
                        [
                          _c("mobile-binder", {
                            attrs: { "user-id": _vm.postForm.id },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "电子邮箱" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.postForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "email", $$v)
                          },
                          expression: "postForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "到期时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          size: "small",
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.postForm.expireTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "expireTime", $$v)
                          },
                          expression: "postForm.expireTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户头像" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          limit: 1,
                          compress: 3000,
                          "list-type": "picture-card",
                          tips: "仅用于学员端展示",
                          accept: ".jpg,.png,.jpeg",
                        },
                        model: {
                          value: _vm.postForm.avatar,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "avatar", $$v)
                          },
                          expression: "postForm.avatar",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "真实姓名", prop: "realName" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.postForm.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "realName", $$v)
                          },
                          expression: "postForm.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.postForm.idCard,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "idCard", $$v)
                          },
                          expression: "postForm.idCard",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.siteData.faceLogin
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "人脸照片" } },
                        [
                          _c("file-upload", {
                            attrs: {
                              limit: 1,
                              compress: 3000,
                              "list-type": "picture-card",
                              tips: "用于人脸登录，需要保证正脸位于图片中央，图片质量会影响识别率",
                              accept: ".jpg,.png,.jpeg",
                            },
                            model: {
                              value: _vm.postForm.face,
                              callback: function ($$v) {
                                _vm.$set(_vm.postForm, "face", $$v)
                              },
                              expression: "postForm.face",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "border-top": "#eee 1px solid",
            "padding-top": "20px",
            "text-align": "right",
          },
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }