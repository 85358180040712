<template>
  <div>
    <el-card style="height: 360px">

      <div style="color: #000; font-weight: 700; font-size: 16px; margin-bottom: 10px;">进行中的课程</div>

      <el-table
        v-if="tableData.length > 0"
        :data="tableData"
        :header-cell-style="{'background':'#f2f7fb', 'color':'#000', 'font-weight':'700', 'font-size':'14px', 'line-height':'32px'}"
        style="width: 100%">
        <el-table-column
          label="课程名称"
          prop="title"
          show-overflow-tooltip>

          <template v-slot="scope">
            <detail-link :params="{courseId: scope.row.id}" :title="scope.row.title" to="CourseStat" />
          </template>

        </el-table-column>

        <el-table-column
          label="学习人数"
          align="center"
          width="100px"
          prop="procCount"
          show-overflow-tooltip
        />

        <el-table-column
          label="学完人数"
          align="center"
          width="100px"
          prop="finishCount"
          show-overflow-tooltip
        />
      </el-table>

      <div v-else class="t-empty">
        <el-empty description="啊哦，暂无正在进行中的课程！"/>
      </div>

    </el-card>

  </div>

</template>

<script>
import permission from '@/directive/permission'
import { postData } from '@/api/common'

export default {
  name: 'CardCourseList',
  components: { },
  directives: { permission },
  data() {
    return {
      tableData: [],
      listQuery: {
        current: 1,
        size: 5,
        params: {
          title: ''
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      postData('/api/stat/course/dash-course-list', this.listQuery).then(res => {
        this.tableData = res.data
      })
    }
  }
}
</script>
