<template>

  <div>

    <div class="font-title" style="margin-bottom: 10px; color: #606266; cursor: pointer">
      <span style="color: #c0c4cc"><i class="el-icon-caret-bottom"/></span> {{ data.title }}
    </div>

    <div v-if="data.fileList && data.fileList.length>0" style="padding: 10px">
      <el-timeline>
        <el-timeline-item
          v-for="file in data.fileList"
          :key="file.id"
          :timestamp="file.fileType_dictText"
          placement="top"
          @click="toLearn(file.fileId)">

          <el-link :underline="false" :disabled="!file.tryLearn" class="link" @click="toLearn(file)">
            {{ file.title }}
            <span v-if="file.tryLearn" class="try-btn try-tag">
              试学
            </span>
          </el-link>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div v-if="data.children && data.children.length> 0">
      <div v-for="child in data.children" :key="child.id" style="padding-left: 20px">
        <course-dir-show :data="child" :level="level+1" :course-id="courseId" @select="toLearn"/>
      </div>
    </div>

  </div>

</template>

<script>


export default {
  name: 'CourseDirShow',
  props: {
    data: { type: Object },
    level: Number,
    courseId: String
  },
  data() {
    return {

    }
  },

  watch: {

  },
  created() {

  },
  methods: {
    // 前往学习页面
    toLearn(file) {
      this.$emit('select', file)
    }
  }
}
</script>
