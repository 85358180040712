<template>
  <el-date-picker
    v-model="dateRange"
    :start-placeholder="startHolder || '开始时间'"
    :end-placeholder="endHolder || '结束时间'"
    size="small"
    format="yyyy-MM-dd HH:mm:ss"
    value-format="yyyy-MM-dd HH:mm:ss"
    type="datetimerange"
    range-separator="至"
  />
</template>
<script>
export default {
  name: 'DateTimePicker',
  props: {
    // 接收绑定参数
    startTime: String,
    // 输入框宽度
    endTime: String,
    // 开始结束文字
    startHolder: String,
    endHolder: String
  },
  data() {
    return {
      dateRange: []
    }
  },
  watch: {
    startTime() {
      this.fillData()
    },
    endTime() {
      this.fillData()
    },
    dateRange(val) {
      console.log('change', val)

      if (val && val.length === 2) {
        this.$emit('update:startTime', val[0])
        this.$emit('update:endTime', val[1])
      } else {
        this.$emit('update:startTime', '')
        this.$emit('update:endTime', '')
      }

      this.$emit('change')
    }
  },
  created() {
    this.fillData()
  },
  methods: {
    fillData() {
      if (this.startTime && this.endTime) {
        this.dateRange = [this.startTime, this.endTime]
      }
    }
  }
}
</script>

