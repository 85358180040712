<template>

  <div>


    <div>
      <div class="u-title">账户总览</div>
      <div>
        <el-descriptions>
          <el-descriptions-item label="可用余额">
            {{ account.availAmount }}
            <el-button size="mini" type="danger" round style="margin-left: 20px" @click="showCash">提现</el-button>
          </el-descriptions-item>
          <el-descriptions-item label="冻结余额">
            <div class="flex">
              <span>{{ account.lockAmount }}</span>
              <tool-tip msg="提现正在处理状态中的余额则为冻结状态"/>
            </div>

          </el-descriptions-item>
          <el-descriptions-item label="分成比例">
            <div class="flex">
              <span>{{ account.settleRate }}%</span>
              <tool-tip msg="您发布的课程将统一按此比例为您结算，若课程设置了单独的分成比例，则按课程设置的为准！"/>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="提现费用">
            <div class="flex">
              <span>{{ account.taxRate }}%</span>
              <tool-tip msg="余额提现到银行卡时，需要收取的手续费，通常此费用由支付服务商收取。"/>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>

    <div style="margin-top: 20px">
      <div class="u-title">收支记录</div>
      <div>
        <account-log ref="log" />
      </div>
    </div>


    <cash-dialog :visible.sync="cashVisible" :avail-amount="account.availAmount" @success="showAccount" />

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { accountPreview } from '@/api/lecturer/account'
import AccountLog from '@/views/pages/uc/lecturer/account/components/AccountLog/index.vue'
import CashDialog from '@/views/pages/uc/lecturer/account/components/CashDialog/index.vue'
import ToolTip from '@/components/ToolTip/index.vue'

export default {
  components: { ToolTip, CashDialog, AccountLog },
  data() {
    return {
      account: {},
      cashVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'siteData',
      'token'
    ])
  },

  created() {
    this.showAccount()
  },
  methods: {

    // 查找账户信息
    showAccount() {
      accountPreview().then(res => {
        this.account = res.data
        this.$refs.log.refreshList()
      })
    },

    // 提现窗口
    showCash() {
      this.cashVisible = true
    }
  }
}
</script>

<style scoped>


.u-title{
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
  margin-bottom: 20px;
  position: relative;
}


.u-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #C6302B 0%, #C6302B 80px, #ccc 80px, #ccc 100%);
}

.dt-item{
  border-bottom: #eee 1px dotted;
  padding: 10px 0;
  line-height: 28px
}

.points{
  width: 100px;
  text-align: right;
  color: #FBBE0F;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
}
</style>
