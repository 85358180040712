<template>

  <span>{{ textShow }}</span>

</template>

<script>

import { fetchTree } from '@/api/sys/dict/value'

// 字典数据
const dictKey = 'SYS-DICT-MAP'

export default {
  name: 'DicListShow',
  props: {
    dicCode: String,
    value: String
  },
  data() {
    return {
      // 下拉选项值
      dataMap: new Map(),
      textShow: ''
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.showText()
      }
    }
  },
  created() {
    this.showText()
  },
  methods: {

    // 显示字典标题
    async showText() {
      // 获取缓存
      const cache = localStorage.getItem(dictKey)
      if (cache) {
        const json = JSON.parse(cache)
        if (json.expire > new Date().getTime()) {
          this.parseMap(json.data, false)
          return
        }
      }

      this.fetchData()
    },

    // 将列表存为Map
    parseMap(list, save) {
      for (let i = 0; i<list.length; i++) {
        const item = list[i]
        this.dataMap.set(item.value, item.title)
      }
      this.textShow = this.dataMap.get(this.value)

      // 存入缓存
      if (save) {
        this.saveCache(list)
      }
    },

    // 设置缓存
    saveCache(list) {
      // 设置缓存，简单缓存5分钟
      const json = {
        data: list,
        expire: new Date().getTime() + (1000 * 300)
      }
      localStorage.setItem(dictKey, JSON.stringify(json))
    },

    // 后台获取字典
    fetchData() {
      console.log('远程获取字典...')
      fetchTree({ dicCode: this.dicCode }).then(res => {
        // 处理数据
        this.parseMap(res.data, true)
      })
    }
  }
}
</script>
