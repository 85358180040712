<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="账单明细"
    width="60%"
    @close="handleClose"
  >

    <data-table
      v-if="billId"
      ref="dataTable"
      :options="options"
      :list-query="listQuery"
    >

      <template #data-columns>
        <el-table-column
          label="所属讲师"
          prop="lecturerId_dictText"
          width="120px"
        />

        <el-table-column
          prop="title"
          label="课程名称"
          show-overflow-tooltip
        />

        <el-table-column
          label="金额"
          prop="amount"
          align="center"
          width="100px"
        />

        <el-table-column
          label="购买用户"
          prop="userId_dictText"
          align="center"
          width="120px"
        />

        <el-table-column
          label="收益比例"
          align="center"
          prop="settleRate"
          width="120px"
        />

        <el-table-column
          label="预估收益"
          align="center"
          prop="settleAmount"
          width="120px"
        />

        <el-table-column
          label="下单时间"
          prop="createTime"
          align="center"
          width="180px"
        />

        <el-table-column
          label="支付时间"
          align="center"
          width="180px"
        >

          <template v-slot="{row}">
            <span v-if="row.payTime">{{ row.payTime }}</span>
            <span v-else>--</span>
          </template>

        </el-table-column>

      </template>
    </data-table>

  </el-dialog>

</template>

<script>
import CourseSelect from '@/components/CourseSelect/index.vue'
import DicListSelect from '@/components/DicListSelect/index.vue'

export default {
  name: 'SettleDetailDialog',
  components: { DicListSelect, CourseSelect },
  props: {
    visible: { type: Boolean },
    billId: { type: String }
  },
  data() {
    return {
      dialogVisible: false,
      listQuery: {
        current: 1,
        size: 10,
        params: {
          payState: 1,
          title: ''
        }
      },

      options: {
        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/api/settle/detail/paging'
      }
    }
  },

  watch: {

    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },

    billId: {
      handler(val) {
        if (val) {
          this.listQuery.params.billRef = val
          this.$refs.dataTable.getList()
        }
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.listQuery.params.billRef = this.billId
  },

  methods: {

    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('success')
    }

  }
}
</script>
