import { post } from '@/utils/request'

/**
 * 获取微信登录的URL
 * @param data
 * @returns {*}
 */
export function apiGetWechatUrl(data) {
  return post('/api/common/wx/get-url', data)
}

/**
 * 获取企业微信登录地址
 * @param data
 * @returns {*}
 */
export function apiGetCropWechatUrl(data) {
  return post('/api/common/crop-wechat/get-url', data)
}

/**
 * 获取钉钉登录地址
 * @param data
 * @returns {*}
 */
export function apiGetDingUrl(data) {
  return post('/api/common/ding-talk/get-url', data)
}

