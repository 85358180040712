var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "账单明细",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _vm.billId
        ? _c("data-table", {
            ref: "dataTable",
            attrs: { options: _vm.options, "list-query": _vm.listQuery },
            scopedSlots: _vm._u(
              [
                {
                  key: "data-columns",
                  fn: function () {
                    return [
                      _c("el-table-column", {
                        attrs: {
                          label: "所属讲师",
                          prop: "lecturerId_dictText",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "课程名称",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          prop: "amount",
                          align: "center",
                          width: "100px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "购买用户",
                          prop: "userId_dictText",
                          align: "center",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "收益比例",
                          align: "center",
                          prop: "settleRate",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "预估收益",
                          align: "center",
                          prop: "settleAmount",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "下单时间",
                          prop: "createTime",
                          align: "center",
                          width: "180px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "支付时间",
                          align: "center",
                          width: "180px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.payTime
                                    ? _c("span", [_vm._v(_vm._s(row.payTime))])
                                    : _c("span", [_vm._v("--")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1993660535
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2341111752
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }