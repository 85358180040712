var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-upload",
        {
          ref: "fileUpload",
          attrs: {
            action: _vm.uploadUrl,
            accept: _vm.accept,
            "before-upload": _vm.beforeUpload,
            "on-change": _vm.handleChange,
            "before-remove": _vm.beforeRemove,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            "on-exceed": _vm.handleExceed,
            drag: _vm.listType !== "picture-card",
            multiple: _vm.limit > 1,
            limit: _vm.limit === 1 ? 2 : _vm.limit,
            "file-list": _vm.fileList,
            "list-type": _vm.listType,
            headers: _vm.header,
          },
        },
        [
          _vm.listType === "picture-card"
            ? _c("i", { staticClass: "el-icon-plus" })
            : _vm._e(),
          _vm.listType !== "picture-card"
            ? _c("i", { staticClass: "el-icon-upload" })
            : _vm._e(),
          _vm.listType !== "picture-card"
            ? _c("div", { staticClass: "el-upload__text" }, [
                _vm._v(" 将文件拖到此处，或 "),
                _c("em", [_vm._v("点击上传")]),
              ])
            : _vm._e(),
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.tips))]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }