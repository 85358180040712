var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "选择题库",
        width: "60%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c("data-table", {
            ref: "pagingTable",
            attrs: { options: _vm.options, "list-query": _vm.listQuery },
            on: { "select-changed": _vm.handleSelected },
            scopedSlots: _vm._u([
              {
                key: "filter-content",
                fn: function () {
                  return [
                    _c("el-input", {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "搜索题库名称", size: "small" },
                      model: {
                        value: _vm.listQuery.params.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "title", $$v)
                        },
                        expression: "listQuery.params.title",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "filter-item",
                        staticStyle: { float: "right" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.handleConfirm },
                      },
                      [_vm._v(_vm._s(_vm.selectedLabel))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "data-columns",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        label: "题库名称",
                        align: "center",
                        prop: "title",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "试题总数",
                        align: "center",
                        prop: "quCount",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "客观题数量",
                        align: "center",
                        prop: "objCount",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "主观题数量",
                        align: "center",
                        prop: "subjCount",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }