// 知识竞赛模块
import UcLayout from '@/layout/UcLayout.vue'

const battleRouter = {
  path: '/pages/lecturer',
  component: UcLayout,
  redirect: '/pages/lecturer/apply',
  hidden: true,
  children: [

    {
      path: '/pages/lecturer/apply',
      component: () => import('@/views/pages/uc/lecturer/apply.vue'),
      name: 'LecturerApply',
      meta: { title: '讲师申请', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/account',
      component: () => import('@/views/pages/uc/lecturer/account'),
      name: 'LecturerAccount',
      meta: { title: '账户管理', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/media',
      component: () => import('@/views/pages/uc/lecturer/media'),
      name: 'LecturerMedia',
      meta: { title: '媒体中心', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/course',
      component: () => import('@/views/pages/uc/lecturer/course'),
      name: 'LecturerCourse',
      meta: { title: '课程发布', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/course/edit',
      component: () => import('@/views/pages/uc/lecturer/course/edit'),
      name: 'LecturerCourseEdit',
      meta: { title: '排课管理', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/settle/detail',
      component: () => import('@/views/pages/uc/lecturer/settle/detail'),
      name: 'LecturerSettleDetail',
      meta: { title: '收益明细', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/settle/order',
      component: () => import('@/views/pages/uc/lecturer/settle/order'),
      name: 'LecturerSettleOrder',
      meta: { title: '收益结算', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/lecturer/cash',
      component: () => import('@/views/pages/uc/lecturer/cash'),
      name: 'LecturerCash',
      meta: { title: '提现管理', noCache: true, activeMenu: '/pages/uc' }
    }
  ]
}
export default battleRouter
