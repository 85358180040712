<template>

  <div>

    <el-card>
      <div slot="header" class="clearfix">
        <span>参与部门范围</span>
      </div>
      <depart-refs v-model="deptCodes"/>
    </el-card>

    <el-card style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>参与群组范围</span>
      </div>
      <group-select v-model="groupIds" />
    </el-card>

    <el-card style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>参与人员范围</span>
      </div>
      <el-button size="mini" @click="handleAdd">选择人员</el-button>
      <el-button type="danger" size="mini" @click="handleClear">清除所有</el-button>
      <div style="margin-top: 10px">
        <el-tag
          v-for="(user,index) in userList"
          :key="user.id"
          size="small"
          style="margin-right: 8px; margin-bottom: 8px"
          closable
          @close="handleRemove(index)">
          {{ user.realName || user.userId_dictText }}
        </el-tag>
      </div>
    </el-card>

    <user-select-dialog
      :dialog-show.sync="dialogShow"
      :exclude-departs="deptCodes"
      :exclude-groups="groupIds"
      :excludes="excludes"
      @select="handleSelected"
    />

  </div>

</template>

<script>
import UserSelectDialog from '@/views/admin/sys/user/components/UserSelectDialog'
import GroupSelect from '@/components/GroupSelect/index.vue'

export default {
  name: 'UserRange',
  components: { GroupSelect, UserSelectDialog },
  props: {
    departs: Array,
    users: Array,
    groups: Array
  },
  data() {
    return {
      dialogShow: false,
      // 弹窗排除
      excludes: [],
      // 已选择
      userList: [],
      deptCodes: [],
      groupIds: []

    }
  },
  watch: {
    departs: {
      handler(val) {
        this.deptCodes = val
      }
    },
    users: {
      handler() {
        this.fillUsers()
      }
    },
    deptCodes: {
      handler(val) {
        this.$emit('update:departs', val)
      }
    },
    groupIds: {
      handler(val) {
        this.$emit('update:groups', val)
      }
    }
  },

  created() {
    this.deptCodes = this.departs
    this.groupIds = this.groups
    this.fillUsers()
  },

  methods: {

    // 填充用户信息
    fillUsers() {
      const excludes = []
      let userList = []

      if (this.users && this.users.length>0) {
        userList = this.users
        for (let i = 0; i<this.users.length; i++) {
          excludes.push(this.users[i].userId)
        }
      }
      this.excludes = excludes
      this.userList = userList
    },

    // 添加窗口
    handleAdd() {
      this.dialogShow = true
    },

    // 移除数据
    handleRemove(index) {
      this.userList.splice(index, 1)
      this.excludes.splice(index, 1)
    },

    // 选定用户
    handleSelected(ids, objs) {
      const userList = this.userList
      const excludes = this.excludes

      for (let i = 0; i<objs.length; i++) {
        const userId = objs[i].id

        if (!this.excludes.includes(userId)) {
          userList.push({ userId: userId, realName: objs[i].realName })
          excludes.push(userId)
        }
      }

      this.userList = userList
      this.excludes = excludes

      this.$emit('update:users', this.userList)
    },

    handleClear() {
      this.userList = []
      this.excludes = []
      this.$emit('update:users', this.userList)
    }
  }
}
</script>
