// 知识竞赛模块
import UcLayout from '@/layout/UcLayout.vue'

const examRouter = {
  path: '/uc',
  component: UcLayout,
  hidden: true,
  children: [
    {
      path: '/pages/uc',
      component: () => import('@/views/pages/uc/info'),
      name: 'UserCenter',
      meta: { title: '用户中心', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/info/pass',
      component: () => import('@/views/pages/uc/pass'),
      name: 'UpdatePass',
      meta: { title: '修改密码', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/info/update',
      component: () => import('@/views/pages/uc/update'),
      name: 'UpdateInfo',
      meta: { title: '修改密码', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/info/bind',
      component: () => import('@/views/pages/uc/bind'),
      name: 'AccountBind',
      meta: { title: '账号绑定', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/info/real',
      component: () => import('@/views/pages/uc/real'),
      name: 'RealPerson',
      meta: { title: '实名认证', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/im',
      component: () => import('@/views/pages/uc/im'),
      name: 'InnerMsg',
      meta: { title: '站内消息', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/points',
      component: () => import('@/views/pages/uc/points'),
      name: 'Points',
      meta: { title: '积分日志', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/uc/mall/order',
      component: () => import('@/views/pages/uc/mall/order'),
      name: 'MallOrder',
      meta: { title: '积分订单', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/uc/mall/addr',
      component: () => import('@/views/pages/uc/mall/addr'),
      name: 'MallAddr',
      meta: { title: '收货地址', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/uc/course/cert',
      component: () => import('@/views/pages/uc/course/cert'),
      name: 'MyCert',
      meta: { title: '我的证书', noCache: true, activeMenu: '/pages/uc' }
    },

    {
      path: '/pages/uc/course/qa',
      component: () => import('@/views/pages/uc/course/qa'),
      name: 'CourseQa',
      meta: { title: '课程问答', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/course/progress',
      component: () => import('@/views/pages/uc/course/progress'),
      name: 'CourseProgress',
      meta: { title: '学习进度', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/course/buy',
      component: () => import('@/views/pages/uc/course/buy'),
      name: 'CourseBuy',
      meta: { title: '已购课程', noCache: true, activeMenu: '/pages/uc' }
    },
    {
      path: '/pages/uc/course/fav',
      component: () => import('@/views/pages/uc/course/fav'),
      name: 'CourseFav',
      meta: { title: '课程收藏', noCache: true, activeMenu: '/pages/uc' }
    }
  ]
}
export default examRouter
