var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images && _vm.images.length > 0
    ? _c("div", [
        _c(
          "div",
          [
            _c(
              "VueSlickCarousel",
              {
                ref: "c1",
                attrs: { "as-nav-for": _vm.c2, "focus-on-select": true },
              },
              _vm._l(_vm.images, function (item) {
                return _c("img", { key: item, attrs: { src: item } })
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sub-c" },
          [
            _c(
              "VueSlickCarousel",
              {
                ref: "c2",
                attrs: {
                  "as-nav-for": _vm.c1,
                  "slides-to-show": 8,
                  "focus-on-select": true,
                },
              },
              _vm._l(_vm.images, function (item) {
                return _c("img", {
                  key: item,
                  staticClass: "thumbnail-item",
                  attrs: { src: item },
                })
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }