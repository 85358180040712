var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "font-title",
        staticStyle: {
          "margin-bottom": "10px",
          color: "#606266",
          cursor: "pointer",
        },
      },
      [_vm._m(0), _vm._v(" " + _vm._s(_vm.data.title) + " ")]
    ),
    _vm.data.fileList && _vm.data.fileList.length > 0
      ? _c(
          "div",
          { staticStyle: { padding: "10px" } },
          [
            _c(
              "el-timeline",
              _vm._l(_vm.data.fileList, function (file) {
                return _c(
                  "el-timeline-item",
                  {
                    key: file.id,
                    attrs: {
                      timestamp: file.fileType_dictText,
                      placement: "top",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toLearn(file.fileId)
                      },
                    },
                  },
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "link",
                        attrs: { underline: false, disabled: !file.tryLearn },
                        on: {
                          click: function ($event) {
                            return _vm.toLearn(file)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(file.title) + " "),
                        file.tryLearn
                          ? _c("span", { staticClass: "try-btn try-tag" }, [
                              _vm._v(" 试学 "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.data.children && _vm.data.children.length > 0
      ? _c(
          "div",
          _vm._l(_vm.data.children, function (child) {
            return _c(
              "div",
              { key: child.id, staticStyle: { "padding-left": "20px" } },
              [
                _c("course-dir-show", {
                  attrs: {
                    data: child,
                    level: _vm.level + 1,
                    "course-id": _vm.courseId,
                  },
                  on: { select: _vm.toLearn },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#c0c4cc" } }, [
      _c("i", { staticClass: "el-icon-caret-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }