<template>

  <div class="app-container">
    登录成功，正在跳转..
  </div>

</template>

<script>

export default {
  created() {
    // 获取数据
    const token = this.$route.query.token
    const to = this.$route.query.to || '/'
    // 写入会话
    this.$store.dispatch('user/sync', token).then(() => {
      // 完成跳转
      setTimeout(() => {
        window.location = to
      }, 1500)
    })
  }
}
</script>
