var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "系统名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "系统显示名称，用于登录页面、浏览器标题等",
                        },
                        model: {
                          value: _vm.postForm.siteName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "siteName", $$v)
                          },
                          expression: "postForm.siteName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "后台LOGO" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          compress: 100,
                          limit: 1,
                          "list-type": "picture-card",
                          tips: "上传jpg或png格式图片",
                          accept: ".jpg,.jpeg,.png",
                        },
                        model: {
                          value: _vm.postForm.backLogo,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "backLogo", $$v)
                          },
                          expression: "postForm.backLogo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "前端LOGO" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          compress: 100,
                          limit: 1,
                          "list-type": "picture-card",
                          tips: "上传jpg或png格式图片",
                          accept: ".jpg,.jpeg,.png",
                        },
                        model: {
                          value: _vm.postForm.frontLogo,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "frontLogo", $$v)
                          },
                          expression: "postForm.frontLogo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "cfg-mb", attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小程序二维码" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          compress: 100,
                          limit: 1,
                          "list-type": "picture-card",
                          tips: "上传jpg或png格式图片",
                          accept: ".jpg,.jpeg,.png",
                        },
                        model: {
                          value: _vm.postForm.mpCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "mpCode", $$v)
                          },
                          expression: "postForm.mpCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "cfg-mb", attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "移动端域名", prop: "h5Host" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "移动端域名，用于登录页扫码分享等内容",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.checkHost($event)
                          },
                        },
                        model: {
                          value: _vm.postForm.h5Host,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "h5Host", $$v)
                          },
                          expression: "postForm.h5Host",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "临时目录", prop: "tmpDir" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "系统临时文件存放，如模板文件，缓存文件等",
                        },
                        model: {
                          value: _vm.postForm.tmpDir,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "tmpDir", $$v)
                          },
                          expression: "postForm.tmpDir",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "版权信息" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          type: "textarea",
                          placeholder: "学员端底部的版权信息",
                        },
                        model: {
                          value: _vm.postForm.copyRight,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "copyRight", $$v)
                          },
                          expression: "postForm.copyRight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存配置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }