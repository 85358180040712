<template>

  <div v-if="exists!==null">

    <el-form v-if="!exists" label-width="100px" label-position="left">

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="postForm.realName" size="small" placeholder="请填写真实姓名"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="认证抬头" prop="title">
            <el-input v-model="postForm.title" size="small" placeholder="职称或一句话描述"/>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="讲师介绍" prop="intro">
            <el-input v-model="postForm.intro" type="textarea" size="small" placeholder="一段关于您的文字介绍" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="认证照片" prop="image">
            <file-upload
              v-model="postForm.image"
              :limit="1"
              :compress="5000"
              list-type="picture-card"
              tips="请上传jpg或png格式图片"
              accept=".jpg,.png,.jpeg" />
          </el-form-item>
        </el-col>

      </el-row>

      <el-form-item>
        <el-button :loading="loading" type="primary" @click="apply">提交申请</el-button>
      </el-form-item>

    </el-form>

    <div v-else>
      <el-empty description="您有正在审核的申请，请耐心等待管理员审核！" />
    </div>

  </div>

</template>

<script>
import { saveApply, checkApply } from '@/api/lecturer/lecturer'
import { mapGetters } from 'vuex'

export default {
  components: { },
  data() {
    return {
      exists: null,
      postForm: {},
      bindVisible: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'siteData'
    ])
  },

  created() {
    // 校验是否存在
    this.check()
  },
  methods: {


    check() {
      // 校验是否存在
      checkApply().then(res => {
        this.exists = res.data
      })
    },

    apply() {
      this.loading = true

      saveApply(this.postForm).then(() => {
        this.loading = false
        this.$notify({
          title: '成功',
          message: '申请提交成功，请耐心等待审核！',
          type: 'success',
          duration: 2000
        })

        setTimeout(() => {
          this.check()
        }, 1500)
      }).catch(() => {
        this.loading = false
      })
    }

  }
}
</script>

<style scoped>


</style>

