var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("dic-list-select", {
                  attrs: { "dic-code": "settle_state", title: "结算状态" },
                  model: {
                    value: _vm.listQuery.params.settleState,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "settleState", $$v)
                    },
                    expression: "listQuery.params.settleState",
                  },
                }),
                _c("date-time-picker", {
                  staticClass: "filter-item",
                  attrs: {
                    "start-time": _vm.listQuery.params.startTime,
                    "end-time": _vm.listQuery.params.endTime,
                    "start-holder": "结算时间",
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.listQuery.params, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.listQuery.params, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.listQuery.params, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.listQuery.params, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "结算周期", align: "center", width: "300px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(row.startTime) +
                              " - " +
                              _vm._s(row.endTime) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "订单数量", align: "center", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetail(row.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.totalCount))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "订单总金额",
                    prop: "totalAmount",
                    align: "center",
                    width: "100px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "应结算金额",
                    prop: "settleAmount",
                    align: "center",
                    width: "100px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "结算状态",
                    align: "center",
                    prop: "settleState_dictText",
                    width: "100px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "结算时间",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.settleTime
                            ? _c("span", [_vm._v(_vm._s(row.settleTime))])
                            : _c("span", [_vm._v("--")]),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("settle-detail-dialog", {
        attrs: { visible: _vm.dialogVisible, "bill-id": _vm.billId },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }