<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="课程评价"
    width="30%"
    @close="handleClose"
  >


    <el-descriptions :column="1">
      <el-descriptions-item v-for="item in fieldList" :key="item.fieldName" :label="item.fieldTitle">
        <el-rate v-model="item.fieldValue" :disabled="rateDisable" show-text allow-half	/>
      </el-descriptions-item>
    </el-descriptions>

    <div v-if="!rateDisable">
      <el-button :loading="loading" type="primary" @click="saveScore">提交</el-button>
    </div>


  </el-dialog>

</template>

<script>

import { listFields, saveScore, listMyScore } from '@/api/course/score'

export default {
  name: 'ScoreDialog',
  props: {
    visible: { type: Boolean },
    courseId: { type: String }
  },
  data() {
    return {
      dialogVisible: false,
      postForm: {},
      loading: false,
      fieldList: [],
      rateDisable: false
    }
  },

  watch: {

    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },

    courseId: {
      handler() {
        this.fetchMyScore()
      }
    }
  },

  created() {
    this.fetchMyScore()
  },

  methods: {

    // 查找我的评分
    fetchMyScore() {
      listMyScore(this.courseId).then(res => {
        const data = res.data
        if (data && data.length > 0) {
          this.fieldList = data
          this.rateDisable = true
        } else {
          this.rateDisable = false
          this.initFields()
        }
      })
    },

    // 查找全部评价纬度
    initFields() {
      listFields().then(res => {
        this.fieldList = res.data

        // 初始化表格
        for (let i = 0; i<res.data.length; i++) {
          this.postForm[res.data[i].fieldName] = ''
        }
      })
    },

    // 提交评价
    saveScore() {
      const fields = this.fieldList.map(obj => ({ fieldId: obj.id, fieldValue: obj.fieldValue }))
      saveScore({ courseId: this.courseId, fields: fields }).then(() => {
        this.$message.success('评价提交成功！')
        this.handleClose()
        this.rateDisable = true
      })
    },


    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('success')
    }

  }
}
</script>
