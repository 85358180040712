<template>

  <div>
    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery"
    >
      <template slot="filter-content">

        <dic-catalog-tree v-model="listQuery.params.catId" dic-code="mall_goods_catalog" title="搜索分类" class="filter-item" width="200"/>
        <el-input v-model="listQuery.params.q" size="small" class="filter-item" style="width: 200px; margin-left: 10px" placeholder="搜索商品"/>
      </template>


      <template v-slot:data-item="{ data }">
        <!-- 引入商品卡片 -->
        <goods-card :data="data" />
      </template>
    </data-grid>

  </div>

</template>

<script>

import GoodsCard from '@/views/pages/mall/components/GoodsCard/index.vue'

export default {
  components: { GoodsCard },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        lineCount: 5,
        // 列表请求URL
        listUrl: '/api/mall/goods/paging'
      }
    }
  }
}
</script>

<style scoped>

</style>
