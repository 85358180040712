var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-inner",
      on: {
        click: function ($event) {
          return _vm.toLecturerHome(_vm.data.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "image-2x3" },
        [
          _c("el-image", { attrs: { src: _vm.data.image } }, [
            _c(
              "div",
              { attrs: { slot: "error" }, slot: "error" },
              [_c("el-image", { attrs: { src: _vm.defaultCover } })],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { "padding-top": "10px" },
          on: {
            click: function ($event) {
              return _vm.toLecturerHome(_vm.data.id)
            },
          },
        },
        [
          _c("div", { staticClass: "font-title c-link" }, [
            _vm._v(_vm._s(_vm.data.realName)),
          ]),
          _c("div", { staticClass: "font-small" }, [
            _vm._v(_vm._s(_vm.data.title)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }