var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        on: {
          add: _vm.showAdd,
          edit: _vm.showEdit,
          "multi-actions": _vm.handleMultiActions,
        },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "搜索文件", size: "small" },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "文件信息" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "font-title",
                              on: {
                                click: function ($event) {
                                  return _vm.showPreview(scope.row)
                                },
                              },
                            },
                            [
                              _c("file-icon-show", {
                                attrs: { value: scope.row.fileType },
                              }),
                              _c("span", { staticClass: "c-link" }, [
                                _vm._v(" " + _vm._s(scope.row.title) + " "),
                              ]),
                              scope.row.fileType === "video"
                                ? _c(
                                    "span",
                                    { staticClass: "font-small" },
                                    [
                                      _c("sec-format", {
                                        attrs: { value: scope.row.duration },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "font-small" }, [
                            _vm._v(_vm._s(scope.row.sizeDesc)),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(scope.row.fileType_dictText)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "引用", align: "center", width: "100px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showRef(row.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.refCount))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "状态", align: "center", width: "120px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.state === 0
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "success" } },
                                [_vm._v("正常")]
                              )
                            : _vm._e(),
                          scope.row.state === 1
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "warning" } },
                                [_vm._v("转码中")]
                              )
                            : _vm._e(),
                          scope.row.state === 2
                            ? _c(
                                "el-tag",
                                { attrs: { size: "mini", type: "danger" } },
                                [_vm._v("转码失败")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("media-save-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          "file-id": _vm.fileId,
          "space-id": _vm.spaceId,
          "cat-id": _vm.catId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          success: _vm.reloadList,
        },
      }),
      _c("media-preview-dialog", {
        attrs: {
          visible: _vm.previewVisible,
          "file-type": _vm.previewFileType,
          "view-url": _vm.previewUrl,
        },
        on: {
          "update:visible": function ($event) {
            _vm.previewVisible = $event
          },
        },
      }),
      _c("media-catalog-batch", {
        attrs: { visible: _vm.batchVisible, ids: _vm.selectedIds },
        on: {
          "update:visible": function ($event) {
            _vm.batchVisible = $event
          },
        },
      }),
      _c("media-ref-dialog", {
        attrs: { visible: _vm.refVisible, "file-id": _vm.fileId },
        on: {
          "update:visible": function ($event) {
            _vm.refVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }