var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, title: "审核消息", width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 1, border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "审核人员" } }, [
            _vm._v(" " + _vm._s(_vm.detail.auditBy_dictText) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "审核结果" } }, [
            _c("span", { class: "audit-state-" + _vm.detail.auditState }, [
              _vm._v(" " + _vm._s(_vm.detail.auditState_dictText) + " "),
            ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "审核时间" } }, [
            _vm._v(" " + _vm._s(_vm.detail.auditTime) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "审核信息" } }, [
            _vm._v(" " + _vm._s(_vm.detail.auditMsg) + " "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }