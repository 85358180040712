var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "引用内容",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("data-table", {
        ref: "dataTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { size: "small", placeholder: "查找名称" },
                  model: {
                    value: _vm.listQuery.params.refTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "refTitle", $$v)
                    },
                    expression: "listQuery.params.refTitle",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "引用类型",
                    width: "120px",
                    prop: "refType_dictText",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "引用ID", prop: "refId", width: "200px" },
                }),
                _c("el-table-column", {
                  attrs: { label: "名称" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", target: "_blank" },
                              on: {
                                click: function ($event) {
                                  return _vm.toCourse(row.refId)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.refTitle))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }