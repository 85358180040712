<template>

  <div style="text-align: left">

    <data-grid
      ref="grid"
      :options="options"
      :list-query="listQuery"
    >
      <template slot="filter-content">
        <div v-if="isMine">
          <user-course-select v-model="listQuery.params.courseId"/>
        </div>
        <div v-else style="text-align: right; width: 100%">
          <el-button type="danger" size="small" icon="el-icon-plus" round @click="handleNew">新建提问</el-button>
        </div>
      </template>
      <template v-slot:data-item="{ data }">
        <div class="qa-box">
          <div class="avatar">
            <yf-avatar :src="data.avatar" />
          </div>
          <div class="content">
            <div class="flex-bt">
              <div class="qa-nick">
                {{ data.createBy_dictText }}
              </div>
              <div class="qa-time" style="padding-right: 10px">
                发表于：{{ data.createTime }}
              </div>
            </div>
            <div class="flex-bt">
              <div class="qa-content">
                <div v-html="$options.filters.formatRichText(data.content)"/>
              </div>
              <div class="reply-btn" style="padding-right: 10px">
                <el-link type="danger" size="mini" icon="el-icon-chat-line-round" @click="handleReply(data.id)">回复</el-link>
              </div>
            </div>

            <div v-if="data.replyList.length > 0">
              <div v-for="reply in data.replyList" :key="reply.id" class="reply-box">
                <div class="avatar">
                  <yf-avatar :src="reply.avatar" />
                </div>
                <div class="content">
                  <div class="flex-bt">
                    <div class="qa-nick">{{ reply.createBy_dictText }} </div>
                    <div class="qa-time">回复于：{{ reply.createTime }}</div>
                  </div>
                  <div class="reply-content" v-html="$options.filters.formatRichText(reply.content)"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>
    </data-grid>


    <course-qa-create-dialog
      :visible.sync="dialogVisible"
      :course-id="courseId"
      :file-id="fileId"
      :reply-id="replyId"
      @success="refreshGrid"
    />

  </div>

</template>

<script>
import YfAvatar from '@/components/YfAvatar/index.vue'
import UserCourseSelect from '@/components/UserCourseSelect/index.vue'
import DataGrid from '@/components/DataGrid/index.vue'
import CourseQaCreateDialog from '@/views/pages/course/components/CourseQaCreateDialog/index.vue'

export default {
  name: 'CourseQa',
  components: { CourseQaCreateDialog, DataGrid, UserCourseSelect, YfAvatar },
  props: {
    courseId: String,
    fileId: String,
    isMine: Boolean
  },

  data() {
    return {
      options: {
        listUrl: '/api/course/qa/paging'
      },
      listQuery: {
        current: 1,
        size: 10,
        params: {
          courseId: this.courseId,
          fileId: this.fileId,
          isMine: this.isMine
        }
      },
      dialogVisible: false,
      replyId: ''
    }
  },

  watch: {
    courseId: {
      handler(val) {
        this.listQuery.params.courseId = val
      }
    },
    fileId: {
      handler(val) {
        this.listQuery.params.fileId = val
      }
    },
    isMine: {
      handler(val) {
        this.listQuery.params.isMine = val
      }
    }
  },
  created() {
    this.listQuery.params.courseId = this.courseId
    this.listQuery.params.fileId = this.fileId
    this.listQuery.params.isMine = this.isMine
  },
  methods: {

    // 打开窗口
    handleNew() {
      this.dialogVisible = true
    },

    handleReply(id) {
      this.replyId = id
      this.dialogVisible = true
    },

    refreshGrid() {
      this.$refs.grid.getList()
    }

  }
}
</script>

<style scoped>
.qa-box{
  display: flex;
}

.qa-box .content{
  flex-grow: 1;
}

.qa-box .avatar{
  padding-right: 10px;
}


.qa-nick{
  font-weight: 700;
  font-size: 16px;
  color: #1890FF;
}

.qa-time{
  font-size: 12px;
  color: #888;
  text-align: right;
}

.qa-content{
  font-size: 14px;
}

.reply-box{
  display: flex;
  padding: 10px;
  background: #f6f6f6;
  margin-top: 5px;
}

.reply-box .avatar{
  flex-shrink: 0;
  padding-right: 5px;
}

.reply-box .content{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  line-height: 25px;
}

.reply-content{
  font-size: 14px;
  line-height: 25px;
  width: 100%;
  flex-grow: 1;
}

.reply-btn{
  width: 80px;
  flex-shrink: 0;
  text-align: right;
}

::v-deep p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

</style>
