var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h3", [_vm._v("系统公告")]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                {
                  class: {
                    "size-normal": _vm.size === "normal",
                    "size-small": _vm.size === "small",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, "show-header": false },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "公告标题",
                          prop: "title",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("detail-link", {
                                  attrs: {
                                    id: scope.row.id,
                                    title: scope.row.title,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(scope.row.id)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }