var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "postForm",
      attrs: { model: _vm.postForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "原始密码", prop: "oldPassword" } },
        [
          _c("el-input", {
            attrs: { "show-password": "", type: "password" },
            model: {
              value: _vm.postForm.oldPassword,
              callback: function ($$v) {
                _vm.$set(_vm.postForm, "oldPassword", $$v)
              },
              expression: "postForm.oldPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "新的密码", prop: "password" } },
        [
          _c("el-input", {
            attrs: { "show-password": "", type: "password" },
            model: {
              value: _vm.postForm.password,
              callback: function ($$v) {
                _vm.$set(_vm.postForm, "password", $$v)
              },
              expression: "postForm.password",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "确认密码", prop: "confirm" } },
        [
          _c("el-input", {
            attrs: { "show-password": "", type: "password" },
            model: {
              value: _vm.postForm.confirm,
              callback: function ($$v) {
                _vm.$set(_vm.postForm, "confirm", $$v)
              },
              expression: "postForm.confirm",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleUpdate } },
            [_vm._v("确认修改")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }