<template>
  <div>

    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">

      <el-row :gutter="20">

        <el-col :span="24">
          <el-form-item label="系统名称">
            <el-input v-model="postForm.siteName" size="small" placeholder="系统显示名称，用于登录页面、浏览器标题等" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="后台LOGO">
            <file-upload
              v-model="postForm.backLogo"
              :compress="100"
              :limit="1"
              list-type="picture-card"
              tips="上传jpg或png格式图片"
              accept=".jpg,.jpeg,.png"/>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="前端LOGO">
            <file-upload
              v-model="postForm.frontLogo"
              :compress="100"
              :limit="1"
              list-type="picture-card"
              tips="上传jpg或png格式图片"
              accept=".jpg,.jpeg,.png"/>
          </el-form-item>
        </el-col>

        <el-col :span="24" class="cfg-mb">
          <el-form-item label="小程序二维码">
            <file-upload
              v-model="postForm.mpCode"
              :compress="100"
              :limit="1"
              list-type="picture-card"
              tips="上传jpg或png格式图片"
              accept=".jpg,.jpeg,.png"/>
          </el-form-item>
        </el-col>

        <el-col :span="12" class="cfg-mb">
          <el-form-item label="移动端域名" prop="h5Host">
            <el-input v-model="postForm.h5Host" size="small" placeholder="移动端域名，用于登录页扫码分享等内容" @keyup.native="checkHost" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="临时目录" prop="tmpDir">
            <el-input v-model="postForm.tmpDir" size="small" placeholder="系统临时文件存放，如模板文件，缓存文件等" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="版权信息">
            <el-input v-model="postForm.copyRight" size="small" type="textarea" placeholder="学员端底部的版权信息" />
          </el-form-item>
        </el-col>

      </el-row>

    </el-form>

    <el-divider />

    <div style="margin-top: 20px">
      <el-button size="small" type="primary" @click="submitForm">保存配置</el-button>
    </div>

  </div>
</template>

<script>
import { fetchDetail, saveData } from '@/api/sys/config/base'
import { isUrl, isDirectory } from '@/utils/validate'

export default {
  name: 'BaseConfig',
  components: { },
  data() {
    return {
      postForm: {
        id: '1',
        uploadType: 1,
        ossUpload: {

        },
        localUpload: {
          localDir: ''
        }
      },
      loading: false,
      rules: {
        // 本地存储
        h5Host: [{ validator: isUrl }],
        tmpDir: [{ validator: isDirectory }]
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      fetchDetail().then(response => {
        this.postForm = response.data
      })
    },

    submitForm() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        this.loading = true

        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '配置保存成功！',
            type: 'success',
            duration: 2000
          })
        })
        this.loading = false
      })
    },

    checkHost() {

    }
  }
}
</script>

<style scoped>

</style>
