<template>
  <div>

    <div class="calc-item">
      <div class="title">最低分数：</div><el-input-number v-model="minValue" size="mini" />
      <div class="title" style="padding-left: 20px">最高分数：</div><el-input-number v-model="maxValue" :max="(max+1)" size="mini" />
    </div>


    <div class="calc-item">
      <div class="title">分数节点：</div>
      <el-tag
        v-for="tag in splitList"
        :key="tag"
        :disable-transitions="false"
        style="margin-right: 10px"
        closable
        @close="removeSplit(tag)">
        {{ tag }}
      </el-tag>

      <el-input-number
        v-if="inputVisible"
        ref="saveTagInput"
        v-model="inputValue"
        :min="minValue"
        :max="maxValue"
        style="width: 100px; margin-right: 10px"
        size="mini"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      />
      <el-button v-else type="primary" size="mini" @click="showInput">+添加</el-button>
    </div>


    <div v-if="resultList.length > 0" class="calc-item">
      <div class="title">结果预览：</div>
      <el-tag v-for="item in resultList" :key="item" type="danger" style="margin-right: 10px; width: 100px; text-align: center">{{ item }}分</el-tag>
    </div>

  </div>

</template>

<script>
export default {
  name: 'MultiRangeSelect',
  props: {
    value: { type: String, required: true },
    max: { type: Number }
  },

  data() {
    return {
      splitList: [],
      inputVisible: false,
      inputValue: '',
      minValue: 0,
      maxValue: 0,
      resultList: []
    }
  },
  watch: {
    value: {
      handler() {
        this.reInitData()
      }
    },
    minValue: {
      handler() {
        this.calcRange()
      }
    },
    maxValue: {
      handler() {
        this.calcRange()
      }
    },
    max: {
      handler(val) {
        if (this.maxValue !== 0) {
          this.splitList = []
        }
        // 清除
        this.maxValue = val
      },
      deep: true
    },
    splitList: {
      handler() {
        this.calcRange()
      }
    }
  },
  created() {
    this.maxValue = this.max
    this.reInitData()
  },
  methods: {

    // 重新初始化数据
    reInitData() {
      // 分割列表
      const list = []
      const items = this.value.split(';')
      for (let i = 0; i<items.length; i++) {
        const item = items[i]
        const range = item.split('-')
        const num = parseInt(range[1])
        if (num !== this.maxValue && num !== this.minValue) {
          list.push(num)
        }
      }
      this.splitList = list
    },

    removeSplit(tag) {
      console.log(tag)
      this.splitList.splice(this.splitList.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.splitList.push(inputValue)
      }

      // 从小到大排序
      this.splitList.sort((a, b) => { return a - b })

      this.inputVisible = false
      this.inputValue = ''
    },
    // 重新计算分值
    calcRange() {
      if (this.minValue === this.maxValue) {
        return
      }

      // 加入最小分值
      const list = [this.minValue]
      // 添加中间分值
      if (this.splitList.length > 0) {
        for (let i = 0; i<this.splitList.length; i++) {
          list.push(this.splitList[i])
        }
      }
      // 加入最大分值
      list.push(this.maxValue)
      let last = this.minValue
      const results = []
      for (let i = 0; i<list.length; i++) {
        const num = list[i]
        if (num !== last && num>last) {
          results.push(last + '-' + num)
          last = parseInt(num) + 1
        }
      }
      this.resultList = results
      this.$emit('input', results.join(';'))
    }
  }
}
</script>

<style lang="scss">

.calc-item{
  display: flex;
  align-items: center;
  margin-top: 10px
}

.calc-item > .title{
  width: 90px;
}
</style>

