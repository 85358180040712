var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务提供商" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "provider", $$v)
                    },
                    expression: "postForm.provider",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "qcloud" } }, [
                    _vm._v("腾讯云"),
                  ]),
                  _c("el-radio", { attrs: { label: "baidu" } }, [
                    _vm._v("百度云"),
                  ]),
                  _c("el-radio", { attrs: { label: "aliyun" } }, [
                    _vm._v("阿里云"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.provider === "baidu"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "AppId" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "appId" },
                        model: {
                          value: _vm.postForm.data.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "appId", $$v)
                          },
                          expression: "postForm.data.appId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AppKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "appKey" },
                        model: {
                          value: _vm.postForm.data.appKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "appKey", $$v)
                          },
                          expression: "postForm.data.appKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "secretKey" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "人脸群组" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "人脸群组，适用多应用场景",
                        },
                        model: {
                          value: _vm.postForm.data.faceGroup,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "faceGroup", $$v)
                          },
                          expression: "postForm.data.faceGroup",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活体检测" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.postForm.data.liveCheck,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm.data, "liveCheck", $$v)
                            },
                            expression: "postForm.data.liveCheck",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c("el-alert", {
                        staticStyle: { "line-height": "normal" },
                        attrs: {
                          type: "error",
                          title:
                            "开启后所有人脸识别都会做前置的活体检测，可以有效防止翻拍或网图！提示：对防作弊要求高时开启，会产生额外的费用！",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "remark" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "备注信息" },
                        model: {
                          value: _vm.postForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "remark", $$v)
                          },
                          expression: "postForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "qcloud"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretId" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "SecretId" },
                        model: {
                          value: _vm.postForm.data.secretId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretId", $$v)
                          },
                          expression: "postForm.data.secretId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "SecretKey" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "人脸群组" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "人脸群组，适用多应用场景",
                        },
                        model: {
                          value: _vm.postForm.data.groupId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "groupId", $$v)
                          },
                          expression: "postForm.data.groupId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活体检测" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.postForm.data.liveCheck,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm.data, "liveCheck", $$v)
                            },
                            expression: "postForm.data.liveCheck",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c("el-alert", {
                        staticStyle: { "line-height": "normal" },
                        attrs: {
                          type: "error",
                          title:
                            "开启后所有人脸识别都会做前置的活体检测，可以有效防止翻拍或网图！提示：对防作弊要求高时开启，会产生额外的费用！",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "remark" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "备注信息" },
                        model: {
                          value: _vm.postForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "remark", $$v)
                          },
                          expression: "postForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "aliyun"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "AccessKeyId" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "AccessKeyId" },
                        model: {
                          value: _vm.postForm.data.accessKeyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeyId", $$v)
                          },
                          expression: "postForm.data.accessKeyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AccessKeySecret" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "AccessKeySecret",
                        },
                        model: {
                          value: _vm.postForm.data.accessKeySecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeySecret", $$v)
                          },
                          expression: "postForm.data.accessKeySecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "DbName" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "dbName" },
                        model: {
                          value: _vm.postForm.data.dbName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "dbName", $$v)
                          },
                          expression: "postForm.data.dbName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活体检测" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.postForm.data.liveCheck,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm.data, "liveCheck", $$v)
                            },
                            expression: "postForm.data.liveCheck",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c("el-alert", {
                        staticStyle: { "line-height": "normal" },
                        attrs: {
                          type: "error",
                          title:
                            "开启后所有人脸识别都会做前置的活体检测，可以有效防止翻拍或网图！提示：对防作弊要求高时开启，会产生额外的费用！",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                icon: "el-icon-refresh",
                type: "warning",
                size: "small",
              },
              on: { click: _vm.changProvider },
            },
            [_vm._v("使用当前方案")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                icon: "el-icon-folder",
                type: "primary",
                size: "small",
              },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存配置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }