var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pay-dialog", {
    attrs: { visible: _vm.dialogVisible, "order-id": _vm.payOrder },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event
      },
      close: _vm.dialogClose,
      success: _vm.payBack,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }