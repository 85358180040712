<template>

  <div>
    <mall-address />
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import MallAddress from '@/views/pages/mall/components/MallAddress/index.vue'

export default {
  components: {
    MallAddress
  },
  data() {
    return {
      active: 'user',
      state: ''
    }
  },
  computed: {
    ...mapGetters([
      'siteData',
      'token',
      'realName'
    ])
  },
  watch: {
    '$route.query.to': {
      handler(val) {
        this.menuSelect(val)
      },
      deep: true
    },
    '$route.query.state': {
      handler(val) {
        this.state = val
      },
      deep: true
    }
  },

  created() {
    const to = this.$route.query.to
    const state = this.$route.query.state
    if (state) {
      this.state = state
    }
    if (to) {
      this.menuSelect(to)
      return
    }

    const cache = localStorage.getItem('uc:menu:active')
    if (cache) {
      this.menuSelect(cache)
    }
  },
  methods: {

    // 菜单选择
    menuSelect(index) {
      if (!index) {
        index = 'user'
      }

      this.active = index
      this.$route.query.to = index
      // 保存状态
      localStorage.setItem('uc:menu:active', index)
    }

  }
}
</script>

<style scoped>

::v-deep .el-menu{
  border-right: none;
}

</style>
