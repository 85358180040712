var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-image",
    {
      staticClass: "yf-avatar",
      style:
        "width:" +
        _vm.size +
        "px; height:" +
        _vm.size +
        "px; border:" +
        _vm.border,
      attrs: { src: _vm.src, fit: "cover" },
    },
    [
      _c(
        "div",
        { attrs: { slot: "error" }, slot: "error" },
        [_c("el-image", { attrs: { src: _vm.defaultAvatar } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }