<template>
  <div>

    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="150px">

      <el-form-item label="方案类型">
        <el-radio-group v-model="postForm.provider" size="small">
          <el-radio label="aliyun">阿里云</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="postForm.provider==='aliyun'">
        <el-form-item label="推流域名">
          <el-input v-model="postForm.data.pushDomain" size="small" placeholder="" />
        </el-form-item>

        <el-form-item label="推流秘钥">
          <el-input v-model="postForm.data.pushKey" size="small" placeholder="" />
        </el-form-item>

        <el-form-item label="拉流域名">
          <el-input v-model="postForm.data.pullDomain" size="small" placeholder="" />
        </el-form-item>

        <el-form-item label="拉流秘钥">
          <el-input v-model="postForm.data.pullKey" size="small" placeholder="" />
        </el-form-item>

      </div>

    </el-form>

    <el-divider />

    <div style="margin-top: 20px">
      <el-button size="small" type="primary" @click="submitForm">保存配置</el-button>
    </div>

  </div>
</template>

<script>
import { fetchDetail, saveData } from '@/api/sys/config/prop'

export default {
  name: 'LiveConfig',
  data() {
    return {
      postForm: {
        provider: '',
        type: 'live'
      },
      loading: false,
      rules: {
      }
    }
  },
  created() {
    this.fetchData('')
  },
  methods: {

    fetchData(provider) {
      fetchDetail('live', provider).then(res => {
        // 清空表单
        this.postForm = { type: 'live', provider: provider, data: {}}
        // 赋值
        if (res.data && res.data.provider) {
          this.postForm = res.data
        }
      })
    },
    submitForm() {
      console.log(JSON.stringify(this.postForm))

      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        // 参数转换
        this.loading = true
        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '直播配置保存成功！',
            type: 'success',
            duration: 2000
          })
        })
        this.loading = false
      })
    }
  }
}
</script>

