var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "text-align": "left" } },
    [
      _c(
        "data-grid",
        {
          ref: "grid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("div", { staticClass: "qa-box" }, [
                    _c(
                      "div",
                      { staticClass: "avatar" },
                      [_c("yf-avatar", { attrs: { src: data.avatar } })],
                      1
                    ),
                    _c("div", { staticClass: "content" }, [
                      _c("div", { staticClass: "flex-bt" }, [
                        _c("div", { staticClass: "qa-nick" }, [
                          _vm._v(" " + _vm._s(data.createBy_dictText) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "qa-time",
                            staticStyle: { "padding-right": "10px" },
                          },
                          [_vm._v(" 发表于：" + _vm._s(data.createTime) + " ")]
                        ),
                      ]),
                      _c("div", { staticClass: "flex-bt" }, [
                        _c("div", { staticClass: "qa-content" }, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.formatRichText(
                                  data.content
                                )
                              ),
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "reply-btn",
                            staticStyle: { "padding-right": "10px" },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  icon: "el-icon-chat-line-round",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReply(data.id)
                                  },
                                },
                              },
                              [_vm._v("回复")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      data.replyList.length > 0
                        ? _c(
                            "div",
                            _vm._l(data.replyList, function (reply) {
                              return _c(
                                "div",
                                { key: reply.id, staticClass: "reply-box" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avatar" },
                                    [
                                      _c("yf-avatar", {
                                        attrs: { src: reply.avatar },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "content" }, [
                                    _c("div", { staticClass: "flex-bt" }, [
                                      _c("div", { staticClass: "qa-nick" }, [
                                        _vm._v(
                                          _vm._s(reply.createBy_dictText) + " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "qa-time" }, [
                                        _vm._v(
                                          "回复于：" + _vm._s(reply.createTime)
                                        ),
                                      ]),
                                    ]),
                                    _c("div", {
                                      staticClass: "reply-content",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.formatRichText(
                                            reply.content
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filter-content" }, [
            _vm.isMine
              ? _c(
                  "div",
                  [
                    _c("user-course-select", {
                      model: {
                        value: _vm.listQuery.params.courseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery.params, "courseId", $$v)
                        },
                        expression: "listQuery.params.courseId",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticStyle: { "text-align": "right", width: "100%" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: "small",
                          icon: "el-icon-plus",
                          round: "",
                        },
                        on: { click: _vm.handleNew },
                      },
                      [_vm._v("新建提问")]
                    ),
                  ],
                  1
                ),
          ]),
        ],
        2
      ),
      _c("course-qa-create-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          "course-id": _vm.courseId,
          "file-id": _vm.fileId,
          "reply-id": _vm.replyId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          success: _vm.refreshGrid,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }