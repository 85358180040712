<template>


  <div style="padding: 20px 50px">

    <div v-if="listData.length > 0" class="flex-bt">

      <div>
        <div v-for="item in listData" :key="item.fieldId" class="score-i flex-bt">
          <div class="score-t">{{ item.fieldTitle }}</div>
          <div>
            <el-rate
              :value="item.fieldAvg"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"/>
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; align-items: center">
        <el-progress
          :percentage="percentage"
          :color="colors"
          :format="textFormat"
          :stroke-width="15"
          :width="200"
          type="dashboard"/>
        <div style="font-size: 18px; font-weight: 700; color: #C6302B">总体评分</div>
      </div>
    </div>
    <div v-else>
      <el-empty description="暂无评分，可能评价的学员不够！"/>
    </div>
  </div>
</template>

<script>
import { listScore } from '@/api/course/score'

export default {
  name: 'CourseScore',
  props: {
    courseId: String
  },
  data() {
    return {
      listData: [],
      colors: [
        { color: '#1989fa', percentage: 20 },
        { color: '#6f7ad3', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#e6a23c', percentage: 80 },
        { color: '#f56c6c', percentage: 100 }
      ]
    }
  },

  computed: {
    // 是否为树状结构数据
    percentage() {
      let total = 0
      for (let i = 0; i<this.listData.length; i++) {
        total += this.listData[i].fieldAvg
      }

      return total * 20 / this.listData.length
    }
  },

  watch: {
    courseId: {
      handler() {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {

    // 获取数据
    fetchData() {
      if (!this.courseId) {
        return
      }
      listScore(this.courseId).then(res => {
        this.listData = res.data
      })
    },

    textFormat(percentage) {
      return percentage / 20
    }
  }
}
</script>

<style scoped>

.score-i{
  display: flex;
  align-items: center;
  height: 50px
}
.score-t{
  padding-right: 20px;
  color: #333;
  font-weight: 700;
}
</style>
