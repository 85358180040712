var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "dir-box" }, [
        _vm._m(0),
        _vm._v(" " + _vm._s(_vm.dir.title) + " "),
      ]),
      _vm._l(_vm.dir.fileList, function (file) {
        return _c("div", { key: file.id, staticClass: "file-box" }, [
          _c(
            "div",
            {
              staticClass: "file-item",
              class: {
                current: file.fileId === _vm.currentId,
                disabled: !file.unlocked && !file.tryLearn,
              },
              attrs: { title: file.title },
              on: {
                click: function ($event) {
                  return _vm.toFile(file)
                },
              },
            },
            [
              !file.unlocked && !file.tryLearn
                ? _c("i", { staticClass: "el-icon-lock" })
                : file.fileType === "video"
                ? _c("i", { staticClass: "el-icon-video-play" })
                : _c("i", { staticClass: "el-icon-document" }),
              _vm._v(" " + _vm._s(file.title) + " "),
            ]
          ),
          file.unlocked
            ? _c(
                "span",
                [
                  _vm.calcPercent(file) === 100
                    ? _c("el-progress", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          percentage: 100,
                          width: 20,
                          "stroke-width": 2,
                          status: "success",
                          "text-color": "#fff",
                          type: "circle",
                        },
                      })
                    : _c("el-progress", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          percentage: _vm.calcPercent(file),
                          width: 20,
                          "stroke-width": 2,
                          "text-color": "#fff",
                          type: "circle",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _vm.dir.children && _vm.dir.children.length > 0
        ? _c(
            "div",
            _vm._l(_vm.dir.children, function (sub) {
              return _c(
                "div",
                { key: sub.id, staticStyle: { "padding-left": "10px" } },
                [
                  _c("course-dir-learn", {
                    attrs: {
                      dir: sub,
                      "file-id": _vm.fileId,
                      "course-id": _vm.courseId,
                    },
                    on: { select: _vm.toFile },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#c0c4cc" } }, [
      _c("i", { staticClass: "el-icon-caret-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }