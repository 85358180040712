<template>

  <div class="bi-box">
    <yf-avatar :src="userData.avatar" :size="64" border="#ccc 1px solid"/>

    <div class="info">
      <div class="name">{{ userData.realName }} </div>
      <div style="padding-left: 10px"><i class="el-icon-s-custom" /> 部门：{{ userData.deptCode_dictText }}</div>
      <div style="padding-left: 10px"><i class="el-icon-s-finance" />  积分：{{ userData.points }} </div>
    </div>

  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import { findInfo } from '@/api/sys/user/user'
import YfAvatar from '@/components/YfAvatar/index.vue'

export default {
  name: 'UserBanner',
  components: { YfAvatar },

  data() {
    return {
      uploadDialog: false,
      defaultAvatar: require('@/assets/web/avatar.png'),
      userData: {},
      postForm: {
        avatar: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },

  created() {
    this.handleUserInfo()
  },
  methods: {
    handleUserInfo() {
      findInfo(this.token).then(res => {
        this.userData = res.data
      })
    }
  }
}
</script>

<style scoped>
.bi-box{
  padding: 20px;
  background: #363636;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
}

.bi-box > .avatar{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: #e1e1e1 1px solid;
}

.bi-box > .info{
  margin-top: 0;
  line-height: 24px;
  color: #999;
  font-size: 12px;
}

.bi-box > .info > div{
  width: 150px;
  text-align: left;
}

.bi-box > .info > .name{
  color:#f0312a;
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0;
  text-align: center;
}


</style>
