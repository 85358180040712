var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      [_c("home-catalog"), _c("home-banner")],
      1
    ),
    _c("div", [_c("home-course")], 1),
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      [_c("home-lecturer"), _c("home-notice")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }