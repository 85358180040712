<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="发货查询"
    width="50%"
    @close="handleClose"
  >

    <el-empty v-if="!delivery || !delivery.id" description="暂无发货信息！"/>

    <el-descriptions :column="1" border="">
      <el-descriptions-item v-if="delivery.remark" label="发货信息">{{ delivery.remark }}</el-descriptions-item>
      <el-descriptions-item label="发货时间">{{ delivery.createTime }}</el-descriptions-item>
      <el-descriptions-item v-if="delivery.expressName" label="快递公司">{{ delivery.expressName }}</el-descriptions-item>
      <el-descriptions-item v-if="delivery.expressNo" label="快递单号">{{ delivery.expressNo }}</el-descriptions-item>
    </el-descriptions>

    <el-table
      v-if="delivery.sendType===1"
      :data="listData"
      stripe
      border
      style="width: 100%; margin-top: 20px">
      <el-table-column
        prop="time"
        label="日期"
        width="180"/>
      <el-table-column
        prop="context"
        label="动态"/>
    </el-table>

  </el-dialog>

</template>

<script>
import { fetchExpress } from '@/api/mall/order'
import { fetchDetail } from '@/api/mall/delivery'

export default {
  name: 'ExpressDialog',
  props: {
    orderId: { type: String },
    visible: { type: Boolean }
  },
  data() {
    return {
      delivery: {},
      dialogVisible: false,
      listData: []
    }
  },

  watch: {

    // 检测查询变化
    orderId: {
      handler() {
        this.fetchData()
      },
      deep: true
    },

    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.fetchData()
  },

  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },

    // 订单发货
    fetchData() {
      // 订单编号必须
      if (!this.orderId) {
        return
      }

      fetchDetail(this.orderId).then(res => {
        console.log(res)
        this.delivery = res.data

        // 物流发货
        if (this.delivery.sendType === 1) {
          fetchExpress(this.orderId).then(res => {
            console.log('express', res)
            this.listData = res.data.data
          })
        }
      })
    }

  }
}
</script>
