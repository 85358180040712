var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "padding-left": "20px" } }, [
    _vm.dirId
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "act-bar" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "item",
                    attrs: {
                      icon: "el-icon-plus",
                      type: "danger",
                      size: "mini",
                      round: "",
                      plain: "",
                    },
                    on: { click: _vm.handleJoin },
                  },
                  [_vm._v(" 添加课件 ")]
                ),
                _c(
                  "el-dropdown",
                  {
                    staticClass: "item",
                    attrs: {
                      disabled:
                        !_vm.selectedIds || _vm.selectedIds.length === 0,
                    },
                    on: { command: _vm.handleCommand },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          round: "",
                          plain: "",
                        },
                      },
                      [
                        _vm._v(" 批量操作 "),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "points" } },
                          [_vm._v("设置积分")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "learn" } },
                          [_vm._v("设置学习时长")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "item",
                    attrs: {
                      disabled:
                        !_vm.selectedIds || _vm.selectedIds.length === 0,
                      type: "danger",
                      size: "mini",
                      icon: "el-icon-delete",
                      round: "",
                      plain: "",
                    },
                    on: { click: _vm.batchDelete },
                  },
                  [_vm._v(" 移除 ")]
                ),
              ],
              1
            ),
            _c("el-divider"),
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  "show-header": true,
                  "row-key": "id",
                  stripe: "",
                  "empty-text": "还没有关联课件，请点击`添加课件`开始添加吧！",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "42" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "title", label: "全选" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "40px",
                                    "text-align": "left",
                                    "font-size": "24px",
                                    "font-weight": "900",
                                    color: "#C6302B",
                                    "font-style": "italic",
                                  },
                                },
                                [_vm._v(_vm._s(scope.$index + 1))]
                              ),
                              _c("div", { staticStyle: { "flex-grow": "1" } }, [
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("file-icon-show", {
                                      attrs: { value: scope.row.fileType },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "padding-left": "5px",
                                          "font-weight": "700",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.title))]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticStyle: {
                                          color: "#C6302B",
                                          "margin-left": "5px",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleUpdate({
                                              ids: [scope.row.id],
                                              tryLearn: scope.row.tryLearn,
                                            })
                                          },
                                        },
                                        model: {
                                          value: scope.row.tryLearn,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "tryLearn", $$v)
                                          },
                                          expression: "scope.row.tryLearn",
                                        },
                                      },
                                      [_vm._v("试学")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex",
                                    staticStyle: { "padding-top": "10px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex font-small" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "font-small" },
                                          [_vm._v("要求时长")]
                                        ),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "80px",
                                            "margin-left": "5px",
                                          },
                                          attrs: {
                                            min: 0,
                                            max: 9999,
                                            controls: false,
                                            size: "mini",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleUpdate({
                                                ids: [scope.row.id],
                                                needLearn: scope.row.needLearn,
                                              })
                                            },
                                          },
                                          model: {
                                            value: scope.row.needLearn,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "needLearn",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.needLearn",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "font-small",
                                            staticStyle: {
                                              "padding-left": "5px",
                                            },
                                          },
                                          [_vm._v("秒")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex",
                                        staticStyle: { "padding-left": "50px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "font-small" },
                                          [_vm._v("学完积分")]
                                        ),
                                        _c("el-input-number", {
                                          staticStyle: {
                                            width: "80px",
                                            "margin-left": "5px",
                                          },
                                          attrs: {
                                            min: 0,
                                            max: 9999,
                                            controls: false,
                                            size: "mini",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleUpdate({
                                                ids: [scope.row.id],
                                                points: scope.row.points,
                                              })
                                            },
                                          },
                                          model: {
                                            value: scope.row.points,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "points", $$v)
                                            },
                                            expression: "scope.row.points",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "padding-left": "10px" } },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      disabled: !scope.row.viewUrl,
                                      plain: "",
                                      icon: "el-icon-view",
                                      size: "mini",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreview(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3970036470
                  ),
                }),
              ],
              1
            ),
            _c("media-select-dialog", {
              attrs: {
                normal: true,
                visible: _vm.mediaVisible,
                excludes: _vm.mediaExcludes,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mediaVisible = $event
                },
                select: _vm.mediaSelect,
              },
            }),
            _c("media-preview-dialog", {
              attrs: {
                visible: _vm.previewVisible,
                "view-url": _vm.previewData.viewUrl,
                "file-type": _vm.previewData.fileType,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.previewVisible = $event
                },
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("el-empty", {
              attrs: { description: "请先添加或选择章节再进行排课！" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }