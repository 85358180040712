<template>
  <iframe :src="fullUrl" class="frame-box" />
</template>

<script>

export default {
  name: 'PdfReader',
  props: {
    src: String
  },
  data() {
    return {
      viewerUrl: '/pdf/web/viewer.html?file=',
      fullUrl: ''
    }
  },
  watch: {
    src: {
      handler(val) {
        this.fullUrl = this.viewerUrl + encodeURIComponent(val)
      }
    }
  },
  created() {
    // 初始化文件
    this.fullUrl = this.viewerUrl + encodeURIComponent(this.src)
  }

}

</script>

<style scoped>

.frame-box{
  width: 100%;
  height: 800px
}

</style>
