<template>

  <div>

    <data-table
      ref="pagingTable"
      :auto-scroll="false"
      :options="options"
      :list-query="listQuery"
      @add="handleAdd"
      @delete="handleRemove"
    >
      <template #filter-content>
        <el-input v-model="listQuery.params.title" style="width: 350px" placeholder="搜索ID/账号/姓名/手机号" class="filter-item" size="small"/>
      </template>

      <template #data-columns>

        <el-table-column
          label="用户ID"
          align="center"
          prop="id"
        />

        <el-table-column
          label="账号"
          align="center"
          prop="userName"
        />

        <el-table-column
          label="姓名"
          align="center"
          prop="realName"
        />

        <el-table-column
          label="手机号码"
          align="center"
          prop="mobile"
        />

      </template>

    </data-table>

    <user-select-dialog
      :dialog-show.sync="dialogShow"
      :excludes="excludes"
      @select="handleSelected"
    />

  </div>

</template>

<script>
import UserSelectDialog from '@/views/admin/sys/user/components/UserSelectDialog'
import { postData } from '@/api/common'

export default {
  name: 'UserRefs',
  components: { UserSelectDialog },
  props: {
    refId: String, // 引用对象如课程ID，考试ID
    listUrl: String, // 查询列表
    joinUrl: String, // 加入列表
    removeUrl: String, // 移除用户ID
    idsUrl: String // 查找全部用户ID
  },
  data() {
    return {

      dialogShow: false,

      // 弹窗排除
      excludes: [],

      // 表格查询参数
      listQuery: {
        current: 1,
        size: 10,
        params: {
          refId: this.refId
        }
      },

      // 表格查询
      options: {
        multi: true,
        add: {
          enable: true
        },
        delete: {
          enable: true
        },
        // 列表请求URL
        listUrl: this.listUrl
      }

    }
  },

  created() {
    // 查找全部ID
    postData(this.joinUrl, { refId: this.refId, ids: [] }).then(res => {
      if (res.data && res.data.length>0) {
        this.excludes = res.data
      }
    })
  },

  methods: {

    // 添加窗口
    handleAdd() {
      this.dialogShow = true
    },

    // 移除数据
    handleRemove(ids) {
      postData(this.removeUrl, { ids: ids, refId: this.refId }).then(() => {
        // 移除数组
        for (let i = 0; i<this.excludes.length; i++) {
          for (let j = 0; j<ids.length; j++) {
            if (this.excludes[i] == ids[j]) {
              this.excludes.splice(i, 1)
            }
          }
        }

        // 刷新表格
        this.$refs.pagingTable.getList()
      })
    },

    // 添加到列表
    handleSelected(ids) {
      // 添加到后台数据
      postData(this.joinUrl, { refId: this.refId, ids: ids }).then(res => {
        // 刷新表格
        this.$refs.pagingTable.getList()
        // 排除列表
        this.excludes = res.data
      })
    }
  }
}
</script>
