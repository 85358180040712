var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    ref: "pagingTable",
    attrs: { options: _vm.options, "list-query": _vm.listQuery },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { size: "small", placeholder: "搜索标题" },
              model: {
                value: _vm.listQuery.params.title,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "title", $$v)
                },
                expression: "listQuery.params.title",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-columns",
        fn: function () {
          return [
            _c("el-table-column", {
              attrs: {
                prop: "title",
                label: "标题",
                "show-overflow-tooltip": "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "金额",
                prop: "amount",
                align: "center",
                width: "100px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "余额",
                prop: "afterAmount",
                align: "center",
                width: "120px",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "时间",
                prop: "createTime",
                align: "center",
                width: "180px",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }