var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cat-box" },
    _vm._l(_vm.listData, function (item) {
      return _c("div", { key: item.value, staticClass: "cat-item" }, [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.toCourseList(item.value)
              },
            },
          },
          [_vm._v(_vm._s(item.title))]
        ),
        item.children && item.children.length > 0
          ? _c(
              "div",
              { staticClass: "child-box" },
              _vm._l(item.children, function (child) {
                return _c(
                  "a",
                  {
                    key: child.value,
                    on: {
                      click: function ($event) {
                        return _vm.toCourseList(child.value)
                      },
                    },
                  },
                  [_vm._v(_vm._s(child.title))]
                )
              }),
              0
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }