var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-container" },
    _vm._l(_vm.dataList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "img-box" },
        [
          _c("el-image", {
            staticStyle: {
              width: "200px",
              height: "150px",
              background: "#000",
            },
            attrs: {
              src: _vm.dataKey ? item[_vm.dataKey] : item,
              "preview-src-list": _vm.imageList,
              fit: "scale-down",
            },
          }),
          _c(
            "div",
            { staticClass: "img-append" },
            [_vm._t("append", null, { item: item })],
            2
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }