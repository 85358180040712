<template>

  <div style="padding-left: 20px; ">

    <div v-if="dirId">


      <div class="act-bar">

        <el-button class="item" icon="el-icon-plus" type="danger" size="mini" round plain @click="handleJoin">
          添加课件
        </el-button>

        <el-dropdown
          :disabled="!selectedIds || selectedIds.length === 0"
          class="item"
          @command="handleCommand">
          <el-button type="primary" size="mini" round plain>
            批量操作 <i class="el-icon-arrow-down el-icon--right"/>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="points">设置积分</el-dropdown-item>
            <el-dropdown-item command="learn">设置学习时长</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button
          :disabled="!selectedIds || selectedIds.length === 0"
          class="item"
          type="danger" size="mini"
          icon="el-icon-delete" round
          plain @click="batchDelete">
          移除
        </el-button>

      </div>


      <el-divider />


      <!-- 课程列表 -->
      <el-table
        :data="tableData"
        :show-header="true"
        row-key="id"
        stripe
        empty-text="还没有关联课件，请点击`添加课件`开始添加吧！"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="42"/>


        <el-table-column prop="title" label="全选">
          <template v-slot="scope">
            <div class="flex">

              <div style="width:40px; text-align: left; font-size: 24px; font-weight: 900; color: #C6302B; font-style: italic">{{ scope.$index+1 }}</div>
              <div style="flex-grow: 1">
                <div class="flex">
                  <file-icon-show :value="scope.row.fileType" />
                  <div style="padding-left: 5px; font-weight: 700">{{ scope.row.title }}</div>
                  <el-checkbox v-model="scope.row.tryLearn" style="color: #C6302B; margin-left: 5px" @change="handleUpdate({ids: [scope.row.id], tryLearn: scope.row.tryLearn})">试学</el-checkbox>
                </div>
                <div class="flex" style="padding-top: 10px">
                  <div class="flex font-small">
                    <div class="font-small">要求时长</div>
                    <el-input-number v-model="scope.row.needLearn" :min="0" :max="9999" :controls="false" size="mini" style="width: 80px; margin-left: 5px" @change="handleUpdate({ids: [scope.row.id], needLearn: scope.row.needLearn})"/>
                    <div class="font-small" style="padding-left: 5px">秒</div>
                  </div>
                  <div class="flex" style="padding-left: 50px">
                    <div class="font-small">学完积分</div><el-input-number v-model="scope.row.points" :min="0" :max="9999" :controls="false" size="mini" style="width: 80px; margin-left: 5px" @change="handleUpdate({ids: [scope.row.id], points: scope.row.points})"/>
                  </div>
                </div>
              </div>


              <div style="padding-left: 10px">
                <el-button :disabled="!scope.row.viewUrl" plain icon="el-icon-view" size="mini" circle @click="handlePreview(scope.row)"/>
              </div>

            </div>


          </template>
        </el-table-column>

      </el-table>


      <media-select-dialog
        :normal="true"
        :visible.sync="mediaVisible"
        :excludes="mediaExcludes"
        @select="mediaSelect"/>


      <media-preview-dialog
        :visible.sync="previewVisible"
        :view-url="previewData.viewUrl"
        :file-type="previewData.fileType"
      />


    </div>
    <div v-else>
      <el-empty description="请先添加或选择章节再进行排课！"/>
    </div>


  </div>

</template>

<script>

import Sortable from 'sortablejs'
import DataGrid from '@/components/DataGrid/index.vue'
import CourseCardList from '@/views/pages/uc/lecturer/course/components/CourseCardList/index.vue'
import CourseCreateDialog from '@/views/pages/uc/lecturer/course/components/CourseCreateDialog/index.vue'
import { listAll, joinAll, remove, batchUpdate, batchSort, listIds } from '@/api/course/ref-file'
import MediaSelectDialog from '@/components/MediaSelectDialog/index.vue'
import MediaPreviewDialog from '@/components/MediaManage/MediaPreviewDialog/index.vue'
import FileIconShow from '@/components/FileIconShow/index.vue'

export default {
  components: { FileIconShow, MediaPreviewDialog, MediaSelectDialog, CourseCreateDialog, CourseCardList, DataGrid },
  props: {
    courseId: String,
    dirId: String
  },
  data() {
    return {
      tableData: [],
      sortTable: null,
      mediaVisible: false,
      mediaExcludes: [],
      selectedIds: [],
      previewData: {},
      previewVisible: false
    }
  },
  watch: {
    dirId: {
      handler() {
        this.listData()
      }
    }
  },
  mounted() {
    // 阻止默认行为
    document.body.ondrop = function(event) {
      event.preventDefault()
      event.stopPropagation()
    }
  },

  created() {
    this.listData()
  },
  methods: {


    // 构建要排除的
    buildExcludes() {
      listIds({ id: this.courseId }).then(res => {
        this.mediaExcludes = res.data
      })
    },

    // 加载数据
    listData() {
      if (!this.courseId) {
        return
      }

      // 构建排除对象
      this.buildExcludes()

      if (!this.dirId) {
        return
      }

      listAll({ courseId: this.courseId, dirId: this.dirId }).then(res => {
        this.tableData = res.data
        this.selectedIds = []
        // 初始化排序
        this.initSort()
      })
    },

    // 加入数据
    joinData(list) {
      joinAll({ courseId: this.courseId, dirId: this.dirId, fileList: list }).then(() => {
        this.listData()
      })
    },

    // 初始化表格
    initSort() {
      // 存在即销毁
      if (this.sortTable) {
        this.sortTable.destroy()
      }

      // 拖动表格
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const that = this
      this.sortTable = Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return
          }
          that.tableData.splice(
            newIndex,
            0,
            that.tableData.splice(oldIndex, 1)[0]
          )
          const newArray = that.tableData.slice(0)
          that.tableData = []
          that.$nextTick(function() {
            that.tableData = newArray
          })
          // 处理排序
          that.handleSort(newArray)
        }
      })
    },

    // 课件选择
    mediaSelect(objs) {
      if (objs != null && objs.length > 0) {
        const list = []
        for (let i = 0; i < objs.length; i++) {
          const file = {
            fileId: objs[i].id,
            title: objs[i].title,
            fileType: objs[i].fileType,
            viewUrl: objs[i].viewUrl,
            needLearn: objs[i].duration,
            points: 0
          }
          list.push(file)
        }
        this.joinData(list)
      }
    },

    // 预览课件
    handlePreview(data) {
      this.previewData = data
      this.previewVisible = true
    },

    batchDelete() {
      // 删除
      this.$confirm('确认要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 刷新数据
        remove({ ids: this.selectedIds }).then(() => {
          this.listData()
        })
      })
    },

    batchNeed() {
      this.$prompt('修改要求学习时长', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '单位秒',
        closeOnClickModal: false,
        inputPattern: /^[1-9]\d*$/,
        inputErrorMessage: '时长必须为正整数'
      }).then(({ value }) => {
        this.handleUpdate({ ids: this.selectedIds, needLearn: value })
      })
    },

    batchPoints() {
      this.$prompt('修改获得积分', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '学完后获得的积分数量',
        closeOnClickModal: false,
        inputPattern: /^[1-9]\d*$/,
        inputErrorMessage: '时长必须为正整数'
      }).then(({ value }) => {
        this.handleUpdate({ ids: this.selectedIds, points: value })
      })
    },

    // 批量修改
    handleUpdate(data) {
      batchUpdate(data).then(() => {
        this.$message.success('修改成功！')
        this.listData()
      })
    },

    // 修改排序
    handleSort(newArr) {
      const sortData = newArr.map((obj, index) => ({ id: obj.id, sort: index }))
      batchSort({ items: sortData }).then(() => {
        this.$message.success('排序成功！')
        this.listData()
      })
    },

    handleSelectionChange(val) {
      this.selectedIds = val.map(obj => obj.id)
    },

    handleJoin() {
      this.mediaVisible = true
    },

    handleCommand(command) {
      if (command === 'select') {
        this.mediaVisible = true
      }

      if (command === 'delete') {
        this.batchDelete()
      }

      if (command === 'learn') {
        this.batchNeed()
      }

      if (command === 'points') {
        this.batchPoints()
      }
    }
  }
}
</script>

<style scoped>
.act-bar{
  display: flex;
  align-items: center;
  padding-bottom: 5px
}

.act-bar > .item{
  margin-right: 10px;
}
</style>

