var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-page-header", {
        attrs: { content: "公告详情" },
        on: { back: _vm.$navBack },
      }),
      _c(
        "div",
        { staticClass: "not-box" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.formData.title))]),
          _c("small", [_vm._v("发布时间：" + _vm._s(_vm.formData.createTime))]),
          _c("el-divider"),
          _c("div", {
            staticClass: "notice",
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.formatRichText(_vm.formData.content)
              ),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }