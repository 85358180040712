<template>

  <data-grid
    :options="options"
    :list-query="listQuery"
    empty-text="暂无相关记录，赶紧去学习获取吧！">

    <template #filter-content>
      <el-input
        v-model="listQuery.params.refRemark"
        class="filter-item"
        size="small"
        placeholder="查找标题"
        prefix-icon="el-icon-search"
        style="width: 200px"
      />

    </template>

    <template v-slot:data-item="{ data }">
      <points-log-card :data="data" />
    </template>
  </data-grid>
</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'
import PointsLogCard from '@/views/pages/uc/components/PointsLogCard/index.vue'

export default {
  name: 'PointsLog',
  components: { PointsLogCard, DataGrid },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/user/points/my-paging'
      }
    }
  }
}
</script>

<style scoped>

::v-deep
.search-area{
  display: none;
}
</style>
