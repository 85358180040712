var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "guide", attrs: { id: "dash-opt-box", gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h3", [_vm._v("快捷入口")]),
          ]),
          _vm.siteData.props.moduleActivity
            ? _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["activity:list"],
                      expression: "['activity:list']",
                    },
                  ],
                  staticClass: "dash-item module-activity",
                  attrs: { span: _vm.span1 },
                },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        on: { click: _vm.navToActivity },
                      },
                      [
                        _c("div", { staticClass: "opt-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/dash/activity.png"),
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "opt-content" }, [
                          _c("div", [_vm._v("活动报名")]),
                          _c("div", [_vm._v("考试报名、学习报名、信息收集")]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["exam:add"],
                  expression: "['exam:add']",
                },
              ],
              staticClass: "dash-item module-exam",
              attrs: { span: _vm.span1 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "opt-item", on: { click: _vm.navToAddExam } },
                  [
                    _c("div", { staticClass: "opt-img" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/dash/a1.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "opt-content" }, [
                      _c("div", [_vm._v("创建考试")]),
                      _c("div", [_vm._v("快速创建一场考试，支持多种选题方式")]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm.siteData.props.moduleCourse
            ? _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["course:add"],
                      expression: "['course:add']",
                    },
                  ],
                  staticClass: "dash-item module-course",
                  attrs: { span: _vm.span1 },
                },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        on: { click: _vm.navToAddCourse },
                      },
                      [
                        _c("div", { staticClass: "opt-img" }, [
                          _c("img", {
                            attrs: { src: require("@/assets/dash/a2.png") },
                          }),
                        ]),
                        _c("div", { staticClass: "opt-content" }, [
                          _c("div", [_vm._v("添加课程")]),
                          _c("div", [
                            _vm._v("添加课程课件，支持Office/PDF/视频"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["repo:list"],
                  expression: "['repo:list']",
                },
              ],
              staticClass: "dash-item module-exam",
              attrs: { span: _vm.span1 },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "opt-item", on: { click: _vm.navToRepoList } },
                  [
                    _c("div", { staticClass: "opt-img" }, [
                      _c("img", {
                        attrs: { src: require("@/assets/dash/a3.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "opt-content" }, [
                      _c("div", [_vm._v("题库管理")]),
                      _c("div", [
                        _vm._v("批量导入导出、设定章节、题目难易程度"),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm.siteData.props.moduleCert
            ? _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["cert:list"],
                      expression: "['cert:list']",
                    },
                  ],
                  staticClass: "dash-item module-cert",
                  attrs: { span: _vm.span1 },
                },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        on: { click: _vm.navToCertList },
                      },
                      [
                        _c("div", { staticClass: "opt-img" }, [
                          _c("img", {
                            attrs: { src: require("@/assets/dash/a4.png") },
                          }),
                        ]),
                        _c("div", { staticClass: "opt-content" }, [
                          _c("div", [_vm._v("证书管理")]),
                          _c("div", [
                            _vm._v("定制证书，支持规则自动发放、人工颁发"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.siteData.props.moduleBattle
            ? _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["battle:list"],
                      expression: "['battle:list']",
                    },
                  ],
                  staticClass: "dash-item module-battle",
                  attrs: { span: _vm.span1 },
                },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        on: { click: _vm.navToBattle },
                      },
                      [
                        _c("div", { staticClass: "opt-img" }, [
                          _c("img", {
                            attrs: { src: require("@/assets/dash/battle.png") },
                          }),
                        ]),
                        _c("div", { staticClass: "opt-content" }, [
                          _c("div", [_vm._v("知识竞赛")]),
                          _c("div", [_vm._v("开启知识竞赛，勇攀知识高峰")]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.showNotice
            ? _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["sys:notice:list"],
                      expression: "['sys:notice:list']",
                    },
                  ],
                  staticClass: "dash-item",
                  attrs: { span: _vm.span1 },
                },
                [
                  _c("el-card", [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        on: { click: _vm.navToNotice },
                      },
                      [
                        _c("div", { staticClass: "opt-img" }, [
                          _c("img", {
                            attrs: { src: require("@/assets/dash/a6.png") },
                          }),
                        ]),
                        _c("div", { staticClass: "opt-content" }, [
                          _c("div", [_vm._v("公告管理")]),
                          _c("div", [_vm._v("发布日常公告、新闻及提醒事项")]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }