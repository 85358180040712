var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "选择图标",
        width: "60%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-wrap": "wrap",
            "justify-content": "space-around",
          },
        },
        _vm._l(_vm.iconList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "icon-item",
              on: {
                click: function ($event) {
                  return _vm.handleCheck(item)
                },
              },
            },
            [_c("svg-icon", { attrs: { "icon-class": item } })],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }