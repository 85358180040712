import { post } from '@/utils/request'

/**
 * 保存课件
 * @param data
 */
export function saveData(data) {
  return post('/api/course/file/save', data)
}

/**
 * 获取课件详情
 * @param data
 */
export function fetchDetail(id) {
  return post('/api/course/file/detail', { id: id })
}

/**
 * 补齐误差
 * @returns {Promise}
 */
export function fixProcess(data) {
  return post('/api/course/file/learn/fix-process', data)
}

/**
 * 根据分类获取目录机构及挂载
 * @param data
 * @returns {Promise}
 */
export function fetchFile(data) {
  return post('/api/course/file/fetch-file', data)
}


