var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "新用户注册" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "开启",
                  "inactive-text": "关闭",
                },
                model: {
                  value: _vm.postForm.userReg,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "userReg", $$v)
                  },
                  expression: "postForm.userReg",
                },
              }),
            ],
            1
          ),
          _vm.postForm.userReg
            ? _c(
                "el-form-item",
                { attrs: { label: "新用户审核" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-text": "开启",
                      "inactive-text": "关闭",
                    },
                    model: {
                      value: _vm.postForm.userAudit,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "userAudit", $$v)
                      },
                      expression: "postForm.userAudit",
                    },
                  }),
                  _c(
                    "small",
                    { staticStyle: { "margin-left": "20px", color: "#888" } },
                    [_vm._v("新用户注册后，需要通过审核才能登录系统")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.userReg
            ? _c(
                "el-form-item",
                { attrs: { label: "新用户部门" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.postForm.userDeptType,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "userDeptType", $$v)
                        },
                        expression: "postForm.userDeptType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("用户自由选择"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("指定默认部门"),
                      ]),
                    ],
                    1
                  ),
                  _vm.postForm.userDeptType === 1
                    ? _c(
                        "div",
                        { staticStyle: { width: "400px" } },
                        [
                          _c("depart-tree-select", {
                            model: {
                              value: _vm.postForm.userDeptCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.postForm, "userDeptCode", $$v)
                              },
                              expression: "postForm.userDeptCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.postForm.userDeptType === 0
                    ? _c("span", [
                        _c(
                          "small",
                          {
                            staticStyle: {
                              color: "#888",
                              "margin-left": "20px",
                            },
                          },
                          [_vm._v("注册时，用户可自由选择归属部门")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "用户登录方式" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.postForm.mobileLogin,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "mobileLogin", $$v)
                    },
                    expression: "postForm.mobileLogin",
                  },
                },
                [_vm._v("手机号登录")]
              ),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.postForm.wechatLogin,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "wechatLogin", $$v)
                    },
                    expression: "postForm.wechatLogin",
                  },
                },
                [_vm._v("微信登录")]
              ),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.postForm.faceLogin,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "faceLogin", $$v)
                    },
                    expression: "postForm.faceLogin",
                  },
                },
                [_vm._v("人脸登录")]
              ),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.postForm.cropLogin,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "cropLogin", $$v)
                    },
                    expression: "postForm.cropLogin",
                  },
                },
                [_vm._v("企业微信登录")]
              ),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.postForm.dingLogin,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "dingLogin", $$v)
                    },
                    expression: "postForm.dingLogin",
                  },
                },
                [_vm._v("钉钉登录")]
              ),
            ],
            1
          ),
          _vm.postForm.faceLogin
            ? _c(
                "el-form-item",
                { attrs: { label: "人脸审核" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.postForm.faceAudit,
                        callback: function ($$v) {
                          _vm.$set(_vm.postForm, "faceAudit", $$v)
                        },
                        expression: "postForm.faceAudit",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "no" } }, [
                        _vm._v("无需审核"),
                      ]),
                      _c("el-radio", { attrs: { label: "manual" } }, [
                        _vm._v("人工审核"),
                      ]),
                      _c("el-radio", { attrs: { label: "auto" } }, [
                        _vm._v("人工审核+自动辅助"),
                      ]),
                    ],
                    1
                  ),
                  _vm.postForm.faceAudit === "auto"
                    ? _c("el-alert", {
                        staticStyle: {
                          "line-height": "normal",
                          "margin-top": "10px",
                        },
                        attrs: {
                          type: "error",
                          title:
                            "开启一键审核功能，由【人脸识别】中的服务商提供，自动校验姓名、身份证号、人脸照片是否匹配；请确保开通了人脸实名认证功能，并注意费用消耗。",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "允许多处登录" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.postForm.loginTick,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "loginTick", $$v)
                    },
                    expression: "postForm.loginTick",
                  },
                },
                [_vm._v("允许多处登录")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.postForm.loginTick,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "loginTick", $$v)
                    },
                    expression: "postForm.loginTick",
                  },
                },
                [_vm._v("将前面的账号踢下线")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存配置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }