var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        title: "学习检测",
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            "label-position": "left",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "图形验证码", prop: "captchaValue" } },
            [
              _c("yf-captcha", {
                attrs: { id: _vm.key },
                model: {
                  value: _vm.postForm,
                  callback: function ($$v) {
                    _vm.postForm = $$v
                  },
                  expression: "postForm",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("el-alert", {
            attrs: {
              closable: false,
              title:
                "请输入正确的验证码继续学习，您还有" +
                _vm.remindTime() +
                "秒时间来确认！",
              type: "warning",
            },
          }),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleCheck },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }