<template>


  <data-grid
    :options="options"
    :list-query="listQuery">
    <template slot="filter-content">
      <div style="display: flex; align-items: center">
        <div style="font-size: 14px; font-weight: 500; color: #303133">公告搜索： </div>
        <div>
          <el-input
            v-model="listQuery.params.title"
            class="filter-item"
            size="small"
            placeholder="搜索公告"
            prefix-icon="el-icon-search"
            style="width: 300px"
          />
        </div>
      </div>
    </template>
    <template v-slot:data-item="{ data }">
      <div class="not-box">
        <div style="flex-grow: 1">
          <el-link type="warning" @click="toDetail(data.id)"> {{ data.title }}</el-link>
        </div>
        <div class="font-second" style="width: 200px; text-align: right">
          {{ data.createTime }}
        </div>
      </div>
      <div class="font-small">{{ data.content }}</div>

    </template>

  </data-grid>


</template>

<script>
import DataGrid from '@/components/DataGrid/index.vue'

export default {
  components: { DataGrid },
  data() {
    return {

      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: 0
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/sys/notice/paging'
      }
    }
  },

  methods: {

    toDetail(id) {
      this.$router.push({ name: 'NoticeDetail', params: { id: id }})
    }
  }
}
</script>

<style scoped>
.not-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-link{
  font-size: 16px;
  font-weight: 700;
  color: #9E2622;
}
</style>
