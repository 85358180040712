var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "left hidden-sm-and-down",
              attrs: { lg: 16, md: 10 },
            },
            [
              _c("img", {
                staticStyle: { height: "45vh" },
                attrs: { src: require("../../assets/web/images/login2.png") },
              }),
            ]
          ),
          _c("el-col", { staticClass: "right", attrs: { lg: 8, md: 14 } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "注册新账号", name: "account" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "postForm",
                            attrs: { model: _vm.postForm, rules: _vm.rules },
                          },
                          [
                            _vm.siteData.props.userDeptType === 0
                              ? _c(
                                  "el-form-item",
                                  { attrs: { prop: "deptCode" } },
                                  [
                                    _c("reg-depart-select", {
                                      attrs: { icon: "el-icon-school" },
                                      model: {
                                        value: _vm.postForm.deptCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm,
                                            "deptCode",
                                            $$v
                                          )
                                        },
                                        expression: "postForm.deptCode",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "userName" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "登录账号",
                                    "prefix-icon": "el-icon-mobile",
                                  },
                                  model: {
                                    value: _vm.postForm.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "userName", $$v)
                                    },
                                    expression: "postForm.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "realName" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "姓名",
                                    "prefix-icon": "el-icon-user",
                                  },
                                  model: {
                                    value: _vm.postForm.realName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "realName", $$v)
                                    },
                                    expression: "postForm.realName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "密码",
                                    type: "password",
                                    "prefix-icon": "el-icon-lock",
                                    "show-password": "",
                                  },
                                  model: {
                                    value: _vm.postForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.postForm, "password", $$v)
                                    },
                                    expression: "postForm.password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("yf-captcha", {
                              ref: "captcha",
                              model: {
                                value: _vm.postForm,
                                callback: function ($$v) {
                                  _vm.postForm = $$v
                                },
                                expression: "postForm",
                              },
                            }),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.handleReg($event)
                                      },
                                    },
                                  },
                                  [_vm._v("注册")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "line-height": "10px",
                    },
                  },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { href: "/login" },
                      },
                      [_vm._v("已有账号？")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }