<template>

  <div>


    <!-- 课程列表 -->
    <data-grid
      :options="options"
      :list-query="listQuery">

      <template slot="filter-content">
        <course-catalog v-model="listQuery.params.catId"/>
        <el-divider />

        <div style="display: flex; align-items: center; padding-top: 10px">
          <div style="font-size: 14px; font-weight: 500; color: #303133">课程搜索： </div>
          <div>
            <el-input
              v-model="listQuery.params.title"
              class="filter-item"
              placeholder="搜索课程名称"
              prefix-icon="el-icon-search"
              size="small"
              style="width: 200px; margin-left: 10px"/>
          </div>
        </div>
      </template>

      <template v-slot:data-item="{ data }">
        <!-- 引入课程卡片 -->
        <course-card :data="data" />
      </template>

    </data-grid>

  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import CourseCatalog from '@/views/pages/course/components/CourseCatalog/index.vue'
import DataGrid from '@/components/DataGrid/index.vue'
import CourseCard from '@/components/CourseCard/index.vue'

export default {
  name: 'UserCourseList',
  components: { CourseCard, DataGrid, CourseCatalog },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 12,
        params: {
          catId: ''
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/course/course/client-paging',
        lineCount: 4
      }
    }
  },

  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  created() {
    const catId = this.$route.query.catId
    if (catId) {
      this.listQuery.params.catId = catId
    }
  },
  methods: {


  }
}
</script>

<style scoped>

/deep/
.el-card__body{
  padding: 0
}

.cover{
  height: 60px;
  width: 120px
}

::v-deep .el-tab-pane{
 margin-top: 0;
}
</style>

