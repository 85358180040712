var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm._v(
      _vm._s(_vm.hour) +
        "小时" +
        _vm._s(_vm.min) +
        "分钟" +
        _vm._s(_vm.sec) +
        "秒"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }