<template>

  <el-dialog
    :visible.sync="dialogVisible"
    title="审核记录"
    width="50%"
    @close="handleClose">

    <data-table
      ref="dataTable"
      :options="options"
      :list-query="listQuery"
    >

      <template #data-columns>
        <el-table-column
          label="审核人员"
          prop="auditBy_dictText"
        />
        <el-table-column
          label="审核时间"
          prop="auditTime"
        />
        <el-table-column
          label="审核结果"
          prop="auditState_dictText"
        />
        <el-table-column
          label="审核消息"
          prop="auditMsg"
          show-overflow-tooltip
        />
      </template>
    </data-table>
  </el-dialog>

</template>

<script>
export default {
  name: 'CourseAuditRecordDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    courseId: String
  },

  data() {
    return {
      dialogVisible: false,
      listQuery: {
        current: 1,
        size: 10,
        params: {
          courseId: ''
        }
      },
      options: {
        listUrl: '/api/course/audit/paging'
      }
    }
  },
  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    // 检测查询变化
    courseId: {
      handler(val) {
        this.listQuery.params.courseId = val
      }
    }
  },

  created() {
    this.listQuery.params.courseId = this.courseId
  },
  methods: {

    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('success')
    }
  }
}
</script>
