<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="媒体预览"
    width="50%"
    @close="handleClose"
  >
    <div v-if="dialogVisible">
      <video-player v-if="fileType==='video'" v-model="viewUrl" :ref-id="refId" />
      <pdf-reader v-if="fileType==='office' || fileType==='pdf'" :src="viewUrl" />
    </div>
  </el-dialog>

</template>

<script>
import { uuid } from 'vue-uuid'

export default {
  name: 'MediaPreviewDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    fileType: String,
    viewUrl: String
  },
  data() {
    return {
      dialogVisible: false,
      // 仅仅为了保存进度，此处随机不保存
      refId: uuid.v4()
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {
    handleClose() {
      this.$emit('update:visible')
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
</style>
