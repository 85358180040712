<template>
  <div class="content">
    <el-upload
      ref="fileUpload"
      :http-request="manualUpload"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :drag="listType!=='picture-card'"
      :accept="accept"
      :multiple="limit > 1"
      :limit="limit===1?2:limit"
      :file-list="fileList"
      :list-type="listType"
      :data="dataObj"
      action=""
    >

      <i v-if="listType==='picture-card'" class="el-icon-plus"/>

      <i v-if="listType!=='picture-card'" class="el-icon-upload" />
      <div v-if="listType!=='picture-card'" class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div v-if="tips" slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>

  </div>
</template>

<script>
import { buildDir } from '@/utils/file-utils'
import { fetchToken } from '@/api/sys/config/qiniu'
import { fetchDetail } from '@/api/sys/config/prop'
import { compressImage } from '@/utils/upload'
const qiniu = require('qiniu-js')

export default {
  name: 'FileUploadQiniu',
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    accept: String,
    tips: String,
    listType: String,
    limit: {
      type: Number,
      default: 1
    },
    compress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fileList: [],
      dataObj: {
        token: ''
      },
      fileNames: [],
      conf: {
        endpoint: ''
      }
    }
  },
  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.fillValue()
      }
    }
  },
  mounted() {
    this.fetchConfig()
  },

  created() {
    this.fillValue()
  },
  methods: {

    // 填充数据
    fillValue() {
      this.fileList = this.value
    },

    // 获取基础配置
    fetchConfig() {
      fetchDetail('upload', '').then(res => {
        console.log('获得配置', res)
        // 配置信息
        this.conf = res.data.data

        // 获得上传Token
        fetchToken().then(res => {
          this.dataObj.token = res.data.token
        }).catch(err => {
          this.$message.error(`获取上传令牌失败： ${err} `)
        })
      })
    },

    // 文件超出个数限制时的钩子
    handleExceed() {
      this.$message.warning(`每次只能上传 ${this.limit} 个文件`)
    },
    // 删除文件之前的钩子
    beforeRemove() {
      return this.$confirm(`确定移除文件吗？`)
    },
    // 文件列表移除文件时的钩子
    handleRemove(file) {
      // 移除文件
      for (let i = 0; i<this.fileList.length; i++) {
        if (file.uid === this.fileList[i].uid) {
          this.fileList.splice(i, 1)
        }
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      // 只能传一个的进行替换
      if (this.limit === 1 && fileList.length>1) {
        const url = this.conf.url + this.fileNames[file.uid]
        // 反转并保留一个
        fileList = [{ url: url, name: url }]
      } else {
        // 上传成功替换服务端URL
        for (let i = 0; i< fileList.length; i++) {
          if (file.uid === fileList[i].uid) {
            fileList[i].url = this.conf.url + this.fileNames[file.uid]
          }
        }
      }

      this.$emit('input', fileList)
    },

    // 文件上传前的校验
    beforeUpload(file) {
      this.$emit('select', file.name)
      return compressImage(file, this.compress)
    },

    // 手动上传文件
    manualUpload(param) {
      const file = param.file
      const key = this.buildName(file)
      this.fileNames[file.uid] = key

      // 开始上传
      const observable = qiniu.upload(file, key, this.dataObj.token, {}, {})

      // 进度监听
      const observer = {
        next: function(res) {
          param.onProgress({ percent: res.total.percent })
        },
        error: function(err) {
          this.$message.warning('上传出现错误：' + err)
        },
        complete: function(res) {
          param.onSuccess()
        }
      }

      // 上传开始
      observable.subscribe(observer)
    },

    // 重命名文件
    buildName(file) {
      const temporary = file.name.lastIndexOf('.')
      const fileNameLength = file.name.length
      const fileFormat = file.name.substring(
        temporary + 1,
        fileNameLength
      )
      return buildDir() + '.' + fileFormat
    }

  }
}
</script>
