<template>
  <a @click="toDetail(data.id)">
    <div class="square-img">
      <img :src="data.image" class="img-content" alt="data.title" >
    </div>
    <div class="title">{{ data.title }}</div>
    <div class="summary">{{ data.summary }}</div>
    <el-divider />
    <div class="flex-bt">
      <div style="width: 100px; color: #555; font-size: 12px">已售：{{ data.sales }}</div>
      <div class="price-small"><price-show :sale-price="data.salePrice" :cost-points="data.costPoints"/></div>
    </div>
  </a>
</template>

<script>


import PriceShow from '@/components/PriceShow/index.vue'

export default {
  name: 'GoodsCard',
  components: { PriceShow },
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      // 默认封面
      defaultCover: require('@/assets/web/default_cs.png')
    }
  },

  created() {

  },
  methods: {
    // 物品详情
    toDetail(id) {
      this.$router.push({ name: 'GoodsDetail', params: { id: id }})
    }
  }
}
</script>

<style scoped>


.title{
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 50px;
  margin: 10px 0;
}
.summary {
  color: #999;
  font-size: 12px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
  margin: 10px 0;
  line-height: 20px;
  padding:0
}

</style>
