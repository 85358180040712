var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nt-box" }, [
    _c("div", { staticClass: "nv-title" }, [_vm._v("最新公告")]),
    _c(
      "div",
      { staticClass: "nt-inner" },
      _vm._l(_vm.listData, function (item) {
        return _c("div", { key: item.id, staticClass: "nt-item" }, [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.toDetail(item.id)
                },
              },
            },
            [
              _c("div", [
                _c("i", { staticClass: "el-icon-caret-right nt-i" }),
                _vm._v(_vm._s(item.title)),
              ]),
              _c("div", { staticClass: "font-small" }, [
                _vm._v(_vm._s(item.createTime)),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }