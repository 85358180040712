var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.data.id)
        },
      },
    },
    [
      _c("div", { staticClass: "square-img" }, [
        _c("img", {
          staticClass: "img-content",
          attrs: { src: _vm.data.image, alt: "data.title" },
        }),
      ]),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.data.title))]),
      _c("div", { staticClass: "summary" }, [_vm._v(_vm._s(_vm.data.summary))]),
      _c("el-divider"),
      _c("div", { staticClass: "flex-bt" }, [
        _c(
          "div",
          {
            staticStyle: { width: "100px", color: "#555", "font-size": "12px" },
          },
          [_vm._v("已售：" + _vm._s(_vm.data.sales))]
        ),
        _c(
          "div",
          { staticClass: "price-small" },
          [
            _c("price-show", {
              attrs: {
                "sale-price": _vm.data.salePrice,
                "cost-points": _vm.data.costPoints,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }