import { post } from '@/utils/request'

/**
 * 课程详情
 * @param id
 * @returns {*}
 */
export function fetchDetail(id) {
  return post('/api/course/course/detail', { id: id })
}

/**
 * 查找详情展示
 * @param id
 * @returns {*}
 */
export function detailForShow(id) {
  return post('/api/course/course/detail-for-show', { id: id })
}

/**
 * 查找详情，用于学习页面
 * @param id
 * @returns {*}
 */
export function detailForLearn(id) {
  return post('/api/course/course/detail-for-learn', { id: id })
}


/**
 * 查找单个课件，用于学习的
 * @param courseId
 * @param fileId
 * @returns {Promise}
 */
export function fileForLearn(courseId, fileId) {
  return post('/api/course/file/learn/file-to-learn', { courseId: courseId, fileId: fileId })
}


/**
 * 课程详情
 * @param id
 * @returns {*}
 */
export function fetchDetailForUpdate(id) {
  return post('/api/course/course/admin/detail', { id: id })
}

/**
 * 简略详情
 * @param id
 * @returns {*}
 */
export function simpleDetail(id) {
  return post('/api/course/course/simple-detail', { id: id })
}

/**
 * 课程分页
 * @param data
 * @returns {*}
 */
export function fetchPaging(data) {
  return post('/api/course/course/paging', data)
}

/**
 * 课程分页
 * @param data
 * @returns {*}
 */
export function fetchUserPaging(data) {
  return post('/api/course/course/client-paging', data)
}

/**
 * 保存课程
 * @param data
 */
export function saveData(data) {
  return post('/api/course/course/save', data)
}

/**
 * 创建预付费订单
 * @param id
 * @returns {Promise}
 */
export function buy(courseId) {
  return post('/api/course/buy/init', { courseId: courseId })
}

/**
 * 发送学习安排
 * @param data
 * @returns {Promise}
 */
export function prepareNotify(data) {
  return post('/api/course/course/prepare-notify', data)
}


/**
 * 检查是否已购买
 * @param courseId
 * @returns {Promise}
 */
export function checkBuy(courseId) {
  return post('/api/course/buy/check-buy', { id: courseId })
}


/**
 * 管理员审核
 * @param data
 * @returns {*}
 */
export function auditPass(data) {
  return post('/api/course/audit/pass', data)
}

/**
 * 批量修改，目前仅类目
 * @param data
 * @returns {*}
 */
export function batchUpdate(data) {
  return post('/api/course/course/batch-update', data)
}

/**
 * 修改上下架状态
 * @param data
 * @returns {Promise}
 */
export function sellState(data) {
  return post('/api/course/course/sell-state', data)
}


