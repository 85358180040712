import { Notification } from 'element-ui'
import { buildDir } from '@/utils/file-utils'
import { upload } from '@/utils/request'
import { fetchDetail } from '@/api/sys/config/prop'
import { fetchSts as ossToken } from '@/api/sys/config/oss'
import { fetchToken as cosToken } from '@/api/sys/config/cos'
import { fetchToken as qiniuToken } from '@/api/sys/config/qiniu'
import { compressAccurately } from 'image-conversion'
import COS from 'cos-js-sdk-v5'
import OSS from 'ali-oss'

/**
 * 文件上传入口
 * @param file
 * @param data
 * @returns {Promise<unknown>}
 */
export function uploadFile(file) {
  return new Promise((resolve, reject) => {
    fetchDetail('upload', '').then(res => {
      // 上传配置
      const cfg = res.data

      // 本地文件上传
      if (cfg.provider === 'local') {
        upload('/api/common/file/upload', file).then(res => {
          resolve(res.data.url)
        }, err => {
          reject(err)
        })
      }

      // OSS文件上传
      if (cfg.provider === 'aliyun') {
        ossUpload(file).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      }

      // 七牛云文件上传
      if (cfg.provider === 'qiniu') {
        qiniuUpload(file).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      }

      // 腾讯云文件上传
      if (cfg.provider === 'qcloud') {
        cosUpload(file).then(res => {
          resolve(res)
        }, err => {
          reject(err)
        })
      }
    })
  })
}

// OSS上传
export function ossUpload(file) {
  return new Promise((resolve, reject) => {
    console.log('file', file)

    // 获得上传令牌
    ossToken().then(res => {
      const data = res.data
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: data.region,
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 300000,
        // 填写Bucket名称。
        bucket: data.bucket
      })
      // 配置信息
      const conf = data
      const key = buildDir() + getFileExt(file)

      // 分片上传。
      client.multipartUpload(key, file, {
        progress: (p) => {
          const percent = Math.round(p * 100)
          if (percent === 100) {
            resolve(conf.url + key)
          }
        }
      })
    }).catch(err => {
      Notification.error({
        title: '提示信息',
        message: `OSS上传失败： ${err} `
      })
      reject(err)
    })
  })
}

/**
 * 腾讯云文件上传
 * @param file
 * @returns {Promise<unknown>}
 */
export function cosUpload(file) {
  return new Promise((resolve, reject) => {
    // 获得上传令牌
    cosToken().then(res => {
      const conf = res.data
      // 使用临时秘钥初始化上传对象
      // eslint-disable-next-line no-undef
      const cos = new COS({
        SecretId: res.data.tmpSecretId,
        SecretKey: res.data.tmpSecretKey,
        XCosSecurityToken: res.data.sessionToken
      })

      const key = buildDir() + getFileExt(file)

      cos.sliceUploadFile({
        Bucket: conf.bucket,
        Region: conf.region,
        Key: key,
        StorageClass: 'STANDARD',
        Body: file,
        onTaskReady: function(taskId) {
          // that.taskId = taskId
        },
        onProgress: function(progressData) {
        }
      }, function(err, data) {
        // 保存数据
        const url = conf.url + data.Key
        resolve(url)
      })
    }).catch(err => {
      Notification.error({
        title: '提示信息',
        message: `获取COS上传令牌失败： ${err} `
      })
      reject(err)
    })
  })
}

/**
 * 七牛云文件上传
 * @param file
 * @returns {Promise<unknown>}
 */
export function qiniuUpload(file) {
  return new Promise((resolve, reject) => {
    // 获得上传Token
    qiniuToken().then(res => {
      const data = res.data

      const dataObj = {
        'token': data.token,
        'key': buildDir() + getFileExt(file)
      }

      upload(data.endpoint, file, dataObj).then(() => {
        // 保存数据
        const url = data.url + dataObj.key
        resolve(url)
      })
    }).catch(err => {
      Notification.error({
        title: '提示信息',
        message: `获取七牛令牌失败： ${err} `
      })
      reject(err)
    })
  })
}

/**
 * 获取文件后缀
 * @param file
 * @returns {string}
 */
export function getFileExt(file) {
  if (file.type === 'image/png') {
    return '.png'
  }

  if (file.type === 'image/jpeg') {
    return '.jpg'
  }

  const name = file.name
  const ext = name.split('.').pop()
  if (ext != null && ext !== '') {
    return '.' + ext.toLowerCase()
  }
  return ''
}

/**
 * 进行图像压缩
 * @param file
 * @returns {string}
 */
export async function compressImage(file, compress) {
  return await new Promise((resolve, reject) => {
    // 超出多少K进行压缩
    const kb = file.size / 1024

    if (!compress || compress >= kb) {
      resolve(file)
      return
    }

    console.log('执行图片压缩：' + kb + 'K')

    // 压缩至500K，宽度800
    const config = {
      size: 500,
      width: 800,
      type: file.type
    }

    console.log('开始压缩：' + kb + 'K')

    compressAccurately(file, config).then(res => {
      console.log('图片压缩后：' + (res.size / 1024) + 'K')
      res.uid = file.uid
      resolve(res)
    })
  })
}
