<template>


  <div>

    <div class="white-box" style="display: flex" >
      <div style="width: 200px; height: auto">
        <el-image :src="detail.image"/>
      </div>
      <div style="padding-left: 20px; line-height: 32px">
        <div class="font-title" style="font-size: 22px; padding-bottom: 10px">{{ detail.realName }}</div>
        <div class="font-small" style="font-size: 14px">{{ detail.title }}</div>
        <div class="font-small" style="font-size: 14px">{{ detail.intro }}</div>
        <div class="font-small" style="font-size: 14px">入驻时间：{{ detail.createTime }}</div>
      </div>
    </div>

    <el-tabs :value="'base'">
      <el-tab-pane label="TA的课程" name="base"/>
    </el-tabs>

    <!-- 课程列表 -->
    <data-grid
      :options="options"
      :list-query="listQuery"
      empty-text="该讲师还没有发布课程哦~">

      <template slot="filter-content"/>

      <template v-slot:data-item="{ data }">
        <!-- 引入课程卡片 -->
        <course-card :data="data" />
      </template>

    </data-grid>

  </div>

</template>

<script>
import DataGrid from '@/components/DataGrid/index.vue'
import { lecturerDetail } from '@/api/lecturer/lecturer'
import UserUpdateBase from '@/views/admin/sys/user/components/UserUpdateDialog/base.vue'
import CourseCard from '@/components/CourseCard/index.vue'
import CourseCatalog from '@/views/pages/course/components/CourseCatalog/index.vue'

export default {
  components: { CourseCatalog, CourseCard, UserUpdateBase, DataGrid },
  data() {
    return {

      lecturerId: null,
      detail: {},

      listQuery: {
        current: 1,
        size: 12,
        params: {
          lecturerId: this.lecturerId
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/course/course/client-paging',
        lineCount: 5
      }
    }
  },
  created() {
    this.lecturerId = this.$route.params.id
    this.listQuery.params.lecturerId = this.lecturerId
    this.showDetail()
  },

  methods: {


    // 讲师详情
    showDetail() {
      lecturerDetail(this.lecturerId).then(res => {
        this.detail = res.data
      })
    },

    toDetail(id) {
      this.$router.push({ name: 'NoticeDetail', params: { id: id }})
    }
  }
}
</script>

<style scoped>
.not-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .el-link{
  font-size: 16px;
  font-weight: 700;
  color: #9E2622;
}
</style>
