import { post } from '@/utils/request'

/**
 * 查找详情
 * @param id
 * @returns {Promise}
 */
export function detail(id) {
  return post('/api/home/banner/detail', { id: id })
}

/**
 * 保存
 * @param data
 * @returns {Promise}
 */
export function save(data) {
  return post('/api/home/banner/save', data)
}

/**
 * 获取轮播图分页
 * @param data
 * @returns {Promise}
 */
export function fetchPaging(data) {
  return post('/api/home/banner/paging', data)
}
