<template>

  <div>
    <media-manage :space-id="userId" />
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import MediaManage from '@/components/MediaManage/index.vue'

export default {
  components: { MediaManage },
  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([
      'siteData',
      'userId'
    ])
  },

  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>

