<template>

  <div>

    <div class="dir-box" >
      <span style="color: #c0c4cc"><i class="el-icon-caret-bottom"/></span> {{ dir.title }}
    </div>

    <div v-for="file in dir.fileList" :key="file.id" class="file-box">
      <div
        :class="{'current': file.fileId === currentId, 'disabled': !file.unlocked && !file.tryLearn}"
        :title="file.title"
        class="file-item"
        @click="toFile(file)">
        <i v-if="!file.unlocked && !file.tryLearn" class="el-icon-lock"/>
        <i v-else-if="file.fileType==='video'" class="el-icon-video-play"/>
        <i v-else class="el-icon-document"/>
        {{ file.title }}
      </div>

      <span v-if="file.unlocked">

        <el-progress
          v-if="calcPercent(file)===100"
          :percentage="100"
          :width="20"
          :stroke-width="2"
          status="success"
          style="margin-right: 10px"
          text-color="#fff" type="circle"/>

        <el-progress
          v-else
          :percentage="calcPercent(file)"
          :width="20"
          :stroke-width="2"
          style="margin-right: 10px"
          text-color="#fff" type="circle"/>
      </span>
    </div>

    <div v-if="dir.children && dir.children.length> 0">
      <div v-for="sub in dir.children" :key="sub.id" style="padding-left: 10px">
        <course-dir-learn :dir="sub" :file-id="fileId" :course-id="courseId" @select="toFile"/>
      </div>
    </div>

  </div>

</template>

<script>


export default {
  name: 'CourseDirLearn',
  props: {
    dir: { type: Object },
    fileId: String,
    courseId: String
  },
  data() {
    return {
      currentId: ''
    }
  },

  watch: {
    fileId: {
      handler(val) {
        this.currentId = val
      }
    },
    dir: {
      handler(val) {
        console.log('变化了', val)
      },
      deep: true
    }
  },
  created() {
    this.currentId = this.fileId
  },
  methods: {
    // 前往学习页面
    toFile(file) {
      this.$emit('select', file)
      this.currentId = file.fileId
    },
    calcPercent(file) {
      let rate = 0
      if (file.learnSec > 0 && file.needLearn > 0) {
        rate = file.learnSec * 100 / file.needLearn
      }
      if (rate > 100) {
        rate = 100
      }
      return parseInt(rate)
    }
  }
}
</script>

<style scoped>

.dir-box{
  color: #fff;
  border-bottom: #555 1px dotted;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 700;
}

.file-box{
  padding-left: 5px;
  color: #ccc;
  font-size: 14px;
  line-height: 26px;
  margin-top: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.file-box > .file-item{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.file-box > div:hover{
  color: #ff3333;
}

.file-box > .current{
  color: #FBBE0F;
}

.file-box > .disabled{
  cursor: default;
  color: #999;
}

.file-box > .disabled:hover{
  color: #999;
}


</style>
