var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-grid", {
    attrs: {
      options: _vm.options,
      "list-query": _vm.listQuery,
      "empty-text": "暂无相关记录，赶紧去学习获取吧！",
    },
    scopedSlots: _vm._u([
      {
        key: "filter-content",
        fn: function () {
          return [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                placeholder: "查找标题",
                "prefix-icon": "el-icon-search",
              },
              model: {
                value: _vm.listQuery.params.refRemark,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery.params, "refRemark", $$v)
                },
                expression: "listQuery.params.refRemark",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "data-item",
        fn: function (ref) {
          var data = ref.data
          return [_c("points-log-card", { attrs: { data: data } })]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }