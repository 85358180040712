var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        ref: "pagingTable",
        attrs: {
          "auto-scroll": false,
          options: _vm.options,
          "list-query": _vm.listQuery,
        },
        on: { add: _vm.handleAdd, delete: _vm.handleRemove },
        scopedSlots: _vm._u([
          {
            key: "filter-content",
            fn: function () {
              return [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "搜索ID/账号/姓名/手机号",
                    size: "small",
                  },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "用户ID", align: "center", prop: "id" },
                }),
                _c("el-table-column", {
                  attrs: { label: "账号", align: "center", prop: "userName" },
                }),
                _c("el-table-column", {
                  attrs: { label: "姓名", align: "center", prop: "realName" },
                }),
                _c("el-table-column", {
                  attrs: { label: "手机号码", align: "center", prop: "mobile" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("user-select-dialog", {
        attrs: { "dialog-show": _vm.dialogShow, excludes: _vm.excludes },
        on: {
          "update:dialogShow": function ($event) {
            _vm.dialogShow = $event
          },
          "update:dialog-show": function ($event) {
            _vm.dialogShow = $event
          },
          select: _vm.handleSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }