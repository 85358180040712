var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.tagList, function (item, index) {
        return _c(
          "el-tag",
          {
            key: index,
            staticStyle: { "margin-right": "10px" },
            attrs: { closable: "" },
            on: {
              close: function ($event) {
                return _vm.closeTag(item)
              },
            },
          },
          [_vm._v(_vm._s(item))]
        )
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "mini" },
          on: { click: _vm.showAdd },
        },
        [_vm._v("+ 添加")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              _vm.dialogVisible = false
            },
          },
        },
        [
          _c("el-time-picker", {
            attrs: {
              "picker-options": {
                selectableRange: "00:00:00 - 23:59:59",
              },
              "is-range": "",
              "value-format": "HH:mm",
              format: "HH:mm",
              "range-separator": "至",
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
              placeholder: "选择时间范围",
            },
            model: {
              value: _vm.rangeValue,
              callback: function ($$v) {
                _vm.rangeValue = $$v
              },
              expression: "rangeValue",
            },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }