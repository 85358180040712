<template>
  <div class="box-inner" @click="toDetail(data.id)">
    <div class="image-3x2">
      <el-image :src="data.cover">
        <div slot="error">
          <el-image :src="defaultCover" />
        </div>
      </el-image>
    </div>
    <div class="font-title c-link" style="padding-top: 10px">{{ data.title }}</div>
    <div class="flex-bt">
      <span class="font-second">共{{ data.fileCount }}节课</span>
      <span v-if="data.tryLearn && showTry" class="try-btn try-tag">试学</span>
    </div>
    <el-divider v-if="price" />
    <div v-if="price" class="flex-bt">
      <div class="font-small c-link" @click="toCatalog(data.catId)">{{ data.catId_dictText || '所有课程' }}</div>
      <div class="price-show">￥{{ data.price }}</div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'CourseCard',
  props: {
    data: {
      type: Object
    },
    price: {
      type: Boolean,
      default: true
    },
    showTry: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 默认封面
      defaultCover: require('@/assets/web/default_cs.png')
    }
  },

  created() {

  },
  methods: {
    // 课程详情
    toDetail(id) {
      this.$router.push({ name: 'CourseView', params: { courseId: id }})
    },

    // 目录
    toCatalog(id) {
      const route = this.$router.resolve({ name: 'CourseIndex', query: { catId: id }})
      window.open(route.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-3x2{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56%;
  cursor: pointer;
  .el-image{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}
</style>
