<template>

  <div>

    <!-- 课程列表 -->
    <data-grid
      ref="grid"
      :options="options"
      :list-query="listQuery">

      <template slot="filter-content">

        <el-tabs v-model="queryState" style="width: 100%">
          <el-tab-pane label="全部" name="00"/>
          <el-tab-pane label="待提审" name="10"/>
          <el-tab-pane label="审核中" name="11"/>
          <el-tab-pane label="已退回" name="13"/>
          <el-tab-pane label="待上架" name="21"/>
          <el-tab-pane label="售卖中" name="20"/>
        </el-tabs>

        <div class="flex" style="width: 100%">

          <div style="flex-grow: 1;display: flex; align-items: center;">
            <div style="font-size: 14px; font-weight: 500; color: #303133">课程搜索： </div>

            <dic-catalog-tree v-model="listQuery.params.catId" dic-code="course_catalog" class="filter-item"/>
            <el-input
              v-model="listQuery.params.title"
              class="filter-item"
              placeholder="搜索课程名称"
              prefix-icon="el-icon-search"
              size="small"
              style="width: 200px; margin-left: 10px"/>
          </div>
          <div>
            <el-button type="danger" size="small" icon="el-icon-plus" round @click="showAdd">新建课程</el-button>
          </div>
        </div>


      </template>

      <template v-slot:data-item="{ data }">
        <!-- 引入课程卡片 -->
        <course-card-list :data="data" @action="handleAction" />
      </template>

    </data-grid>

    <course-create-dialog
      :visible.sync="dialogVisible"
      @refresh="refreshTable"/>

    <course-audit-msg-dialog
      :id="courseId"
      :visible.sync="auditMsgVisible" />


  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import DataGrid from '@/components/DataGrid/index.vue'
import CourseCardList from '@/views/pages/uc/lecturer/course/components/CourseCardList/index.vue'
import CourseCreateDialog from '@/views/pages/uc/lecturer/course/components/CourseCreateDialog/index.vue'
import { auditSubmit, putOnSell } from '@/api/lecturer/course'
import DicCatalogTree from '@/components/DicCatalogTree/index.vue'
import CourseAuditMsgDialog from '@/views/pages/uc/lecturer/course/components/CourseAuditMsgDialog/index.vue'

export default {
  components: { CourseAuditMsgDialog, DicCatalogTree, CourseCreateDialog, CourseCardList, DataGrid },
  data() {
    return {

      courseId: null,
      queryState: '00',
      listQuery: {
        current: 1,
        size: 12,
        params: {
          catId: ''
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/course/course/lecturer-paging',
        lineCount: 1
      },
      dialogVisible: false,
      auditMsgVisible: false
    }
  },

  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  watch: {
    queryState: {
      handler(val) {
        if (val === '00') {
          this.listQuery.params.auditState = ''
          this.listQuery.params.sellState = ''
        }

        if (val === '10') {
          this.listQuery.params.auditState = 0
        }
        if (val === '11') {
          this.listQuery.params.auditState = 1
        }
        if (val === '13') {
          this.listQuery.params.auditState = 3
          this.listQuery.params.sellState = null
        }
        if (val === '20') {
          this.listQuery.params.auditState = 2
          this.listQuery.params.sellState = 0
        }
        if (val === '21') {
          this.listQuery.params.auditState = 2
          this.listQuery.params.sellState = 1
        }

        this.$refs.grid.getList()
      }
    }
  },

  created() {
    const catId = this.$route.query.catId
    if (catId) {
      this.listQuery.params.catId = catId
    }
  },
  methods: {

    showAdd() {
      this.courseId = ''
      this.dialogVisible = true
    },

    // 刷新数据
    refreshTable() {
      this.$refs.grid.getList()
    },


    // 提交审核
    actionAudit(id) {
      this.$confirm('请确保课程信息完整，定价设置合理，审核通过后将无法再次修改！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        auditSubmit({ id: id, audit: true }).then(() => {
          this.$message.success('提审成功！')
          this.refreshTable()
        })
      })
    },

    // 撤回审核
    actionRollback(id) {
      this.$confirm('确认要撤回审核吗！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        auditSubmit({ id: id, audit: false }).then(() => {
          this.$message.success('撤回成功！')
          this.refreshTable()
        })
      })
    },

    // 提交审核
    onSell(id) {
      this.$confirm('确定要上架吗！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        putOnSell({ ids: [id], state: 0 }).then(() => {
          this.$message.success('上架成功！')
          this.refreshTable()
        })
      })
    },

    // 撤回审核
    offSell(id) {
      this.$confirm('确实要下架课程吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        putOnSell({ ids: [id], state: 1 }).then(() => {
          this.$message.success('下架成功！')
          this.refreshTable()
        })
      })
    },

    handleAction({ action, id }) {
      console.log('++++action', action)

      if (action === 'audit') {
        this.actionAudit(id)
      }
      if (action === 'rollback') {
        this.actionRollback(id)
      }
      if (action === 'onSell') {
        this.onSell(id)
      }
      if (action === 'offSell') {
        this.offSell(id)
      }
      if (action === 'auditMsg') {
        this.courseId = id
        this.auditMsgVisible = true
      }
    }
  }
}
</script>

<style scoped>


</style>

