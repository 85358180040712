<template>

  <div>

    <div v-if="visible">
      <video-player
        ref="player"
        v-model="file.viewUrl"
        :drag="check.videoDrag"
        :ref-id="`read_${course.id}_${file.fileId}`"
        @play="onPlay"
        @pause="onPause"
        @ended="onEnded"
      />
    </div>

    <!-- SOCKET记录时间 -->
    <read-socket
      v-if="file.fileId && visible && playing"
      :file-id="file.fileId"
      :course-id="course.id"
      @change="changeLearnMin"
      @finish="handleFinish"
      @break="checkBreak"
    />

    <!-- 行为校验 -->
    <read-check
      v-if="file.fileId && visible && playing && check.checkOn"
      :time-min="check.checkSec"
      @break="checkBreak"
    />

  </div>

</template>

<script>
import { fixProcess } from '@/api/course/file'
import ReadCheck from '@/views/pages/course/components/ReadCheck/index.vue'
import ReadSocket from '@/views/pages/course/components/ReadSocket/index.vue'
import VideoPlayer from '@/components/VideoPlayer/index.vue'

export default {
  name: 'FilePlayer',
  components: { VideoPlayer, ReadSocket, ReadCheck },
  props: {
    course: Object,
    check: Object,
    file: Object
  },
  data() {
    return {
      playing: false,
      visible: true
    }
  },

  created() {
    // 监听页面状态
    document.addEventListener('visibilitychange', this.stateChange)
  },
  methods: {

    // 监听面可见状态
    stateChange() {
      if (document.visibilityState === 'hidden') {
        this.visible = false
        this.$emit('pause')
      } else {
        this.visible = true
      }

      console.log('++++++video-page-visible', this.visible)
    },

    // 阅读进度变化
    changeLearnMin(data) {
      this.$emit('change', data)
    },

    // 完成课件阅读
    handleFinish(json) {
      this.$emit('finish', json)
    },

    onPlay() {
      this.playing = true
      this.$emit('play')
    },

    onPause() {
      this.playing = false
      this.$emit('pause')
    },

    // 暂停
    pause() {
      this.$refs.player.pause()
    },

    onEnded() {
      console.log('播放结束啦！')

      const params = { courseId: this.course.id, fileId: this.file.fileId }
      fixProcess(params).then(res => {
        this.handleFinish(res.data)
      })
    },

    // 校验超时
    checkBreak() {
      // 跳转到首页
      this.$router.push({ name: 'WebIndex' })
    }
  }
}
</script>

<style scoped>

  .file-item{

    border: #ddd 1px solid;
    text-align: left;
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .file-item-active {

    border: #1890ff 1px solid;
    box-sizing: border-box;
    color: #1890ff;
    font-weight: 700;
  }

   ::v-deep
   .content{
     text-align: left;
   }

   ::v-deep
   .content img{
     text-align: left;
     max-width: 100%;
   }

</style>
