var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "data-grid",
    {
      attrs: { options: _vm.options, "list-query": _vm.listQuery },
      scopedSlots: _vm._u([
        {
          key: "data-item",
          fn: function (ref) {
            var data = ref.data
            return [
              _c("div", { staticClass: "not-box" }, [
                _c(
                  "div",
                  { staticStyle: { "flex-grow": "1" } },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "warning" },
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(data.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(data.title))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "font-second",
                    staticStyle: { width: "200px", "text-align": "right" },
                  },
                  [_vm._v(" " + _vm._s(data.createTime) + " ")]
                ),
              ]),
              _c("div", { staticClass: "font-small" }, [
                _vm._v(_vm._s(data.content)),
              ]),
            ]
          },
        },
      ]),
    },
    [
      _c("template", { slot: "filter-content" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  "font-weight": "500",
                  color: "#303133",
                },
              },
              [_vm._v("公告搜索： ")]
            ),
            _c(
              "div",
              [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "300px" },
                  attrs: {
                    size: "small",
                    placeholder: "搜索公告",
                    "prefix-icon": "el-icon-search",
                  },
                  model: {
                    value: _vm.listQuery.params.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery.params, "title", $$v)
                    },
                    expression: "listQuery.params.title",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }