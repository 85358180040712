var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _vm.postForm && _vm.postForm.id && _vm.postForm.state === 0
            ? _c("el-alert", { attrs: { type: "warning" } }, [
                _vm._v("实名认证审核中，审核通过后才能进行人脸登录！"),
              ])
            : _vm._e(),
          _vm.postForm && _vm.postForm.id && _vm.postForm.state === 1
            ? _c("el-alert", { attrs: { type: "success" } }, [
                _vm._v("实名认证通过，您可以使用人脸进行登录！"),
              ])
            : _vm._e(),
          _vm.postForm && _vm.postForm.id && _vm.postForm.state === 2
            ? _c("el-alert", { attrs: { type: "error" } }, [
                _vm._v("实名认证未通过：" + _vm._s(_vm.postForm.auditMsg)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-width": "100px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "真实姓名", prop: "realName" } },
            [
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.postForm.realName,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "realName", $$v)
                  },
                  expression: "postForm.realName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "人脸照片", prop: "face" } },
            [
              _c("file-upload", {
                attrs: {
                  limit: 1,
                  compress: 3000,
                  "list-type": "picture-card",
                  tips: "用于人脸登录，需要保证正脸位于图片中央，图片质量会影响识别率",
                  accept: ".jpg,.png,.jpeg",
                },
                model: {
                  value: _vm.postForm.face,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "face", $$v)
                  },
                  expression: "postForm.face",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号", prop: "idCard" } },
            [
              _c("el-input", {
                attrs: { size: "small" },
                model: {
                  value: _vm.postForm.idCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "idCard", $$v)
                  },
                  expression: "postForm.idCard",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _vm.postForm && _vm.postForm.state === 1
                ? _c(
                    "div",
                    { staticStyle: { "font-size": "12px", color: "#888" } },
                    [
                      _vm._v(
                        " *修改认证需要重新审核，通过之前，旧的人脸依然可以登录！ "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleSubmit },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.postForm && _vm.postForm.id
                          ? "修改认证"
                          : "提交认证"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }