<template>

  <div>


    <div>
      <div class="u-title">用户信息</div>
      <div>
        <el-descriptions>
          <el-descriptions-item label="编号">{{ userData.id }}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{ userData.realName }}</el-descriptions-item>
          <el-descriptions-item label="部门">{{ userData.deptCode_dictText }}</el-descriptions-item>
          <el-descriptions-item label="积分">{{ userData.points }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </div>


    <div style="margin-top: 30px">
      <div class="u-title">最近学习</div>
      <div>
        <div v-if="progressList.length>0">
          <div v-for="item in progressList" :key="item.id" class="dt-item">
            <learn-ps-card :data="item" />
          </div>
        </div>
        <div v-else>
          <el-empty description="最近没有学习记录哦，快去课程库学习吧！"/>
        </div>
      </div>
    </div>


    <div style="margin-top: 30px">
      <div class="u-title">积分获得</div>
      <div>
        <div v-if="logList.length>0">
          <div v-for="item in logList" :key="item.id" class="dt-item">
            <points-log-card :data="item"/>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无积分记录，通过学习可以获得积分噢！"/>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { findInfo } from '@/api/sys/user/user'
import { progressPaging } from '@/api/course/learn'
import { logPaging } from '@/api/sys/points/points'
import LearnPsCard from '@/views/pages/uc/components/LearnPsCard/index.vue'
import PointsLogCard from '@/views/pages/uc/components/PointsLogCard/index.vue'

export default {
  components: { PointsLogCard, LearnPsCard },
  data() {
    return {
      active: 'user',
      state: '',
      userData: {},
      progressList: [],
      logList: []
    }
  },
  computed: {
    ...mapGetters([
      'siteData',
      'token'
    ])
  },

  created() {
    this.fetchInfo()
    this.fetchProgress()
    this.fetchLog()
  },
  methods: {

    fetchInfo() {
      findInfo(this.token).then(res => {
        this.userData = res.data
      })
    },

    fetchProgress() {
      progressPaging({ current: 1, size: 5 }).then(res => {
        this.progressList = res.data.records
      })
    },

    fetchLog() {
      logPaging({ current: 1, size: 5 }).then(res => {
        this.logList = res.data.records
      })
    }

  }
}
</script>

<style scoped>


.u-title{
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
  margin-bottom: 20px;
  position: relative;
}


.u-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #C6302B 0%, #C6302B 80px, #ccc 80px, #ccc 100%);
}

.dt-item{
  border-bottom: #eee 1px dotted;
  padding: 10px 0;
  line-height: 28px
}


</style>
