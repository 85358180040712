import { post } from '@/utils/request'

/**
 * 批量授权颁发
 * @param data
 * @returns {*}
 */
export function grantBatch(data) {
  return post('/api/cert/grant/grant-batch', data)
}

/**
 * 我的证书列表
 * @returns {*}
 */
export function myGrant() {
  return post('/api/cert/grant/my-list')
}

/**
 * 证书详情
 * @returns {*}
 */
export function grantDetail(id) {
  return post('/api/cert/grant/detail', { id: id })
}
