<template>

  <div>

    <data-table
      ref="pagingTable"
      :options="options"
      :list-query="listQuery"
      @add="showAdd"
      @edit="showEdit"
      @multi-actions="handleMultiActions"
    >
      <template #filter-content>
        <el-input v-model="listQuery.params.title" style="width: 200px" placeholder="搜索文件" class="filter-item" size="small" />
      </template>
      <template #data-columns>


        <el-table-column
          label="文件信息"
        >
          <template v-slot="scope">
            <div class="font-title" @click="showPreview(scope.row)">
              <file-icon-show :value="scope.row.fileType" />
              <span class="c-link"> {{ scope.row.title }} </span>
              <span v-if="scope.row.fileType==='video'" class="font-small">
                <sec-format :value="scope.row.duration"/>
              </span>
            </div>
            <div class="font-small">{{ scope.row.sizeDesc }}</div>
            <div>{{ scope.row.fileType_dictText }}</div>
          </template>

        </el-table-column>

        <el-table-column
          label="引用"
          align="center"
          width="100px">
          <template v-slot="{row}">
            <el-link @click="showRef(row.id)">{{ row.refCount }}</el-link>
          </template>
        </el-table-column>

        <el-table-column
          label="状态"
          align="center"
          width="120px"
        >

          <template v-slot="scope">
            <el-tag v-if="scope.row.state===0" size="mini" type="success">正常</el-tag>
            <el-tag v-if="scope.row.state===1" size="mini" type="warning">转码中</el-tag>
            <el-tag v-if="scope.row.state===2" size="mini" type="danger">转码失败</el-tag>
          </template>

        </el-table-column>

      </template>

    </data-table>


    <media-save-dialog
      :visible.sync="dialogVisible"
      :file-id="fileId"
      :space-id="spaceId"
      :cat-id="catId"
      @success="reloadList"
    />

    <media-preview-dialog
      :visible.sync="previewVisible"
      :file-type="previewFileType"
      :view-url="previewUrl" />

    <media-catalog-batch
      :visible.sync="batchVisible"
      :ids="selectedIds"/>

    <media-ref-dialog
      :visible.sync="refVisible"
      :file-id="fileId"
    />
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import MediaSaveDialog from '@/components/MediaManage/MediaSaveDialog/index.vue'
import FileIconShow from '@/components/FileIconShow/index.vue'
import MediaPreviewDialog from '@/components/MediaManage/MediaPreviewDialog/index.vue'
import MediaCatalogBatch from '@/components/MediaManage/MediaCatalogBatch/index.vue'
import { transcode, fetchInfo } from '@/api/media/file'
import MediaRefDialog from '@/components/MediaManage/MediaRefDialog/index.vue'

export default {
  name: 'MediaFileEdit',
  components: { MediaRefDialog, MediaCatalogBatch, MediaPreviewDialog, FileIconShow, MediaSaveDialog },
  props: {
    spaceId: String,
    catId: String,
    fileType: String
  },
  data() {
    return {
      loading: false,
      selectedIds: [],
      listQuery: {
        current: 1,
        size: 10,
        params: {
          filType: '',
          catId: ''
        }
      },
      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        // 列表请求URL
        listUrl: '/api/media/file/paging',
        add: {
          enable: true
        },
        edit: {
          enable: true
        },
        delete: {
          enable: true,
          permission: '',
          url: '/api/media/file/delete'
        },
        // 批量操作列表
        multiActions: [{
          value: 'batch-catalog',
          label: '批量迁移分类'
        },
        {
          value: 'transcode',
          label: '重新转码'
        },
        {
          value: 'get-info',
          label: '读取文件大小&时长'
        }
        ],
        // 是否支持选中
        selectable: (row) => {
          return row.refCount === 0
        }
      },

      dialogVisible: false,
      fileId: null,

      previewVisible: false,
      previewFileType: null,
      previewUrl: null,
      batchVisible: false,

      refVisible: false
    }
  },


  computed: {
    ...mapGetters([
      'userId'
    ])
  },

  watch: {

    fileType: {
      handler(val) {
        this.listQuery.params.fileType = val
        this.$refs.pagingTable.getList()
      }
    },

    catId: {
      handler(val) {
        this.listQuery.params.catId = val
        this.$refs.pagingTable.getList()
      }
    }
  },
  created() {
    this.listQuery.fileType = this.fileType
    this.listQuery.catId = this.catId
  },

  methods: {

    showAdd() {
      this.fileId = ''
      this.dialogVisible = true
    },
    showEdit(id) {
      this.fileId = id
      this.dialogVisible = true
    },
    showPreview(data) {
      this.previewFileType = data.fileType
      this.previewUrl = data.viewUrl
      this.previewVisible = true
    },
    showRef(id) {
      this.fileId = id
      this.refVisible = true
    },
    reloadList() {
      this.$refs.pagingTable.getList()
    },
    handleMultiActions(event) {
      if (event.opt === 'batch-catalog') {
        this.batchVisible = true
        this.selectedIds = event.ids
      }
      if (event.opt === 'transcode') {
        transcode(event.ids).then(() => {
          this.$message.success('转码命令已发送，请稍后刷新列表查看！')
        })
      }
      if (event.opt === 'get-info') {
        fetchInfo(event.ids).then(() => {
          this.$message.success('信息读取命令已发送，请稍后刷新列表查看！')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
