var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "calc-item" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("最低分数：")]),
        _c("el-input-number", {
          attrs: { size: "mini" },
          model: {
            value: _vm.minValue,
            callback: function ($$v) {
              _vm.minValue = $$v
            },
            expression: "minValue",
          },
        }),
        _c(
          "div",
          { staticClass: "title", staticStyle: { "padding-left": "20px" } },
          [_vm._v("最高分数：")]
        ),
        _c("el-input-number", {
          attrs: { max: _vm.max + 1, size: "mini" },
          model: {
            value: _vm.maxValue,
            callback: function ($$v) {
              _vm.maxValue = $$v
            },
            expression: "maxValue",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "calc-item" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("分数节点：")]),
        _vm._l(_vm.splitList, function (tag) {
          return _c(
            "el-tag",
            {
              key: tag,
              staticStyle: { "margin-right": "10px" },
              attrs: { "disable-transitions": false, closable: "" },
              on: {
                close: function ($event) {
                  return _vm.removeSplit(tag)
                },
              },
            },
            [_vm._v(" " + _vm._s(tag) + " ")]
          )
        }),
        _vm.inputVisible
          ? _c("el-input-number", {
              ref: "saveTagInput",
              staticStyle: { width: "100px", "margin-right": "10px" },
              attrs: { min: _vm.minValue, max: _vm.maxValue, size: "mini" },
              on: { blur: _vm.handleInputConfirm },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleInputConfirm($event)
                },
              },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            })
          : _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.showInput },
              },
              [_vm._v("+添加")]
            ),
      ],
      2
    ),
    _vm.resultList.length > 0
      ? _c(
          "div",
          { staticClass: "calc-item" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("结果预览：")]),
            _vm._l(_vm.resultList, function (item) {
              return _c(
                "el-tag",
                {
                  key: item,
                  staticStyle: {
                    "margin-right": "10px",
                    width: "100px",
                    "text-align": "center",
                  },
                  attrs: { type: "danger" },
                },
                [_vm._v(_vm._s(item) + "分")]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }