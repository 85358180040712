<template>
  <data-table
    ref="pagingTable"
    :options="options"
    :list-query="listQuery"
  >
    <template #filter-content>
      <el-input v-model="listQuery.params.title" style="width: 200px" size="small" class="filter-item" placeholder="搜索标题" />
    </template>

    <template #data-columns>

      <el-table-column
        prop="title"
        label="标题"
        show-overflow-tooltip
      />

      <el-table-column
        label="金额"
        prop="amount"
        align="center"
        width="100px"
      />

      <el-table-column
        label="余额"
        prop="afterAmount"
        align="center"
        width="120px"
      />

      <el-table-column
        label="时间"
        prop="createTime"
        align="center"
        width="180px"
      />

    </template>
  </data-table>

</template>

<script>

export default {
  name: 'AccountLog',
  data() {
    return {
      dialogVisible: false,
      listQuery: {
        current: 1,
        size: 10,
        params: {
          payState: 1,
          title: ''
        }
      },

      options: {
        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/api/lecturer/account-log/lecturer-paging'
      }
    }
  },

  created() {
  },

  methods: {

    handleClose() {
      this.dialogVisible = false
      this.$emit('update:visible', false)
      this.$emit('success')
    },

    refreshList() {
      this.$refs.pagingTable.getList()
    }

  }
}
</script>
