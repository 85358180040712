<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="迁移分类"
    width="50%"
    @close="handleClose"
  >

    <media-catalog-tree @selected="dirSelected" @clear="dirClear"/>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>

</template>

<script>
import { batchUpdate } from '@/api/media/file'
import MediaCatalogTree from '@/components/MediaManage/MediaCatalogTree/index.vue'

export default {
  name: 'MediaCatalogBatch',
  components: { MediaCatalogTree },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    ids: Array
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      catId: ''
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },
  methods: {

    // 选定目录
    dirSelected(id) {
      this.catId = id
    },

    // 清理目录
    dirClear() {
      this.catId = ''
    },

    handleSave() {
      if (!this.catId) {
        this.$message.error('请先选择一个类目！')
        return
      }
      this.loading = true
      batchUpdate({ ids: this.ids, catId: this.catId }).then(() => {
        this.$message({
          type: 'success',
          message: '课件保存成功!'
        })
        this.handleClose()
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.dialogVisible = false
      this.loading = false
      this.$emit('success')
    }
  }
}
</script>

<style scoped>
</style>
