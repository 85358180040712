var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-grid", {
    attrs: {
      options: _vm.options,
      "list-query": _vm.listQuery,
      "empty-text": "还没有收藏记录，赶紧去课程库看看吧~",
    },
    scopedSlots: _vm._u([
      {
        key: "data-item",
        fn: function (ref) {
          var data = ref.data
          return [
            _c("course-card", {
              attrs: { data: data, price: false, "show-try": false },
            }),
            _c("course-fav", {
              staticStyle: { float: "right", "margin-top": "-32px" },
              attrs: { "course-id": data.id, size: "18px" },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }