var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "pan-back-btn",
          attrs: { icon: "el-icon-arrow-left" },
          on: { click: _vm.back },
        },
        [_vm._v(" 返回 ")]
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v(" Oops! "),
            ]),
            _c("h2", [_vm._v("抱歉，您没有权限访问当前页面！")]),
            _c("h6", [_vm._v("如有问题，请联系管理员：")]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [_vm._v("或者您可以:")]),
              _c("li", { staticClass: "link-type" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.logout()
                      },
                    },
                  },
                  [_vm._v("退出并重新登录")]
                ),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              attrs: {
                src: _vm.errGif,
                width: "313",
                height: "428",
                alt: "Girl has dropped her ice cream.",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }