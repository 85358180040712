<template>
  <div>
    <div style="height: 80px">
      <el-upload
        ref="upload"
        :action="actionUrl"
        :multiple="false"
        :limit="1"
        :headers="header"
        :data="data"
        :on-success="importSuccess"
        accept=".xlsx"
        list-type="file"
      >
        <el-button type="primary" icon="el-icon-upload">点击上传</el-button>
      </el-upload>
    </div>
    <el-link icon="el-icon-download" type="danger" style="float: right" @click="downloadTemplate">下载导入模板</el-link>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      title="提示信息"
      width="30%"
      @close="dialogVisible = false"
    >
      <span>{{ dialogMsg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { getToken } from '@/utils/auth'
import { download } from '@/utils/request'

export default {
  name: 'ImportExcel',
  props: {
    title: String,
    action: String,
    data: Object,
    template: String
  },
  data() {
    return {
      dialogVisible: false,
      dialogMsg: '',
      // 上传头
      header: {},
      actionUrl: ''
    }
  },

  watch: {
    // 检测查询变化
    action: {
      handler() {
        this.actionUrl = process.env.VUE_APP_BASE_API + this.action
      }
    }
  },

  created() {
    this.header = { token: getToken() }
    this.actionUrl = process.env.VUE_APP_BASE_API + this.action
  },

  methods: {

    downloadTemplate() {
      return download(this.template, {}, `${this.title}导入模板.xlsx`)
    },

    importSuccess(resp) {
      // 清除内容
      this.$refs.upload.clearFiles()

      if (resp.code === 0) {
        this.$message({
          message: '数据导入成功！',
          type: 'success'
        })

        this.$emit('success', resp)
      } else {
        // 用弹窗提示信息
        this.dialogMsg = resp.msg
        this.dialogVisible = true
      }
    }

  }
}
</script>
