<template>

  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    title="学习检测"
    width="500px"
  >

    <el-form ref="postForm" :model="postForm" label-position="left" label-width="120px">

      <el-form-item label="图形验证码" prop="captchaValue">
        <yf-captcha :id="key" v-model="postForm" />
      </el-form-item>

    </el-form>

    <div>

      <el-alert :closable="false" :title="`请输入正确的验证码继续学习，您还有${remindTime()}秒时间来确认！`" type="warning" />

      <div style="margin-top: 20px">
        <el-button :loading="loading" type="primary" @click="handleCheck">确认</el-button>
      </div>

    </div>

  </el-dialog>

</template>

<script>
import { uuid } from 'vue-uuid'
import { checkCode } from '@/api/ability/captcha'
import screenfull from 'screenfull'

export default {
  name: 'ReadCheck',
  props: {
    timeMin: Number
  },
  data() {
    return {
      goSec: 0,
      stopSec: 0,
      loading: false,
      dialogVisible: false,
      key: uuid.v4(),
      postForm: {
        captchaValue: '',
        captchaKey: ''
      },
      checkTimer: null,
      timeSec: 0
    }
  },
  watch: {
    timeMin: {
      handler(val) {
        if (val) {
          this.goSec = 0
          this.timeSec = val * 60
        }
      }
    }
  },

  mounted() {
    this.timeSec = this.timeMin * 60
    this.initTimer()
  },

  methods: {

    // 确认时间展示
    remindTime() {
      let time = this.stopSec - this.goSec

      if (time <= 0) {
        time = 0
      }

      return time
    },

    // 提交检查
    handleCheck() {
      this.loading = true
      checkCode(this.postForm).then(res => {
        this.loading = false

        // 正确校验后重置时间
        if (res.data) {
          this.clearTimer()
        } else {
          this.$message.error('验证码错误或已失效，请点击图片刷新验证码！')
        }

        this.key = uuid.v4()
      }).catch(() => {
        this.loading = false
      })
    },

    // 初始化定时器
    initTimer() {
      // 10秒时间来确认
      this.stopSec = this.timeSec + 30

      this.checkTimer = setInterval(() => {
        if (this.goSec >= this.timeSec && !this.dialogVisible) {
          this.dialogVisible = true
          screenfull.exit()
        }

        // 大于
        if (this.goSec > this.stopSec) {
          clearInterval(this.checkTimer)
          this.$emit('break')
        }

        this.goSec += 1
      }, 1000)
    },

    // 重新计时
    clearTimer() {
      this.goSec = 0
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
/deep/
.el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}

/deep/
.el-dialog .el-dialog__body{
  flex:1;
  overflow: auto;
}
</style>
