import { post } from '@/utils/request'

// 获取地址列表
export function fetchAddrList() {
  return post('/api/mall/user/addr/paging', { current: 1, size: 10, params: {}})
}

// 保存地址
export function saveAddr(data) {
  return post('/api/mall/user/addr/save', data)
}

// 删除地址
export function deleteAddr(id) {
  return post('/api/mall/user/addr/delete', { ids: [id] })
}

// 保存地址
export function fetchDetail(id) {
  return post('/api/mall/user/addr/detail', { id: id })
}

