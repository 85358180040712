var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "container-bg" },
    [
      _c(
        "el-header",
        { staticStyle: { padding: "0", height: "60px" } },
        [_c("web-header")],
        1
      ),
      _c("el-main", { staticStyle: { padding: "0" } }, [
        _c("div", { staticClass: "container main-inner" }, [_c("app-main")], 1),
      ]),
      _vm.siteData.copyRight
        ? _c(
            "el-footer",
            { staticClass: "footer-copy", staticStyle: { height: "auto" } },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "footer-box" }, [
                  _c(
                    "div",
                    { staticClass: "footer-box-inner" },
                    [
                      _c("el-image", {
                        staticStyle: { height: "50px" },
                        attrs: { src: _vm.siteData.frontLogo },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "footer-box-inner" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#fff",
                          "font-weight": "700",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v("系统指引")]
                    ),
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _c("a", { attrs: { href: "/pages/lecturer/apply" } }, [
                        _vm._v("讲师入驻"),
                      ]),
                    ]),
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _c("a", { attrs: { href: "/pages/notice" } }, [
                        _vm._v("系统公告"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "footer-box-inner" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#fff",
                          "font-weight": "700",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v("联系我们")]
                    ),
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("电话：18710213152（杨）18603038204（郭）"),
                    ]),
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("邮箱：626264481@qq.com"),
                    ]),
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("地址：北京市朝阳区十里堡路1号未来时大厦4层"),
                    ]),
                  ]),
                  _c("div", { staticClass: "footer-box-inner" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#fff",
                          "font-weight": "700",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v("关注我们")]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-bt" },
                      [
                        _c("el-image", {
                          staticStyle: { height: "100px" },
                          attrs: { src: _vm.siteData.mpCode },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "20px",
                      "font-size": "14px",
                    },
                    domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
                  },
                  [_vm._v(" ©2024 ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }