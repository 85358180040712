var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "white-box", staticStyle: { display: "flex" } },
        [
          _c(
            "div",
            { staticStyle: { width: "200px", height: "auto" } },
            [_c("el-image", { attrs: { src: _vm.detail.image } })],
            1
          ),
          _c(
            "div",
            { staticStyle: { "padding-left": "20px", "line-height": "32px" } },
            [
              _c(
                "div",
                {
                  staticClass: "font-title",
                  staticStyle: {
                    "font-size": "22px",
                    "padding-bottom": "10px",
                  },
                },
                [_vm._v(_vm._s(_vm.detail.realName))]
              ),
              _c(
                "div",
                {
                  staticClass: "font-small",
                  staticStyle: { "font-size": "14px" },
                },
                [_vm._v(_vm._s(_vm.detail.title))]
              ),
              _c(
                "div",
                {
                  staticClass: "font-small",
                  staticStyle: { "font-size": "14px" },
                },
                [_vm._v(_vm._s(_vm.detail.intro))]
              ),
              _c(
                "div",
                {
                  staticClass: "font-small",
                  staticStyle: { "font-size": "14px" },
                },
                [_vm._v("入驻时间：" + _vm._s(_vm.detail.createTime))]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-tabs",
        { attrs: { value: "base" } },
        [_c("el-tab-pane", { attrs: { label: "TA的课程", name: "base" } })],
        1
      ),
      _c(
        "data-grid",
        {
          attrs: {
            options: _vm.options,
            "list-query": _vm.listQuery,
            "empty-text": "该讲师还没有发布课程哦~",
          },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [_c("course-card", { attrs: { data: data } })]
              },
            },
          ]),
        },
        [_c("template", { slot: "filter-content" })],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }