// 课程模块
import WebLayout from '@/layout/WebLayout'

const courseRouter = {
  path: '/course',
  component: WebLayout,
  redirect: '/pages/course',
  hidden: true,
  children: [

    {
      path: '/pages/course',
      component: () => import('@/views/pages/course/index'),
      name: 'CourseIndex',
      meta: { title: '课程中心', noCache: true, activeMenu: '/pages/course' }
    },

    {
      path: '/pages/course/view/:courseId',
      component: () => import('@/views/pages/course/view'),
      name: 'CourseView',
      meta: { title: '课程详情', noCache: true, activeMenu: '/pages/course' }
    },

    {
      path: '/pages/course/learn',
      component: () => import('@/views/pages/course/learn'),
      name: 'CourseLearn',
      meta: { title: '课程学习', noCache: true, activeMenu: '/pages/course' },
      hidden: true
    },

    {
      path: '/pages/course/view/:courseId/:fileId',
      component: () => import('@/views/pages/course/view'),
      name: 'CourseViewWithFile',
      meta: { title: '课程详情', noCache: true, activeMenu: '/pages/course' }
    },

    {
      path: '/pages/lecturer/home/:id',
      component: () => import('@/views/pages/lecturer'),
      name: 'LecturerHome',
      meta: { title: '讲师首页', noCache: true, activeMenu: '/pages/course' }
    }

  ]
}
export default courseRouter
