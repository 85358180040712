var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-right": "20px" } },
    [
      _c(
        "div",
        { staticStyle: { "padding-bottom": "5px" } },
        [
          _c("el-button", {
            attrs: {
              icon: "el-icon-plus",
              plain: "",
              circle: "",
              size: "mini",
              type: "primary",
            },
            on: { click: _vm.showBatchAdd },
          }),
          _c("el-button", {
            attrs: {
              icon: "el-icon-delete",
              plain: "",
              circle: "",
              size: "mini",
              type: "danger",
            },
            on: { click: _vm.handleDelete },
          }),
        ],
        1
      ),
      _c("el-divider"),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.treeData,
          "expand-on-click-node": false,
          "default-checked-keys": [_vm.checkedId],
          "check-strictly": true,
          "empty-text": "点击上方+来创建章节",
          draggable: "",
          "check-on-click-node": "",
          "show-checkbox": "",
          "node-key": "id",
          "default-expand-all": "",
          "highlight-current": "",
        },
        on: {
          "node-drag-end": _vm.handleDragEnd,
          "node-click": _vm.checkChange,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return _c("div", { staticClass: "tree-node" }, [
                _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _c("el-input", {
                      attrs: { size: "mini" },
                      on: {
                        change: function ($event) {
                          return _vm.handleUpdate(data.id, data.title)
                        },
                      },
                      model: {
                        value: data.title,
                        callback: function ($$v) {
                          _vm.$set(data, "title", $$v)
                        },
                        expression: "data.title",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "opt" }, [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { title: "添加下级" },
                    on: {
                      click: function ($event) {
                        return _vm.addSubDir(data.id)
                      },
                    },
                  }),
                ]),
              ])
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "批量添加章节",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                model: _vm.postForm,
                rules: _vm.rules,
                "label-position": "top",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "章节数据", prop: "data" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "10",
                      placeholder:
                        "支持批量添加，每个层级进行换行，树结构使用'-'标识，'-'表示2级目录，'--'表示3级目录，依次类推",
                    },
                    model: {
                      value: _vm.postForm.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "data", $$v)
                      },
                      expression: "postForm.data",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.fillDemo },
                    },
                    [_vm._v("填充演示数据")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }