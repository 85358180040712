<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="选择题库"
    width="60%"
    top="5vh"
    @close="handleClose"
  >

    <div class="app-container">
      <data-table
        ref="pagingTable"
        :options="options"
        :list-query="listQuery"
        @select-changed="handleSelected"
      >
        <template #filter-content>

          <el-input v-model="listQuery.params.title" style="width: 200px" placeholder="搜索题库名称" class="filter-item" size="small"/>
          <el-button type="primary" class="filter-item" style="float:  right" size="small" @click="handleConfirm">{{ selectedLabel }}</el-button>

        </template>

        <template #data-columns>

          <el-table-column
            label="题库名称"
            align="center"
            prop="title"
          />

          <el-table-column
            label="试题总数"
            align="center"
            prop="quCount"
          />

          <el-table-column
            label="客观题数量"
            align="center"
            prop="objCount"
          />

          <el-table-column
            label="主观题数量"
            align="center"
            prop="subjCount"
          />

        </template>

      </data-table>
    </div>

  </el-dialog>

</template>

<script>

export default {
  name: 'RepoDialog',
  components: { },
  props: {
    excludes: Array,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      dialogVisible: false,
      selectedLabel: '请勾选题库',

      listQuery: {
        current: 1,
        size: 10,
        params: {
          excludes: []
        }
      },

      // 选定的数据列表
      selectedList: [],

      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        // 列表请求URL
        listUrl: '/api/repo/paging'
      }
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
        if (this.dialogVisible && this.$refs.pagingTable !== undefined) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {

    handleSelected(obj) {
      this.selectedList = obj.objs
      this.selectedLabel = '确认' + this.selectedList.length + '项'
    },

    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('close', this.selectedList)
    },

    handleConfirm() {
      this.$emit('update:visible', false)
      this.$emit('select', this.selectedList)
    }

  }
}
</script>

<style scoped>

  ::v-deep
  .el-dialog__body{
    padding: 0;
  }
</style>
