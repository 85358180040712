<template>
  <course-qa :is-mine="true"/>
</template>

<script>

import CourseQa from '@/views/pages/course/components/CourseQa/index.vue'

export default {
  components: { CourseQa }
}
</script>
