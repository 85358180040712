<template>

  <div class="header-bg">
    <div class="header-inner container">
      <div class="col-logo">
        <div v-if="siteData.frontLogo">
          <img :src="siteData.frontLogo" :alt="siteData.siteName" style="height: 40px;">
        </div>
        <div v-else class="site-tt">{{ siteData.siteName }}</div>
      </div>

      <div class="col-menu">

        <el-menu
          :router="true"
          :default-active="activeIndex"
          mode="horizontal"
          background-color="#C6302B"
          text-color="#fff"
          active-text-color="#FBBE0F">

          <el-menu-item index="/pages/index">首页</el-menu-item>
          <el-menu-item v-if="siteData.props.moduleCourse" class="module-course" index="/pages/course">课程库</el-menu-item>
          <el-menu-item v-if="siteData.props.moduleMall" index="/pages/mall" class="module-mall">积分商城</el-menu-item>
          <el-menu-item index="/pages/notice">系统公告</el-menu-item>
          <el-menu-item index="/pages/uc">用户中心</el-menu-item>
        </el-menu>
      </div>

      <div class="col-user">

        <div v-if="userId" class="login">
          <el-badge :value="unreadMsg" :max="99" :hidden="unreadMsg===0">
            <yf-avatar
              :src="avatar"
              border="#ccc 1px solid"
              @click.native="toMsg"/>
          </el-badge>
          <div :title="realName" class="uname">{{ realName }}</div>
          <el-link v-if="roleType===2" type="warning" @click="toAdmin">管理</el-link>
          <el-button size="mini" type="warning" round @click="logout">退出</el-button>
        </div>
        <div v-else class="guest">
          <el-button size="mini" type="danger" round @click="toReg">注册</el-button>
          <el-button size="mini" type="warning" round @click="toLogin">登录</el-button>
        </div>


      </div>

    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import YfAvatar from '@/components/YfAvatar/index.vue'

export default {
  components: { YfAvatar },

  data() {
    return {
      activeIndex: '/pages/index',
      msgTimer: null
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'userId',
      'realName',
      'siteData',
      'roleType',
      'unreadMsg'
    ])
  },
  created() {
    // 菜单状态
    this.activeIndex = this.$route.meta.activeMenu

    // 登录用户获取消息
    if (this.userId) {
      this.fetchMsg()
    }
  },

  methods: {

    // 跳转到消息
    toMsg() {
      this.$router.push('/pages/uc/im')
    },

    // 退出登录
    async logout() {
      this.$confirm('确实要退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.$store.dispatch('user/logout').then(() => {
          window.location.href = '/'
        })
      })
    },

    // 获取管理首页
    toAdmin() {
      const index = store.getters.index
      this.$router.push({ path: index })
    },

    // 跳转到登录页面
    toLogin() {
      const path = this.$route.path
      this.$router.push(`/login?redirect=${path}`)
    },

    // 跳转到注册页
    toReg() {
      const path = this.$route.path
      this.$router.push(`/register?redirect=${path}`)
    },

    // 获取消息
    fetchMsg() {
      // 清理
      if (this.msgTimer) {
        clearInterval(this.msgTimer)
      }
      // 20秒读取一次消息
      this.msgTimer = setInterval(() => {
        store.dispatch('user/fetchMsg')
      }, 20000)

      // 立即读取一次
      store.dispatch('user/fetchMsg')
    }
  }
}
</script>

<style scoped>
.header-bg {
  height: 59px;
  background: #C6302B;
  display: flex;
  justify-content: center
}

.header-inner{
  display: flex;
  align-items: center;
}

.site-tt {
  font-weight: 700;
  font-size: 22px;
  color: #eee;
  text-align: left;
}

.col-user {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  line-height: 60px;
  align-items: center;
  max-width: 240px;
}

.col-user > .login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 220px;
}


.col-user > .login > .uname {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-weight: 700;
}


.col-user > .login > .el-badge{
  align-items: center;
  display: flex;
}

.col-user > .guest {
  margin-right: 0 !important;
}

.col-logo {
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 250px;
}

.col-menu {
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
}


::v-deep .el-link{
  font-size: 16px;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  font-weight: 700;
  font-size: 16px;
}

::v-deep .el-menu--horizontal > .el-menu-item{
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-weight: 700;
    font-size: 16px;
    padding: 0 5px;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item{
    font-size: 16px;
    font-weight: 700;
    padding: 0 5px;
  }
}

</style>
