import { post } from '@/utils/request'


/**
 * 提现详情
 * @param id
 */
export function fetchDetail(id) {
  return post('/api/lecturer/cash/detail', { id: id })
}


/**
 * 标记为已支付
 * @param data
 */
export function markAsPaid(data) {
  return post('/api/lecturer/cash/mark-paid', data)
}


/**
 * 标记为已支付
 * @param data
 */
export function markAsReject(data) {
  return post('/api/lecturer/cash/mark-reject', data)
}
