var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-grid",
        {
          ref: "grid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c("course-card-list", {
                    attrs: { data: data },
                    on: { action: _vm.handleAction },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.queryState,
                    callback: function ($$v) {
                      _vm.queryState = $$v
                    },
                    expression: "queryState",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "全部", name: "00" } }),
                  _c("el-tab-pane", { attrs: { label: "待提审", name: "10" } }),
                  _c("el-tab-pane", { attrs: { label: "审核中", name: "11" } }),
                  _c("el-tab-pane", { attrs: { label: "已退回", name: "13" } }),
                  _c("el-tab-pane", { attrs: { label: "待上架", name: "21" } }),
                  _c("el-tab-pane", { attrs: { label: "售卖中", name: "20" } }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "flex-grow": "1",
                        display: "flex",
                        "align-items": "center",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "500",
                            color: "#303133",
                          },
                        },
                        [_vm._v("课程搜索： ")]
                      ),
                      _c("dic-catalog-tree", {
                        staticClass: "filter-item",
                        attrs: { "dic-code": "course_catalog" },
                        model: {
                          value: _vm.listQuery.params.catId,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.params, "catId", $$v)
                          },
                          expression: "listQuery.params.catId",
                        },
                      }),
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px", "margin-left": "10px" },
                        attrs: {
                          placeholder: "搜索课程名称",
                          "prefix-icon": "el-icon-search",
                          size: "small",
                        },
                        model: {
                          value: _vm.listQuery.params.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.params, "title", $$v)
                          },
                          expression: "listQuery.params.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            size: "small",
                            icon: "el-icon-plus",
                            round: "",
                          },
                          on: { click: _vm.showAdd },
                        },
                        [_vm._v("新建课程")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("course-create-dialog", {
        attrs: { visible: _vm.dialogVisible },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          refresh: _vm.refreshTable,
        },
      }),
      _c("course-audit-msg-dialog", {
        attrs: { id: _vm.courseId, visible: _vm.auditMsgVisible },
        on: {
          "update:visible": function ($event) {
            _vm.auditMsgVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }