var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "用户信息",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "base" } },
            [
              _c("user-update-base", {
                ref: "updateBase",
                attrs: { "user-id": _vm.userId, "depart-id": _vm.departId },
                on: { close: _vm.handleClose },
              }),
            ],
            1
          ),
          _vm.userId
            ? _c(
                "el-tab-pane",
                { attrs: { label: "账号绑定", name: "bind" } },
                [_c("user-update-bind", { attrs: { "user-id": _vm.userId } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }