var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-bt", staticStyle: { "line-height": "28px" } },
    [
      _c("div", { staticStyle: { "flex-grow": "1" } }, [
        _c("div", { staticClass: "font-title" }, [
          _c(
            "a",
            {
              staticClass: "c-link",
              on: {
                click: function ($event) {
                  return _vm.toDetail(_vm.data.courseId)
                },
              },
            },
            [_vm._v(_vm._s(_vm.data.courseName))]
          ),
        ]),
        _c("div", { staticClass: "font-small" }, [
          _vm._v(" " + _vm._s(_vm.data.fileName) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { width: "200px", "text-align": "right" } },
        [
          _c(
            "el-link",
            {
              attrs: { type: "danger", icon: "el-icon-d-arrow-right" },
              on: {
                click: function ($event) {
                  return _vm.toLearn(_vm.data.courseId, _vm.data.fileId)
                },
              },
            },
            [_vm._v("继续学习")]
          ),
          _c("div", { staticClass: "font-small" }, [
            _vm._v(_vm._s(_vm.data.updateTime || _vm.data.createTime)),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }