var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("参与部门范围")])]
          ),
          _c("depart-refs", {
            model: {
              value: _vm.deptCodes,
              callback: function ($$v) {
                _vm.deptCodes = $$v
              },
              expression: "deptCodes",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("参与群组范围")])]
          ),
          _c("group-select", {
            model: {
              value: _vm.groupIds,
              callback: function ($$v) {
                _vm.groupIds = $$v
              },
              expression: "groupIds",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("参与人员范围")])]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleAdd } },
            [_vm._v("选择人员")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.handleClear },
            },
            [_vm._v("清除所有")]
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            _vm._l(_vm.userList, function (user, index) {
              return _c(
                "el-tag",
                {
                  key: user.id,
                  staticStyle: {
                    "margin-right": "8px",
                    "margin-bottom": "8px",
                  },
                  attrs: { size: "small", closable: "" },
                  on: {
                    close: function ($event) {
                      return _vm.handleRemove(index)
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(user.realName || user.userId_dictText) + " "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("user-select-dialog", {
        attrs: {
          "dialog-show": _vm.dialogShow,
          "exclude-departs": _vm.deptCodes,
          "exclude-groups": _vm.groupIds,
          excludes: _vm.excludes,
        },
        on: {
          "update:dialogShow": function ($event) {
            _vm.dialogShow = $event
          },
          "update:dialog-show": function ($event) {
            _vm.dialogShow = $event
          },
          select: _vm.handleSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }