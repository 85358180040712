import { post } from '@/utils/request'

/**
 * 保存截图
 * @param data
 * @returns {Promise}
 */
export function saveCapture(data) {
  return post('/api/course/face/save', data)
}

/**
 * 校验人脸
 * @param data
 * @returns {Promise}
 */
export function validateFace(data) {
  return post('/api/course/face/validate-face', data)
}

/**
 * 刷脸记录
 * @param data
 * @returns {Promise}
 */
export function facePaging(data) {
  return post('/api/course/face/face-paging', data)
}

/**
 * 抓拍记录
 * @param data
 * @returns {Promise}
 */
export function capturePaging(data) {
  return post('/api/course/face/capture-paging', data)
}
