<template>

  <el-container class="container-bg">

    <el-header style="padding: 0; height: 60px">
      <web-header />
    </el-header>

    <el-main style="padding: 0;">
      <div class="container main-inner">
        <div style="width: 160px;">
          <user-banner />
          <el-menu
            :router="true"
            :default-active="defaultActive"
            :default-openeds="['uc']"
            text-color="#333"
            unique-opened
            active-text-color="#fff"
          >
            <el-submenu index="uc">
              <template slot="title">
                <i class="el-icon-user"/>
                <span>个人中心</span>
              </template>
              <el-menu-item index="/pages/uc">个人信息</el-menu-item>
              <el-menu-item index="/pages/uc/info/update">资料修改</el-menu-item>
              <el-menu-item index="/pages/uc/info/pass">密码安全</el-menu-item>
              <el-menu-item index="/pages/uc/info/bind">账号绑定</el-menu-item>
              <el-menu-item index="/pages/uc/info/real">实名认证</el-menu-item>
              <el-menu-item index="/pages/uc/im">站内消息</el-menu-item>
              <el-menu-item index="/pages/uc/points">积分日志</el-menu-item>
            </el-submenu>

            <el-submenu v-if="isLecturer" index="lecturer">
              <template slot="title">
                <i class="el-icon-medal"/>
                <span>讲师中心</span>
              </template>
              <el-menu-item index="/pages/lecturer/account">账户总览</el-menu-item>
              <el-menu-item index="/pages/lecturer/course">课程发布</el-menu-item>
              <el-menu-item index="/pages/lecturer/media">媒体中心</el-menu-item>
              <el-menu-item index="/pages/lecturer/settle/detail">收益明细</el-menu-item>
              <el-menu-item index="/pages/lecturer/settle/order">收益结算</el-menu-item>
              <el-menu-item index="/pages/lecturer/cash">提现管理</el-menu-item>
            </el-submenu>

            <el-submenu index="course">
              <template slot="title">
                <i class="el-icon-reading"/>
                <span>课程学习</span>
              </template>
              <el-menu-item index="/pages/uc/course/progress">学习记录</el-menu-item>
              <el-menu-item index="/pages/uc/course/buy">已购课程</el-menu-item>
              <el-menu-item index="/pages/uc/course/fav">收藏课程</el-menu-item>
              <el-menu-item index="/pages/uc/course/qa">课程问答</el-menu-item>
              <el-menu-item index="/pages/uc/course/cert">我的证书</el-menu-item>
            </el-submenu>
            <el-submenu index="mall">
              <template slot="title">
                <i class="el-icon-goods"/>
                <span>积分商城</span>
              </template>
              <el-menu-item index="/pages/uc/mall/order">我的订单</el-menu-item>
              <el-menu-item index="/pages/uc/mall/addr">收货地址</el-menu-item>
            </el-submenu>


          </el-menu>
        </div>

        <div style="flex-grow: 1; margin-left: 20px; background: #fff; padding: 20px; border:#dedede 1px solid">
          <app-main />
        </div>


      </div>
    </el-main>
    <el-footer v-if="siteData.copyRight" class="footer-copy">
      <div v-html="siteData.copyRight" />
    </el-footer>
  </el-container>

</template>

<script>
import { mapGetters } from 'vuex'
import { AppMain, WebHeader } from './components'
import UserBanner from '@/views/pages/uc/components/UserBanner/index.vue'

export default {
  name: 'UcLayout',
  components: {
    UserBanner,
    AppMain,
    WebHeader
  },

  data() {
    return {
      defaultActive: '',
      isLecturer: false
    }
  },
  computed: {
    ...mapGetters([
      'siteData',
      'roles'
    ])
  },
  created() {
    this.defaultActive = this.$route.path
    // 是否讲师角色
    this.isLecturer = this.roles.some(role => role === 'lecturer')
  }
}
</script>

<style scoped>

::v-deep .el-menu {
  border-right: none;
}


.el-menu-item.is-active {
  background: #363636;
}

::v-deep .app-container {
  padding: 0;
}

/*页面背景*/
.container-bg {
  background: #F5F7F9
}

.main-inner {
  margin-top: 20px;
  display: flex;
}

.footer-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px !important;
  color: #A3A8B0;
  font-size: 16px;
  background: #333;
  margin-top: 20px;
}

::v-deep .el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  min-width: 160px;
  cursor: pointer;
}


::v-deep .el-tabs__item.is-active {
  color: #C6302B
}

::v-deep .el-tabs__active-bar {
  background-color: #C6302B
}

</style>

