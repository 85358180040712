<template>

  <div>

    <div style="margin-bottom: 10px; display: flex; align-items: center">
      <el-input
        v-model="filterText"
        style="flex-grow: 1"
        size="small"
        placeholder="输入关键字进行过滤"/>
      <el-button
        type="danger"
        style="margin-left: 10px"
        icon="el-icon-refresh-left"
        size="mini"
        circle
        @click="clearSelection"/>
    </div>

    <el-tree
      ref="tree"
      :data="treeData"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      empty-text="暂无目录"
      check-on-click-node
      node-key="id"
      default-expand-all
      highlight-current
      @node-click="checkChange">
      <div slot-scope="{ node, data }">
        {{ data.title }}
      </div>
    </el-tree>

  </div>

</template>

<script>
import { dirTree } from '@/api/media/catalog'

export default {
  name: 'MediaCatalogTree',
  components: { },
  props: {
    spaceId: { type: String, default: '' }
  },
  data() {
    return {
      loading: false,
      treeData: [],
      filterText: ''
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },

  created() {
    this.showDirTree()
  },

  methods: {

    // 加载树结构
    showDirTree() {
      dirTree({ id: this.spaceId }).then(res => {
        this.treeData = res.data
      })
    },

    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },

    // 清理
    clearSelection() {
      this.filterText = ''
      // 移除高亮效果
      const current = document.querySelector('.is-current')
      if (current) {
        current.className = 'el-tree-node is-expanded is-focusable'
      }
      this.$emit('clear')
    },

    checkChange(data) {
      this.$emit('selected', data.id)
    }
  }
}
</script>

<style scoped>

</style>
