var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.costPoints === 0 && _vm.salePrice > 0
      ? _c("span", [
          _c("span", { staticStyle: { "font-size": "12px" } }, [_vm._v("￥")]),
          _vm._v(_vm._s(_vm.salePrice) + " "),
        ])
      : _vm._e(),
    _vm.costPoints > 0 && _vm.salePrice === 0
      ? _c("span", [_vm._v(" " + _vm._s(_vm.costPoints) + "积分 ")])
      : _vm._e(),
    _vm.costPoints > 0 && _vm.salePrice > 0
      ? _c("span", [
          _c("span", { staticStyle: { "font-size": "12px" } }, [_vm._v("￥")]),
          _vm._v(
            " " + _vm._s(_vm.salePrice) + "+" + _vm._s(_vm.costPoints) + "积分 "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }