<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="审核消息"
    width="30%"
    @close="handleClose"
  >
    <el-descriptions :column="1" border>
      <el-descriptions-item label="审核人员">
        {{ detail.auditBy_dictText }}
      </el-descriptions-item>
      <el-descriptions-item label="审核结果">
        <span :class="`audit-state-${detail.auditState}`">
          {{ detail.auditState_dictText }}
        </span>
      </el-descriptions-item>
      <el-descriptions-item label="审核时间">
        {{ detail.auditTime }}
      </el-descriptions-item>
      <el-descriptions-item label="审核信息">
        {{ detail.auditMsg }}
      </el-descriptions-item>
    </el-descriptions>

  </el-dialog>

</template>

<script>
import { auditMsg } from '@/api/lecturer/course'

export default {
  name: 'CourseAuditMsgDialog',
  components: { },
  props: {
    id: String,
    visible: Boolean
  },
  data() {
    return {
      dialogVisible: false,
      detail: {}
    }
  },

  watch: {
    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    id: {
      handler(val) {
        this.showDetail(val)
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.showDetail()
  },

  methods: {


    // 查找详情
    showDetail() {
      if (!this.id) {
        return
      }
      auditMsg(this.id).then(res => {
        this.detail = res.data
      })
    },

    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>
.audit-state-2{
  color: #1aac1a;
}
.audit-state-3{
  color: #ff3333;
}
</style>
