var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-bt" }, [
    _c("div", [
      _c("div", { staticClass: "font-title" }, [
        _vm._v(_vm._s(_vm.data.refRemark)),
      ]),
      _c("div", { staticClass: "font-small" }, [
        _vm._v(_vm._s(_vm.data.createTime)),
      ]),
    ]),
    _c("div", { staticStyle: { "font-size": "16px" } }, [
      _vm.data.points > 0
        ? _c("span", { staticClass: "points points-in" }, [
            _vm._v(" + " + _vm._s(_vm.data.points) + " "),
          ])
        : _c("span", { staticClass: "points points-out" }, [
            _vm._v(" " + _vm._s(_vm.data.points) + " "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }