var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            background: "#f5f5f5",
            padding: "10px",
            "border-radius": "4px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "5px" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.strictly,
                    callback: function ($$v) {
                      _vm.strictly = $$v
                    },
                    expression: "strictly",
                  },
                },
                [_vm._v("级联选择下级")]
              ),
            ],
            1
          ),
          _c("el-input", {
            attrs: { placeholder: "输入关键字进行过滤", size: "small" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
        ],
        1
      ),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.treeLoading,
            expression: "treeLoading",
          },
        ],
        ref: "tree",
        staticStyle: { "margin-top": "10px" },
        attrs: {
          data: _vm.treeData,
          "check-strictly": !_vm.strictly,
          "default-checked-keys": _vm.deptCodes,
          props: _vm.defaultProps,
          "filter-node-method": _vm.filterNode,
          "empty-text": " ",
          "default-expand-all": "",
          "show-checkbox": "",
          "node-key": "deptCode",
        },
        on: { "check-change": _vm.handleCheckChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }