<template>

  <data-grid
    :options="options"
    :list-query="listQuery"
    empty-text="还没有获得证书哦，快去学习吧~"
  >

    <template v-slot:data-item="{ data }">

      <div class="cert-box">
        <div class="font-title" style="font-size: 20px; padding-bottom: 10px">{{ data.title }}</div>
        <div>证书编号：<span style="color: #9E2622; font-weight: 700">{{ data.id }}</span></div>
        <div>获得时间：<span class="font-small" style="font-size: 14px">{{ data.createTime }}</span></div>
        <div>到期时间：<span class="font-small" style="font-size: 14px">{{ data.expireTime }}</span></div>
        <div>获得方式：<span class="font-small" style="font-size: 14px">{{ data.grantType_dictText }}</span></div>
        <div>颁发机构：<span class="font-small" style="font-size: 14px">{{ data.authority }}</span></div>
        <div style="text-align: right; margin-top: -30px">
          <el-link
            :disabled="!data.certFilePdf"
            type="danger"
            size="mini"
            icon="el-icon-download"
            @click="download(data.certFilePdf)">下载</el-link>
        </div>

      </div>

    </template>

  </data-grid>

</template>

<script>
import { myGrant } from '@/api/cert/grant'
import DataGrid from '@/components/DataGrid/index.vue'

export default {
  name: 'MyCerts',
  components: { DataGrid },
  data() {
    return {
      dataList: [],

      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/cert/grant/my-paging',
        lineCount: 2
      }
    }
  },

  created() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      myGrant().then(res => {
        this.dataList = res.data
      })
    },

    download(url) {
      window.open(url)
    }

  }
}
</script>

<style scoped>
.cert-box{
  background: url('../../../../assets/web/cert-bg.png') no-repeat top;
  background-size: 100% 100%;
  border: #e3a949 1px solid;
  padding: 10px;
  border-radius: 5px;
  margin: -10px;
}

::v-deep .grid-box-inner{
  border: none;
}

</style>

