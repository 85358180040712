<template>

  <div>
    <data-grid
      ref="grid"
      :options="options"
      :list-query="listQuery"
      @select-changed="handleSelect"
    >
      <template #filter-content>

        <div class="flex-bt" style="width: 100%">
          <div>
            <el-button
              :disabled="selections.length === 0 || readState===1"
              type="primary"
              size="small"
              icon="el-icon-check"
              @click="handleMark">
              标记已读
            </el-button>

            <el-button
              :disabled="selections.length === 0"
              type="danger"
              size="small"
              icon="el-icon-delete"
              @click="handelDelete">
              删除
            </el-button>
          </div>

          <div>
            <el-radio-group v-model="readState" size="small" plain>
              <el-radio-button :label="0">未读</el-radio-button>
              <el-radio-button :label="1">已读</el-radio-button>
            </el-radio-group>
          </div>
        </div>


      </template>

      <template v-slot:data-item="{ data }">

        <div class="flex-bt">
          <div>
            <div class="font-title">{{ data.title }}</div>
            <div class="font-second">{{ data.content }}</div>
          </div>
          <div>
            <div class="font-small">{{ data.createTime }}</div>
          </div>
        </div>
      </template>
    </data-grid>

    <el-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      title="消息详情"
      width="60%">
      <h3>{{ detail.title }}</h3>
      <small>发送时间：{{ detail.createTime }}</small>
      <el-divider />
      <p class="notice" v-html="$options.filters.formatRichText(detail.content)" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import { fetchDetail, markAsRead, userDelete } from '@/api/sys/user/im'
import store from '@/store'
import DataGrid from '@/components/DataGrid/index.vue'

export default {
  components: { DataGrid },
  data() {
    return {

      readState: 0,

      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },

      options: {
        multi: true,
        // 列表请求URL
        listUrl: '/api/sys/user/msg/paging'
      },

      dialogVisible: false,
      loading: false,
      detail: {

      },
      selections: []
    }
  },
  watch: {
    readState: {
      handler(val) {
        this.listQuery.params.readState = val
        this.$refs.grid.getList()
      }
    }
  },
  methods: {

    // 消息详情
    showDetail(id) {
      this.dialogVisible = true
      fetchDetail(id).then(res => {
        this.detail = res.data
        this.refresh()
      })
    },

    // 删除站内信
    handelDelete() {
      this.$confirm('确认要删除选定的信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        userDelete({ ids: this.selections }).then(() => {
          this.$message.success('删除成功！')
          this.refresh()
        })
      })
    },

    // 标记为已读
    handleMark() {
      markAsRead({ ids: this.selections }).then(() => {
        this.$message.success('操作成功！')
        this.refresh()
      })
    },

    /**
     * 选定操作
     * @param ids
     */
    handleSelect(ids) {
      this.selections = ids
    },

    // 刷新计数
    refresh() {
      // 立马刷新
      this.$refs.grid.getList()
      // 刷新全局统计
      store.dispatch('user/fetchMsg')
    }
  }
}
</script>

<style scoped>

::v-deep a {
  color: #1890ff !important;
  font-weight: 700;
}

</style>
