var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.conf.provider === "local"
        ? _c("file-upload-local", {
            attrs: {
              accept: _vm.acceptType,
              tips: _vm.tips,
              limit: _vm.limit,
              compress: _vm.compress,
              "list-type": _vm.listType,
            },
            on: { select: _vm.fileSelected },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          })
        : _vm._e(),
      _vm.conf.provider === "aliyun"
        ? _c("file-upload-oss", {
            attrs: {
              accept: _vm.acceptType,
              tips: _vm.tips,
              limit: _vm.limit,
              compress: _vm.compress,
              "list-type": _vm.listType,
            },
            on: { select: _vm.fileSelected },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          })
        : _vm._e(),
      _vm.conf.provider === "qiniu"
        ? _c("file-upload-qiniu", {
            attrs: {
              accept: _vm.acceptType,
              tips: _vm.tips,
              limit: _vm.limit,
              compress: _vm.compress,
              "list-type": _vm.listType,
            },
            on: { select: _vm.fileSelected },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          })
        : _vm._e(),
      _vm.conf.provider === "qcloud"
        ? _c("file-upload-cos", {
            attrs: {
              accept: _vm.acceptType,
              tips: _vm.tips,
              limit: _vm.limit,
              compress: _vm.compress,
              "list-type": _vm.listType,
            },
            on: { select: _vm.fileSelected },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }