import { post } from '@/utils/request'

/**
 * Paypal支付，返回URL
 * @param id
 * @returns {*}
 */
export function paypalTrade(data) {
  return post('/api/pay/paypal/trade', data)
}
