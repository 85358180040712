var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "flex-bt white-box",
          staticStyle: { "align-items": "flex-start" },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "40%" } },
            [_c("carousel-show", { attrs: { images: _vm.detail.images } })],
            1
          ),
          _c("div", { staticClass: "goods-box" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.detail.title)),
            ]),
            _c("div", { staticClass: "summary" }, [
              _vm._v(_vm._s(_vm.detail.summary)),
            ]),
            _c("div", { staticClass: "sku" }, [_vm._v(_vm._s(_vm.sku.title))]),
            _c(
              "div",
              { staticClass: "price" },
              [
                _c("price-show", {
                  attrs: {
                    "cost-points": _vm.sku.costPoints,
                    "sale-price": _vm.sku.salePrice,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "sku-group" }, [
              _vm.detail.multiSku
                ? _c(
                    "div",
                    _vm._l(_vm.detail.params, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "sku-group-item" },
                        [
                          _c("div", { staticClass: "sku-group-name" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c(
                            "ul",
                            { staticClass: "sku-group-property" },
                            _vm._l(item.values, function (vv) {
                              return _c(
                                "li",
                                {
                                  key: vv.id,
                                  staticClass: "sku-group-key",
                                  class: {
                                    active: _vm.isActive(item.id, vv.id),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.skuSelect(item.id, vv.id)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(vv.value) + " ")]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("div", { staticStyle: { "font-weight": "700" } }, [
                _vm._v("兑换数量"),
              ]),
              _c(
                "div",
                { staticClass: "num-box" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      min: 1,
                      value: 1,
                      max: _vm.sku.leftStock,
                      disabled: "",
                    },
                  }),
                  _c("div", { staticClass: "stock" }, [
                    _vm._v("库存：" + _vm._s(_vm.sku.leftStock)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        disabled: !_vm.sku.id || _vm.sku.leftStock <= 0,
                        type: "primary",
                        size: "big",
                      },
                      on: { click: _vm.checkout },
                    },
                    [_vm._v("立即购买")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("el-divider"),
      _c("div", { staticClass: "goods-detail white-box" }, [
        _c("div", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$options.filters.formatRichText(_vm.detail.content)
            ),
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }