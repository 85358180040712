var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      ref: "popover",
      staticStyle: { "margin-bottom": "-3px" },
      attrs: { placement: "bottom-start", trigger: "click" },
      on: { show: _vm.onShowPopover, hide: _vm.onHidePopover },
    },
    [
      _c("el-tree", {
        ref: "tree",
        staticClass: "select-tree",
        style: "min-width: " + _vm.treeWidth,
        attrs: {
          "check-strictly": false,
          data: _vm.data,
          props: _vm.props,
          "expand-on-click-node": false,
          "filter-node-method": _vm.filterNode,
          placeholder: "选择部门",
          "highlight-current": "",
          "default-expand-all": "",
        },
        on: { "node-click": _vm.onClickNode },
      }),
      _c("el-input", {
        ref: "input",
        class: { rotate: _vm.showStatus },
        style: "width: " + _vm.width + "px;",
        attrs: {
          slot: "reference",
          "prefix-icon": _vm.icon,
          placeholder: _vm.placeholder,
          size: "small",
          clearable: "",
          "suffix-icon": "el-icon-arrow-down",
        },
        slot: "reference",
        model: {
          value: _vm.labelModel,
          callback: function ($$v) {
            _vm.labelModel = $$v
          },
          expression: "labelModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }