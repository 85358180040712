<template>


  <div>
    <el-tabs v-model="listQuery.params.paid">
      <el-tab-pane label="已支付" name="true"/>
      <el-tab-pane label="待支付" name="false"/>
    </el-tabs>


    <data-grid
      ref="dataGrid"
      :options="options"
      :list-query="listQuery"
      empty-text="还没有购买记录，赶紧去课程库看看吧~"
    >

      <template v-slot:data-item="{ data }">
        <div>

          <div class="flex-bt">
            <div>
              <a class="t-link" type="danger" @click="toLearn(data.courseId)">{{ data.title }}</a>
            </div>
            <div>
              <span class="price-show">￥{{ data.amount }}</span>
            </div>
          </div>

          <div class="flex-bt">
            <div>
              <div v-if="data.paid" class="font-small">有效期至：{{ data.expireTime }}</div>
              <div v-else class="font-small">下单时间：{{ data.createTime }}</div>
            </div>
            <div class="flex">
              <div style="font-size: 12px; font-weight: 700; padding-right: 10px">
                <span v-if="data.paid" class="state-0">已支付</span>
                <span v-else class="state-1">待支付</span>
              </div>
              <div class="opt-box">
                <el-link v-if="!data.paid" type="warning" icon="el-icon-bank-card" @click="handlePay(data.orderId)">继续支付</el-link>
                <el-link v-if="data.paid" type="primary" icon="el-icon-d-arrow-right" round @click="toLearn(data.courseId)">去学习</el-link>
                <el-link v-if="data.paid && !data.rated" type="danger" icon="el-icon-chat-round" round @click="showScore(data.courseId)">评价</el-link>
                <el-link v-if="data.paid && data.rated" type="primary" icon="el-icon-chat-dot-round" round @click="showScore(data.courseId)">已评价</el-link>
              </div>


            </div>
          </div>
        </div>
      </template>

    </data-grid>

    <!-- 支付弹窗 -->
    <pay-dialog
      :visible.sync="dialogVisible"
      :order-id="payOrder"
      @close="dialogClose"
      @success="payBack"
    />

    <score-dialog
      :visible.sync="scoreVisible"
      :course-id="courseId"
      @success="refresh"
    />

</div></template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'
import PayDialog from '@/components/PayDialog/index.vue'
import DicListSelect from '@/components/DicListSelect/index.vue'
import ScoreDialog from '@/views/pages/uc/course/components/ScoreDialog/index.vue'

export default {
  components: { ScoreDialog, DicListSelect, PayDialog, DataGrid },
  data() {
    return {
      dialogVisible: false,
      payOrder: '',
      listQuery: {
        current: 1,
        size: 10,
        params: {
          paid: 'true'
        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/course/buy/paging'
      },

      scoreVisible: false,
      courseId: ''
    }
  },
  methods: {
    // 继续支付
    handlePay(orderId) {
      this.payOrder = orderId
      this.dialogVisible = true
    },
    dialogClose() {
      this.$emit('update:visible', false)
    },
    toLearn(courseId) {
      this.$router.push({ name: 'CourseView', params: { courseId: courseId }})
    },
    // 评价
    showScore(courseId) {
      this.courseId = courseId
      this.scoreVisible = true
    },

    // 支付回调
    payBack() {
      this.listQuery.params.paid = 'true'
      this.refresh()
    },
    refresh() {
      this.$refs.dataGrid.getList()
    }
  }
}
</script>

<style scoped>
.opt-box > .el-link{
  margin-left: 10px;
}
</style>
