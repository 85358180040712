import { post } from '@/utils/request'

export function save(data) {
  return post('/api/media/file/save', data)
}

export function detail(data) {
  return post('/api/media/file/detail', data)
}

export function batchUpdate(data) {
  return post('/api/media/file/batch-update', data)
}

/**
 * 重新转码
 * @param ids
 * @returns {Promise}
 */
export function transcode(ids) {
  return post('/api/media/file/transcode', { ids: ids })
}

/**
 * 重新获取转码状态
 * @param ids
 * @returns {Promise}
 */
export function fetchState(ids) {
  return post('/api/media/file/fetch-state', { ids: ids })
}

/**
 * 重新读取文件信息
 * @param ids
 * @returns {Promise}
 */
export function fetchInfo(ids) {
  return post('/api/media/file/fetch-info', { ids: ids })
}

