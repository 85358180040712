var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bi-box" },
    [
      _c("yf-avatar", {
        attrs: { src: _vm.userData.avatar, size: 64, border: "#ccc 1px solid" },
      }),
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.userData.realName) + " "),
        ]),
        _c("div", { staticStyle: { "padding-left": "10px" } }, [
          _c("i", { staticClass: "el-icon-s-custom" }),
          _vm._v(" 部门：" + _vm._s(_vm.userData.deptCode_dictText)),
        ]),
        _c("div", { staticStyle: { "padding-left": "10px" } }, [
          _c("i", { staticClass: "el-icon-s-finance" }),
          _vm._v(" 积分：" + _vm._s(_vm.userData.points) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }