var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, title: "发货查询", width: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      !_vm.delivery || !_vm.delivery.id
        ? _c("el-empty", { attrs: { description: "暂无发货信息！" } })
        : _vm._e(),
      _c(
        "el-descriptions",
        { attrs: { column: 1, border: "" } },
        [
          _vm.delivery.remark
            ? _c("el-descriptions-item", { attrs: { label: "发货信息" } }, [
                _vm._v(_vm._s(_vm.delivery.remark)),
              ])
            : _vm._e(),
          _c("el-descriptions-item", { attrs: { label: "发货时间" } }, [
            _vm._v(_vm._s(_vm.delivery.createTime)),
          ]),
          _vm.delivery.expressName
            ? _c("el-descriptions-item", { attrs: { label: "快递公司" } }, [
                _vm._v(_vm._s(_vm.delivery.expressName)),
              ])
            : _vm._e(),
          _vm.delivery.expressNo
            ? _c("el-descriptions-item", { attrs: { label: "快递单号" } }, [
                _vm._v(_vm._s(_vm.delivery.expressNo)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.delivery.sendType === 1
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { data: _vm.listData, stripe: "", border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "time", label: "日期", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "context", label: "动态" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }