var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { height: "360px" } }, [
    _c(
      "div",
      {
        staticStyle: {
          color: "#000",
          "font-weight": "700",
          "font-size": "16px",
          "margin-bottom": "10px",
        },
      },
      [_vm._v("学习总览")]
    ),
    _c("div", {
      class: _vm.className,
      style: { height: _vm.height, width: _vm.width },
      attrs: { id: _vm.id },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }