var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("tracking-cam", {
        ref: "cam",
        attrs: {
          tracking: true,
          "track-capture": false,
          "show-track-tips": false,
        },
        on: {
          error: _vm.handleCamError,
          "face-out": _vm.faceOut,
          started: _vm.startTimer,
          capture: _vm.handleCapture,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }