<template>
  <div>

    <el-row id="dash-opt-box" :gutter="20" class="guide">

      <el-col :span="24">
        <h3>快捷入口</h3>
      </el-col>

      <el-col v-permission="['activity:list']" v-if="siteData.props.moduleActivity" :span="span1" class="dash-item module-activity">
        <el-card>
          <div class="opt-item" @click="navToActivity">
            <div class="opt-img"><img src="@/assets/dash/activity.png"> </div>
            <div class="opt-content">
              <div>活动报名</div>
              <div>考试报名、学习报名、信息收集</div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col v-permission="['exam:add']" :span="span1" class="dash-item module-exam">
        <el-card>
          <div class="opt-item" @click="navToAddExam">
            <div class="opt-img"><img src="@/assets/dash/a1.png"> </div>
            <div class="opt-content">
              <div>创建考试</div>
              <div>快速创建一场考试，支持多种选题方式</div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col v-permission="['course:add']" v-if="siteData.props.moduleCourse" :span="span1" class="dash-item module-course">
        <el-card>
          <div class="opt-item" @click="navToAddCourse">
            <div class="opt-img"><img src="@/assets/dash/a2.png"> </div>
            <div class="opt-content">
              <div>添加课程</div>
              <div>添加课程课件，支持Office/PDF/视频</div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col v-permission="['repo:list']" :span="span1" class="dash-item module-exam">
        <el-card>
          <div class="opt-item" @click="navToRepoList">
            <div class="opt-img"><img src="@/assets/dash/a3.png"> </div>
            <div class="opt-content">
              <div>题库管理</div>
              <div>批量导入导出、设定章节、题目难易程度</div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col v-permission="['cert:list']" v-if="siteData.props.moduleCert" :span="span1" class="dash-item module-cert">
        <el-card>
          <div class="opt-item" @click="navToCertList">
            <div class="opt-img"><img src="@/assets/dash/a4.png"> </div>
            <div class="opt-content">
              <div>证书管理</div>
              <div>定制证书，支持规则自动发放、人工颁发</div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col v-permission="['battle:list']" v-if="siteData.props.moduleBattle" :span="span1" class="dash-item module-battle">
        <el-card>
          <div class="opt-item" @click="navToBattle">
            <div class="opt-img"><img src="@/assets/dash/battle.png"> </div>
            <div class="opt-content">
              <div>知识竞赛</div>
              <div>开启知识竞赛，勇攀知识高峰</div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col v-permission="['sys:notice:list']" v-if="showNotice" :span="span1" class="dash-item">
        <el-card>
          <div class="opt-item" @click="navToNotice">
            <div class="opt-img"><img src="@/assets/dash/a6.png"> </div>
            <div class="opt-content">
              <div>公告管理</div>
              <div>发布日常公告、新闻及提醒事项</div>
            </div>
          </div>
        </el-card>
      </el-col>

    </el-row>

  </div>

</template>

<script>
import permission from '@/directive/permission'
import { mapGetters } from 'vuex'

export default {
  name: 'CardQuickOpt',
  directives: { permission },
  data() {
    return {
      showNotice: false,
      span1: 8
    }
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  },
  created() {

  },

  mounted() {
    const els = document.querySelectorAll('#dash-opt-box .dash-item')
    let count = els.length

    // 凑数显示出来
    if (count % 2 !== 0 || count < 3) {
      this.showNotice = true
      count += 1
    }

    // 默认一排3个
    let span = 8

    // 数量小的
    if (count < 3) {
      span = 24 / count
    } else if (count % 3 === 0) {
      span = 8
    } else if (count % 4 === 0) {
      span = 6
    } else if (count % 2 === 0) {
      span = 12
    }

    if (count <= 4) {
      this.$emit('one')
    }

    this.span1 = span
  },
  methods: {

    navToAddExam() {
      this.$router.push({ name: 'ExamAdd', params: { tmplId: '0' }})
    },
    navToAddCourse() {
      this.$router.push({ name: 'CourseAdd' })
    },
    navToRepoList() {
      this.$router.push({ name: 'RepoList' })
    },
    navToCertList() {
      this.$router.push({ name: 'CertList' })
    },
    navToNotice() {
      this.$router.push({ name: 'SysNotice' })
    },
    navToBattle() {
      this.$router.push({ name: 'BattleList' })
    },
    navToActivity() {
      this.$router.push({ name: 'ActivityList' })
    }
  }
}
</script>

<style scoped>
.opt-item {
  display: flex;
  width: 100%;
  flex-direction: row;  /* 子元素横向排列 */
  justify-content: center; /* 相对父元素水平居中 */
  align-items: center; /*  子元素相对父元素垂直居中 */
}

.opt-img {
  width: 50px;
  height: 50px;
}

.opt-img img{
  width: 100%;
}

.opt-content{
  width: 200px;
  flex-grow: 1;
  padding-left: 10px;
  line-height: 30px;
}

.opt-content :first-child{
  font-size: 16px; font-weight: 700
}

.opt-content :last-child{
  font-size: 12px; color: #666; line-height: 20px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.no-data{

  display: flex; align-items: center; height: 200px;justify-content: center; color: #666
}

::v-deep
.el-card {
  margin-bottom: 20px;
}

::v-deep
.el-card__body{
  padding: 10px;
}

.guide .el-card {
  cursor: pointer
}

.chart-title{
  font-weight: 700;
  font-size: 16px;
}

</style>
