import { post } from '@/utils/request'

/**
 * 支付宝支付，返回二维码
 * @param id
 * @returns {*}
 */
export function alipayTrade(data) {
  return post('/api/pay/alipay/trade', data)
}
