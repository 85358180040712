<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="70%"
    @close="handleClose"
  >


    <el-tabs v-model="listQuery.params.fileType">
      <el-tab-pane label="全部" name="all" />
      <el-tab-pane label="视频" name="video" />
      <el-tab-pane label="文档" name="office" />
      <el-tab-pane label="PDF" name="pdf" />
    </el-tabs>

    <div style="display: flex;">
      <div style="width: 300px">
        <el-card>
          <media-catalog-tree @selected="dirSelected" @clear="dirClear"/>
        </el-card>
      </div>
      <div style="flex-grow: 1; padding-left: 20px; overflow-x: auto">


        <data-table
          ref="pagingTable"
          :options="options"
          :list-query="listQuery"
          @select-changed="handleSelected"
        >
          <template #filter-content>
            <el-input v-model="listQuery.params.title" style="width: 200px" placeholder="搜索课件名称" class="filter-item" size="small" />
            <dic-list-select v-model="listQuery.params.state" dic-code="course_state" title="课件状态"/>
            <el-button :disabled="selectedList.length === 0" type="primary" size="small" class="filter-item" style="float:  right" @click="handleConfirm">{{ selectedLabel }}</el-button>
          </template>

          <template #data-columns>

            <el-table-column
              label="文件信息"
            >
              <template v-slot="scope">
                <div class="font-title" >
                  <file-icon-show :value="scope.row.fileType" />
                  {{ scope.row.title }}
                  <span v-if="scope.row.fileType==='video'" class="font-small">
                    <sec-format :value="scope.row.duration"/>
                  </span>
                </div>
                <div class="font-small">{{ scope.row.sizeDesc }}</div>
                <div>{{ scope.row.fileType_dictText }}</div>
              </template>

            </el-table-column>


            <el-table-column
              label="状态"
              align="center"
              width="100px"
            >

              <template v-slot="scope">
                <el-tag v-if="scope.row.state===0" size="mini" type="success">正常</el-tag>
                <el-tag v-if="scope.row.state===1" size="mini" type="warning">转码中</el-tag>
                <el-tag v-if="scope.row.state===2" size="mini" type="danger">转码失败</el-tag>
              </template>

            </el-table-column>

          </template>

        </data-table>

      </div>

    </div>
  </el-dialog>

</template>

<script>
import MediaCatalogTree from '@/components/MediaManage/MediaCatalogTree/index.vue'
import FileIconShow from '@/components/FileIconShow/index.vue'

export default {
  name: 'MediaSelectDialog',
  components: { FileIconShow, MediaCatalogTree },
  props: {
    // 空间ID
    spaceId: String,
    // 是否只能选择正常的
    normal: Boolean,
    excludes: Array,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

      dialogVisible: false,
      selectedLabel: '请勾选课件',

      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: '0',
          fileType: 'all',
          excludes: []
        }
      },

      // 选定的数据列表
      selectedList: [],

      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        // 列表请求URL
        listUrl: '/api/media/file/paging',
        // 是否支持选定
        selectable: this.checkSelectable
      }
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
        if (this.dialogVisible && this.$refs.pagingTable !== undefined) {
          this.$refs.pagingTable.getList()
        }
      }
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
      },
      deep: true
    },

    spaceId: {
      handler() {
        this.listQuery.params.spaceId = this.spaceId
      },
      deep: true
    },

    selectedList: {
      handler(val) {
        if (val.length > 0) {
          this.selectedLabel = '确认' + val.length + '项'
        } else {
          this.selectedLabel = '请勾选文件'
        }
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.listQuery.params.excludes = this.excludes
    this.listQuery.params.apaceId = this.spaceId
  },

  methods: {

    // 选定回调
    handleSelected(obj) {
      this.selectedList = obj.objs
    },

    // 关闭回调
    handleClose() {
      this.$emit('update:visible', false)
    },

    // 确认选定
    handleConfirm() {
      this.$emit('update:visible', false)
      this.$emit('select', this.selectedList)
      this.$refs.pagingTable.clearSelection()
    },

    // 只能选中
    checkSelectable(row) {
      if (this.normal) {
        return row.state === 0
      }
      return true
    },

    dirSelected(id) {
      this.listQuery.params.catId = id
      this.$refs.pagingTable.getList()
    },

    dirClear() {
      this.listQuery.params.catId = ''
      this.$refs.pagingTable.getList()
    }

  }
}
</script>

<style scoped>
::v-deep .el-dialog{
  height: 90vh
}
</style>
