var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type === 0 ? "解绑手机" : "绑定手机",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              attrs: {
                model: _vm.postForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "mobile", label: "手机号码" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.type === 0,
                      placeholder: "手机号码",
                    },
                    model: {
                      value: _vm.postForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "mobile", $$v)
                      },
                      expression: "postForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "smsCode", label: "短信验证码" } },
                [
                  _c("sms-input", {
                    ref: "sms",
                    attrs: { type: _vm.type === 1 ? 1 : 2 },
                    model: {
                      value: _vm.postForm,
                      callback: function ($$v) {
                        _vm.postForm = $$v
                      },
                      expression: "postForm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.type === 1
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: { click: _vm.doBind },
                    },
                    [_vm._v("确认绑定")]
                  )
                : _vm._e(),
              _vm.type === 0
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading, type: "danger" },
                      on: { click: _vm.unBind },
                    },
                    [_vm._v("确认解绑")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }