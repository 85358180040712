<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="提现详情"
    width="30%"
    @close="handleClose"
  >
    <el-descriptions :column="1" class="margin-top" border>
      <el-descriptions-item label="提现时间">
        {{ detail.createTime }}
      </el-descriptions-item>
      <el-descriptions-item label="提现金额">
        {{ detail.totalAmount }}
      </el-descriptions-item>
      <el-descriptions-item label="手续费">
        {{ detail.taxAmount }} ({{ detail.taxRate }} %)
      </el-descriptions-item>
      <el-descriptions-item label="实际应付">
        {{ detail.payableAmount }}
      </el-descriptions-item>


      <el-descriptions-item label="银行名称">
        {{ detail.bankName }}
      </el-descriptions-item>

      <el-descriptions-item label="分行名称">
        {{ detail.bankBranch }}
      </el-descriptions-item>

      <el-descriptions-item label="银行卡号">
        {{ detail.bankCard }}
      </el-descriptions-item>

      <el-descriptions-item label="持卡姓名">
        {{ detail.bankHolder }}
      </el-descriptions-item>

      <el-descriptions-item label="提现状态">
        {{ detail.state_dictText }}
      </el-descriptions-item>
      <el-descriptions-item label="支付时间">
        <span v-if="detail.payTime">{{ detail.payTime }}</span>
        <span v-else>--</span>
      </el-descriptions-item>

      <el-descriptions-item v-if="detail.state===2" label="拒付理由">
        <span style="color: #ff3333">{{ detail.remark }}</span>
      </el-descriptions-item>

    </el-descriptions>


  </el-dialog>

</template>

<script>

import { fetchDetail } from '@/api/lecturer/cash'

export default {
  name: 'CashDetailDialog',
  props: {
    visible: { type: Boolean },
    id: { type: String }
  },
  data() {
    return {
      dialogVisible: false,
      detail: {}
    }
  },

  watch: {

    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },

    id: {
      handler(val) {
        this.fetchData(val)
      }
    }
  },

  methods: {

    fetchData(id) {
      fetchDetail(id).then(res => {
        this.detail = res.data
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
