import { post } from '@/utils/request'

/**
 * 发送短信
 * @param data
 * @returns {Promise}
 */
export function send(data) {
  return post('/api/sys/user/sms', data)
}
