var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c("el-input", {
            staticStyle: { "flex-grow": "1" },
            attrs: { size: "small", placeholder: "输入关键字进行过滤" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c("el-button", {
            staticStyle: { "margin-left": "10px" },
            attrs: {
              type: "danger",
              icon: "el-icon-refresh-left",
              size: "mini",
              circle: "",
            },
            on: { click: _vm.clearSelection },
          }),
        ],
        1
      ),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.treeData,
          "close-on-click-modal": false,
          props: _vm.props,
          "filter-node-method": _vm.filterNode,
          "default-expand-all": "",
          "node-key": "id",
          "highlight-current": "",
        },
        on: { "node-click": _vm.onClickNode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }