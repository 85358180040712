var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "方案类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "provider", $$v)
                    },
                    expression: "postForm.provider",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "aliyun" } }, [
                    _vm._v("阿里云"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.provider === "aliyun"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "推流域名" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.postForm.data.pushDomain,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "pushDomain", $$v)
                          },
                          expression: "postForm.data.pushDomain",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推流秘钥" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.postForm.data.pushKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "pushKey", $$v)
                          },
                          expression: "postForm.data.pushKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "拉流域名" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.postForm.data.pullDomain,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "pullDomain", $$v)
                          },
                          expression: "postForm.data.pullDomain",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "拉流秘钥" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "" },
                        model: {
                          value: _vm.postForm.data.pullKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "pullKey", $$v)
                          },
                          expression: "postForm.data.pullKey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存配置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }