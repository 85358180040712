var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "flex-grow": "1" } }, [
    _c("div", { staticClass: "nv-title" }, [_vm._v("精品课程")]),
    _c(
      "div",
      { staticClass: "grid-box grid-box-4" },
      _vm._l(_vm.listData, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "grid-box-inner" },
          [_c("course-card", { attrs: { data: item } })],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }