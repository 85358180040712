<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="true"
    title="订单详情"
    width="60%"
    @close="handleClose"
  >

    <div class="all-box">
      <div class="left-box">

        <el-card v-if="detail.delivery && detail.delivery.id" style="margin-bottom: 10px">
          <el-descriptions :column="1" border title="订单信息">
            <el-descriptions-item v-if="detail.delivery.sendType===1" label="物流名称">{{ detail.delivery.expressName }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.delivery.sendType===1" label="运单编号">{{ detail.delivery.expressNo }}</el-descriptions-item>
            <el-descriptions-item label="发货信息">{{ detail.delivery.remark }}</el-descriptions-item>
          </el-descriptions>
        </el-card>

        <el-card v-if="detail.contact || detail.mobile || detail.address" style="margin-bottom: 10px">
          <el-descriptions :column="1" border title="收货信息">
            <el-descriptions-item label="联系人">{{ detail.contact }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ detail.mobile }}</el-descriptions-item>
            <el-descriptions-item label="地址">{{ detail.address }}</el-descriptions-item>
          </el-descriptions>
        </el-card>

        <el-card style="margin-bottom: 10px">
          <el-descriptions :column="1" border title="订单详情">
            <el-descriptions-item label="订单编号">{{ detail.id }}</el-descriptions-item>
            <el-descriptions-item label="下单时间">{{ detail.createTime }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.remark" label="订单备注">{{ detail.remark }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.state===100" label="取消时间">{{ detail.cancelTime }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.state===100" label="取消说明">{{ detail.cancelRemark }}</el-descriptions-item>

            <el-descriptions-item v-if="detail.state>0 && detail.state<100" label="支付方式">{{ detail.payMethod_dictText }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.state>0 && detail.state<100" label="支付时间">{{ detail.payTime }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.state>0 && detail.state<100" label="支付积分">{{ detail.totalPoints }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.state>0 && detail.state<100" label="支付价格">{{ detail.totalAmount }}</el-descriptions-item>
            <el-descriptions-item v-if="detail.state>1 && detail.state<100" label="发货时间">{{ detail.sendTime }}</el-descriptions-item>

          </el-descriptions>
        </el-card>

      </div>

      <div class="right-box">

        <div class="goods-box">

          <div v-for="item in detail.goodsList" :key="item.id">

            <div class="list">
              <img :src="item.goodsImage" class="img">
              <div class="desc-box">
                <div style="display: flex">
                  <div class="title">{{ item.goodsTitle }}</div>
                  <div class="num">*{{ item.totalCount }}</div>
                </div>
                <div style="display: flex">
                  <div class="sku-t">{{ item.skuTitle }}</div>
                  <div class="sku-p">
                    <price-show :cost-points="item.totalPoints" :sale-price="item.totalAmount"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

  </el-dialog>
</template>

<script>
import {
  orderDetail as fetchDetail
} from '@/api/mall/order'

export default {
  name: 'MallOrderDetailDialog',
  components: { },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      detail: {}
    }
  },
  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      },
      deep: true
    },
    orderId: {
      handler() {
        this.loadData()
      },
      deep: true
    }

  },

  created() {
    this.dialogVisible = this.visible
    this.loadData()
  },

  methods: {

    // 订单详情
    loadData() {
      if (!this.orderId || !this.dialogVisible) {
        return
      }
      fetchDetail(this.orderId).then(res => {
        this.detail = res.data
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>

.all-box{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.left-box{
  width: 53%;
}

.right-box{
  display: flex;
  flex-direction: column;
  width: 44%;
}

.goods-box{
  display: flex;
  flex-direction: column;
  background: #f6f5f5;
  padding: 20px;
  overflow-y: auto;
  border-radius: 5px;
}

.goods-box .list{
  display: flex;
}

.goods-box .list .img{
  width: 100px;
  height: 100px;
  border: #eee 1px solid;
  background: #fff;
}

.goods-box .list .desc-box{
  margin: 0 10px;
  line-height: 30px;
  flex-grow: 1;
}

.goods-box .list .desc-box .title{
  font-weight: 700;
  flex-grow: 1
}

.goods-box .list .desc-box .num{
  width: 100px;
  text-align: right;
  font-weight: 700;
  font-size: 22px;
}

.goods-box .list .desc-box .sku-t{
  flex-grow: 1;
  color: #666;
  font-size: 16px;
  font-weight: 700
}

.goods-box .list .desc-box .sku-p{
  width: 200px;
  text-align: right;
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  color: #e67e23;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'sans-serif';
}

::v-deep .el-descriptions-row > th{
  width: 120px;
}

</style>

