var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "font-size": "10px",
      },
    },
    [
      _c("el-input-number", {
        staticStyle: { width: "50px" },
        attrs: { controls: false, min: 0, max: 9999, size: "mini" },
        model: {
          value: _vm.min,
          callback: function ($$v) {
            _vm.min = $$v
          },
          expression: "min",
        },
      }),
      _vm._v("分"),
      _c("el-input-number", {
        staticStyle: { width: "30px" },
        attrs: { controls: false, min: 0, max: 59, size: "mini" },
        model: {
          value: _vm.sec,
          callback: function ($$v) {
            _vm.sec = $$v
          },
          expression: "sec",
        },
      }),
      _vm._v("秒 "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }