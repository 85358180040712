<template>
  <div class="box-inner cs-line">

    <div @click="toDetail">
      <el-image :src="data.cover" class="cs-img" fit="cover">
        <div slot="error">
          <el-image :src="defaultCover" fit="cover" />
        </div>
      </el-image>
    </div>

    <div class="ctt-box">

      <div>
        <a class="c-link font-title" @click="toDetail">{{ data.title }}</a>
      </div>

      <div class="font-small">{{ data.createTime }}</div>
      <div class="info-grid">
        <div>
          <strong><i class="el-icon-price-tag"/></strong>￥{{ data.price }}
        </div>
        <div>
          <strong><i class="el-icon-user"/></strong>{{ data.learnerCount }}人
        </div>
        <div>
          <strong><i class="el-icon-document"/></strong>{{ data.fileCount }}
        </div>
      </div>
    </div>

    <div>

      <div class="act-box act-state">
        <span v-if="data.auditState===0">待提审</span>
        <span v-if="data.auditState===1">审核中</span>
        <span v-if="data.auditState===3" class="audit-fail c-link" @click="toAction('auditMsg')">
          <span><i class="el-icon-question" /> 退回修改</span>
        </span>
        <span v-if="data.auditState===2 && data.sellState===0">已上架</span>
        <span v-if="data.auditState===2 && data.sellState===1">待上架</span>
      </div>

      <div class="act-box">
        <el-link v-if="data.auditState===0 || data.auditState===3" icon="el-icon-edit" type="danger" size="mini" round @click="toEdit">排课</el-link>
        <el-link v-if="data.auditState===0" icon="el-icon-finished" type="success" size="mini" round @click="toAction('audit')">提审</el-link>
        <el-link v-if="data.auditState===3" icon="el-icon-finished" type="success" size="mini" round @click="toAction('audit')">再次提审</el-link>
        <el-link v-if="data.auditState===1" icon="el-icon-refresh-right" type="primary" size="mini" round @click="toAction('rollback')">撤回审核</el-link>
        <el-link v-if="data.auditState===2 && data.sellState===0" icon="el-icon-sold-out" type="warning" size="mini" round @click="toAction('offSell')">下架</el-link>
        <el-link v-if="data.auditState===2 && data.sellState===1" icon="el-icon-sell" type="primary" size="mini" round @click="toAction('onSell')">上架</el-link>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: 'CourseCardList',
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      // 默认封面
      defaultCover: require('@/assets/web/default_cs.png')
    }
  },

  created() {

  },
  methods: {
    // 课程详情
    toDetail() {
      this.$router.push({ name: 'CourseView', params: { courseId: this.data.id }})
    },

    // 去排课
    toEdit() {
      this.$router.push({ name: 'LecturerCourseEdit', query: { courseId: this.data.id }})
    },

    // 行内操作
    toAction(action) {
      this.$emit('action', { action: action, id: this.data.id })
    }
  }
}
</script>

<style scoped>
.cs-img{
  height: 80px;
  width: 120px;
  border: #d6d6d6 1px solid;
  padding: 1px;
  cursor: pointer;
}

.cs-line{
  display: flex;
  width: 100%;
  padding:0;
}

.ctt-box{
  padding-left: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  line-height: 28px;
}

.act-box{
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end
}

.act-state{
  font-size: 12px;
  color: #888;
  font-weight: 700
}

::v-deep .act-box > .el-link{
  padding-left: 10px;
}

.info-grid{
  display: flex;
  color: #888;
  font-size: 14px;
}

.info-grid i{
  color: #C6302B;
  margin-right: 5px;
}

.info-grid > div{
  width: 100px;
}

.audit-fail{
  color: #FF221C;
  font-size: 12px;
  cursor: pointer;
}
</style>
