// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/dash-ring.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.data-out[data-v-c8ce0c30]{\n  background: #0a84ff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100%;\n}\n.data-box[data-v-c8ce0c30]{\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n.data-title[data-v-c8ce0c30]{\n\n  font-size: 16px;\n  font-weight: 700;\n  color: #afd5fa;\n  text-align: center;\n}\n.data-content[data-v-c8ce0c30]{\n  font-size: 26px;\n  font-weight: 700;\n  color: #fdf7d6;\n  margin-top: 10px;\n  text-align: center;\n}\n.data-content a[data-v-c8ce0c30]{\n  color: #fdf7d6;\n  font-weight: 700;\n}\n.data-content a[data-v-c8ce0c30]:hover{\n  color: #ff8000;\n}\n", ""]);
// Exports
module.exports = exports;
