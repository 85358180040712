<template>

  <el-form ref="postForm" :model="postForm" :rules="rules" label-width="100px">
    <el-form-item label="原始密码" prop="oldPassword">
      <el-input v-model="postForm.oldPassword" show-password type="password" />
    </el-form-item>
    <el-form-item label="新的密码" prop="password">
      <el-input v-model="postForm.password" show-password type="password" />
    </el-form-item>
    <el-form-item label="确认密码" prop="confirm">
      <el-input v-model="postForm.confirm" show-password type="password" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleUpdate">确认修改</el-button>
    </el-form-item>

  </el-form>

</template>

<script>
import { updatePass } from '@/api/sys/user/user'
import { checkPass } from '@/utils/validate'
import { mapGetters } from 'vuex'

export default {
  data() {
    // 两次输入校验
    const confirmCheck = (rule, value, callback) => {
      if (value !== this.postForm.password) {
        callback(new Error('两次密码输入不一致！'))
      } else {
        callback()
      }
    }

    // 校验规则和不能与旧密码一致
    const checkAndNotSame = (rule, value, callback) => {
      if (value === this.postForm.oldPassword) {
        callback(new Error('新密码不能与旧密码一致！'))
      } else {
        checkPass(rule, value, callback)
      }
    }

    return {
      postForm: {
        oldPassword: '',
        password: '',
        confirm: ''
      },
      rules: {

        oldPassword: [
          { required: true, message: '旧密码不能为空！' }
        ],
        password: [
          { required: true, message: '新的密码不能为空！' },
          { validator: checkAndNotSame }
        ],
        confirm: [
          { required: true, message: '确认密码不能为空！' },
          { validator: confirmCheck }
        ]
      }
    }
  },

  computed: {
    ...mapGetters([
      'token'
    ])
  },

  methods: {

    async handleUpdate() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        updatePass(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '用户密码修改成功，请重新登录！',
            type: 'success',
            duration: 1500
          })

          setTimeout(() => {
            this.$store.dispatch('user/logout').then(() => {
              this.$router.push(`/login`)
            })
          }, 1500)
        })
      })
    }

  }
}
</script>

<style scoped>

.cert-box{
  line-height: 28px;
}

.cert-box .title{

  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
}

.cert-box .item{
  color: #555;
}

.cert-box .item span{
  font-weight: 700;
}

</style>

