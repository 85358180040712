var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-bg" }, [
    _c("div", { staticClass: "header-inner container" }, [
      _c("div", { staticClass: "col-logo" }, [
        _vm.siteData.frontLogo
          ? _c("div", [
              _c("img", {
                staticStyle: { height: "40px" },
                attrs: {
                  src: _vm.siteData.frontLogo,
                  alt: _vm.siteData.siteName,
                },
              }),
            ])
          : _c("div", { staticClass: "site-tt" }, [
              _vm._v(_vm._s(_vm.siteData.siteName)),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "col-menu" },
        [
          _c(
            "el-menu",
            {
              attrs: {
                router: true,
                "default-active": _vm.activeIndex,
                mode: "horizontal",
                "background-color": "#C6302B",
                "text-color": "#fff",
                "active-text-color": "#FBBE0F",
              },
            },
            [
              _c("el-menu-item", { attrs: { index: "/pages/index" } }, [
                _vm._v("首页"),
              ]),
              _vm.siteData.props.moduleCourse
                ? _c(
                    "el-menu-item",
                    {
                      staticClass: "module-course",
                      attrs: { index: "/pages/course" },
                    },
                    [_vm._v("课程库")]
                  )
                : _vm._e(),
              _vm.siteData.props.moduleMall
                ? _c(
                    "el-menu-item",
                    {
                      staticClass: "module-mall",
                      attrs: { index: "/pages/mall" },
                    },
                    [_vm._v("积分商城")]
                  )
                : _vm._e(),
              _c("el-menu-item", { attrs: { index: "/pages/notice" } }, [
                _vm._v("系统公告"),
              ]),
              _c("el-menu-item", { attrs: { index: "/pages/uc" } }, [
                _vm._v("用户中心"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "col-user" }, [
        _vm.userId
          ? _c(
              "div",
              { staticClass: "login" },
              [
                _c(
                  "el-badge",
                  {
                    attrs: {
                      value: _vm.unreadMsg,
                      max: 99,
                      hidden: _vm.unreadMsg === 0,
                    },
                  },
                  [
                    _c("yf-avatar", {
                      attrs: { src: _vm.avatar, border: "#ccc 1px solid" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.toMsg($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "uname", attrs: { title: _vm.realName } },
                  [_vm._v(_vm._s(_vm.realName))]
                ),
                _vm.roleType === 2
                  ? _c(
                      "el-link",
                      {
                        attrs: { type: "warning" },
                        on: { click: _vm.toAdmin },
                      },
                      [_vm._v("管理")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "warning", round: "" },
                    on: { click: _vm.logout },
                  },
                  [_vm._v("退出")]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "guest" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "danger", round: "" },
                    on: { click: _vm.toReg },
                  },
                  [_vm._v("注册")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "warning", round: "" },
                    on: { click: _vm.toLogin },
                  },
                  [_vm._v("登录")]
                ),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }