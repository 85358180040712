<template>

  <data-grid
    :options="options"
    :list-query="listQuery"
    empty-text="还没有记录，赶紧去学习吧！"
  >

    <template #filter-content>
      <el-input v-model="listQuery.params.title" size="small" placeholder="搜索课程名称" class="filter-item" style="width: 200px"/>
    </template>

    <template v-slot:data-item="{ data }">
      <learn-ps-card :data="data"/>
    </template>

  </data-grid>

</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'
import LearnPsCard from '@/views/pages/uc/components/LearnPsCard/index.vue'

export default {
  components: { LearnPsCard, DataGrid },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        // 列表请求URL
        listUrl: '/api/course/file/learn/progress-paging'
      }
    }
  }
}
</script>
