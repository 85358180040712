var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-connection", size: "mini", type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v(" 传附件 ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.mediaType,
                callback: function ($$v) {
                  _vm.mediaType = $$v
                },
                expression: "mediaType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("图片")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("视频")]),
              _c("el-radio", { attrs: { label: 3 } }, [_vm._v("音频")]),
              _c("el-radio", { attrs: { label: 4 } }, [_vm._v("录音")]),
              _c("el-radio", { attrs: { label: 5 } }, [_vm._v("文件")]),
            ],
            1
          ),
          _vm.dialogVisible
            ? _c(
                "div",
                { staticStyle: { "padding-top": "20px" } },
                [
                  _vm.mediaType === 4
                    ? _c("recorder", {
                        ref: "record",
                        on: { success: _vm.recordBack },
                        model: {
                          value: _vm.recordUrl,
                          callback: function ($$v) {
                            _vm.recordUrl = $$v
                          },
                          expression: "recordUrl",
                        },
                      })
                    : _c("file-upload", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          accept: _vm.accept,
                          tips: _vm.tips,
                          limit: 100,
                          "list-type": "file",
                        },
                        model: {
                          value: _vm.fileList,
                          callback: function ($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm.mediaType !== 4 && _vm.fileList.length > 0
            ? _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.handleInsert },
                    },
                    [_vm._v("插入")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }