<template>
  <div class="nt-box">
    <div class="nv-title">最新公告</div>
    <div class="nt-inner">
      <div v-for="item in listData" :key="item.id" class="nt-item">
        <a @click="toDetail(item.id)">
          <div><i class="el-icon-caret-right nt-i"/>{{ item.title }}</div>
          <div class="font-small">{{ item.createTime }}</div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>

import { fetchPaging } from '@/api/sys/notice/notice'


export default {
  name: 'HomeNotice',
  data() {
    return {
      listData: []
    }
  },

  created() {
    this.initData()
  },
  methods: {
    // 查找轮播图
    initData() {
      fetchPaging({ current: 1, size: 5 }).then(res => {
        this.listData = res.data.records
      })
    },

    // 转到详情
    toDetail(id) {
      this.$router.push({ name: 'NoticeDetail', params: { id: id }})
    }
  }
}
</script>

<style scoped>
.nt-box{
  width: 320px;
  flex-shrink:0;
  padding-left: 20px;
}
.nt-inner{
  background: #fff;
  padding: 15px;
}
.nt-item{
  padding: 5px 0;
  line-height: 20px;
  border-bottom:  #F5F7F9 1px solid;
}

.nt-item a {
  color: #555;
  font-size: 14px;
  font-weight: 500;
}

.nt-item a .nt-i {
  color: #C6302B;
}

.nt-item a:hover .nt-i {
  color: #FBBE0F;
}


.nt-item a:hover {
  color: #C6302B;
}
</style>
