import { post } from '@/utils/request'

export function listAll(data) {
  return post('/api/course/ref/file/list-all', data)
}

export function joinAll(data) {
  return post('/api/course/ref/file/join-all', data)
}

export function remove(data) {
  return post('/api/course/ref/file/remove', data)
}

export function batchUpdate(data) {
  return post('/api/course/ref/file/batch-update', data)
}

export function batchSort(data) {
  return post('/api/course/ref/file/batch-sort', data)
}

export function listIds(data) {
  return post('/api/course/ref/file/list-ids', data)
}
