<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="引用内容"
    width="50%"
    @close="handleClose"
  >

    <data-table
      ref="dataTable"
      :options="options"
      :list-query="listQuery"
    >

      <template #filter-content>
        <el-input v-model="listQuery.params.refTitle" size="small" style="width: 200px" placeholder="查找名称" class="filter-item" />
      </template>

      <template #data-columns>

        <el-table-column
          align="center"
          label="引用类型"
          width="120px"
          prop="refType_dictText"
        />

        <el-table-column
          label="引用ID"
          prop="refId"
          width="200px"
        />

        <el-table-column
          label="名称"
        >
          <template v-slot="{row}">
            <el-link type="primary" target="_blank" @click="toCourse(row.refId)">{{ row.refTitle }}</el-link>
          </template>

        </el-table-column>

      </template>
    </data-table>
  </el-dialog>

</template>

<script>

export default {
  name: 'MediaRefDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    fileId: String
  },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          fileId: ''
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/media/ref/paging'
      },
      dialogVisible: false
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    },
    fileId: {
      handler(val) {
        this.listQuery.params.fileId = val
      }
    }
  },


  methods: {
    handleClose() {
      this.$emit('update:visible', false)
      this.dialogVisible = false
    },
    // 课程详情
    toCourse(id) {
      this.$router.push({ name: 'CourseView', params: { courseId: id }})
    }
  }
}
</script>

<style scoped>
</style>
