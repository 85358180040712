<template>

  <div v-if="detail!=null && detail.id!=null" >


    <div class="title-box">
      <div class="img">
        <el-image :src="detail.cover" />
      </div>
      <div class="desc">
        <div class="flex-bt">
          <div style="line-height: 30px">
            <div class="font-title" style="padding-bottom: 6px; font-size: 20px">{{ detail.title }}</div>
            <div class="font-second">共{{ detail.fileCount }}节课</div>
            <div class="font-second c-link" @click="toLecturerHome(detail.lecturerId)"> 讲师：{{ detail.lecturerId_dictText }}</div>
          </div>
          <div>
            <course-fav :course-id="detail.id" />
          </div>
        </div>


        <div class="price-box">
          <div class="flex">
            <div class="label">价格</div>
            <div class="price-show"><span style="font-size: 12px">￥</span>{{ detail.price }}</div>
          </div>
          <div class="flex">
            <div class="label">有效期</div>
            <div class="black2">自购买起<span style="color: #C6302B; font-weight: 700">{{ detail.expireDays }}</span>天内</div>
          </div>
        </div>
        <div v-if="buyCheck" style="margin-top: 20px">
          <div v-if="buyCheck.hasBuy">
            <el-button style="width: 180px" type="primary" @click="toLearn('')">
              去学习
              <i class="el-icon-video-play el-icon--right"/>
            </el-button>
          </div>
          <div v-else>
            <el-button style="width: 180px" type="danger" @click="handleBuy">
              立即购买<i class="el-icon-shopping-cart-1 el-icon--right"/>
            </el-button>
            <el-button v-if="buyCheck.tryLearn" style="width: 180px" type="warning" @click="toLearn">
              立即试学<i class="el-icon-thumb el-icon--right"/>
            </el-button>
          </div>
        </div>

      </div>
    </div>

    <div class="detail-box">
      <div class="files">
        <el-tabs v-model="tabActive">
          <el-tab-pane label="课程介绍" name="detail">
            <div class="content">
              <div v-html="$options.filters.formatRichText(detail.content)" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="课程目录" name="dir">
            <course-dir-show
              v-for="dir in detail.dirList"
              :key="dir.id"
              :data="dir"
              :level="1"
              :course-id="courseId"
              @select="toLearn"
            />
          </el-tab-pane>

          <el-tab-pane label="课程评价" name="score">
            <course-score :course-id="courseId"/>
          </el-tab-pane>

        </el-tabs>
      </div>

      <div class="help">

        <el-collapse :value="'1'">
          <el-collapse-item title="如何查看已购课程？" name="1">
            <div>您可以在课程详情页直接跳转到学习，或者在个人中心左侧菜单，点击已购课程进行查看和学习；</div>
          </el-collapse-item>
          <el-collapse-item title="哪里可以试学课程？" name="2">
            <div>在课程详情页面，如果出现了试学标志，则表示当前课程支持试学，您可以通过试学确定是否适合学习该课程。</div>
          </el-collapse-item>
          <el-collapse-item title="课程过期了怎么办？" name="3">
            <div>课程有效期一般为1年，到期后将无法学习，一般情况下，您需要再次购买，当然，您可以联系客服获取一个专属的优惠券。</div>
          </el-collapse-item>
        </el-collapse>


      </div>

    </div>

    <!-- 支付弹窗 -->
    <course-buy :visible.sync="payVisible" :course-id="courseId" />

  </div>

</template>

<script>

import { detailForShow, checkBuy } from '@/api/course/course'
import { mapGetters } from 'vuex'
import CourseDirShow from '@/views/pages/course/components/CourseDirShow/index.vue'
import CourseFav from '@/views/pages/course/components/CourseFav/index.vue'
import CourseScore from '@/views/pages/course/components/CourseScore/index.vue'

export default {
  components: { CourseScore, CourseFav, CourseDirShow },
  data() {
    return {
      // 是否已购买
      buyCheck: null,
      tabActive: 'detail',
      dirActive: [0, 1],
      detail: {},
      courseId: null,
      fileList: [],
      payVisible: false,
      payOrder: null
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },

  created() {
    // 课程ID
    this.courseId = this.$route.params.courseId
    if (this.courseId) {
      this.fetchData()
    }
  },
  methods: {

    fetchData() {
      detailForShow(this.courseId).then(res => {
        this.detail = res.data

        // 校验是否已购买
        this.handleCheckBuy()
      })
    },

    handleCheckBuy() {
      // 校验是否已购买
      checkBuy(this.courseId).then(res => {
        this.buyCheck = res.data
      })
    },

    handleBuy() {
      if (!this.userId) {
        this.$router.push(`/login?redirect=${this.$route.path}`)
        return
      }
      this.payVisible = true
    },

    // 前往学习页面
    toLearn(file) {
      const query = { courseId: this.courseId }
      if (file && file.fileId) {
        query.fileId = file.fileId
      }
      this.$router.push({ name: 'CourseLearn', query: query })
    },
    // 讲师页面
    toLecturerHome(id) {
      const route = this.$router.resolve({ name: 'LecturerHome', params: { id: id }})
      window.open(route.href, '_blank')
    }
  }
}
</script>

<style scoped>

   ::v-deep .content{
     text-align: left;
   }

   ::v-deep .content img{
     text-align: left;
     max-width: 100%;
   }

   .price-box{
     background: #F5F7F9;
     line-height: 40px;
     padding: 10px;
     margin: 10px 0;
   }

   .price-box .label{
     margin-right: 20px;
     width: 58px;
     display: inline-block;
     text-align: justify;
     text-align-last: justify;
     -moz-text-align-last: justify;
     margin-left: 12px;
     color: #797A7D;
     line-height: 14px;
   }

   .price-show{
    font-size: 36px;
    color: #f44130;
     font-weight: 700;
   }

   ::v-deep .el-collapse-item__header{
     font-weight: 700;
     font-size: 16px;
     background: #F5F7F9;
     padding: 0 10px;
   }

   ::v-deep .el-collapse-item__content{
     padding: 10px 0;
   }

  ::v-deep ul {
    padding-inline-start: 10px;
  }

   .try-tag {
     min-width: 48px !important;
     padding: 0;
     height: 18px;
     line-height: 18px;
     font-size: 12px;
     display: block;
     float: right;
     text-indent: 0;
     text-align: center;
     border-radius: 12px 9px 9px 0;
     position: relative;
     top: 0;
     left: 10px;
   }

   .try-btn {
     border: 1px solid transparent !important;
     background: linear-gradient(94deg, #EC6A6E, #F3886D);
     box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
     color: #ffffff !important;
   }

   .link:hover{
     color: #ff3333;
   }

   .el-link.el-link--default.is-disabled {
     color: #555;
   }

   .title-box{
     background: #fff;
     display: flex;
     padding: 20px
   }

   .title-box > .img{
     width: 400px;
   }

   .title-box > .desc{
     flex-grow: 1;
     padding-left: 20px;
     line-height: 32px;
   }

   .detail-box{
     display: flex;
     margin-top: 20px
   }

   .detail-box > .files{
     background: #fff;
     padding: 20px;
     flex-grow: 1
   }

   .detail-box > .help{
     background: #fff;
     display: flex;
     padding: 20px;
     width: 300px;
     margin-left: 20px;
     flex-shrink: 0
   }

</style>
