<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <el-form ref="postForm" :model="postForm" :rules="rules" label-width="120px" label-position="left">

      <el-form-item label="提问内容" prop="content">
        <Tinymce ref="editor" v-model="postForm.content" :height="200" :media="false" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>

  </el-dialog>

</template>

<script>

import { saveData } from '@/api/course/qa'

export default {
  name: 'CourseQaCreateDialog',
  props: {
    visible: { type: Boolean, default: false },
    courseId: String,
    fileId: String,
    replyId: String
  },
  data() {
    return {
      dialogVisible: false,
      rules: {
        content: [{ required: true, message: '内容不能为空！' }]
      },
      postForm: {
        courseId: '',
        fileId: ''
      },
      loading: false
    }
  },

  watch: {

    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },

  methods: {

    // 提交问答
    handleSave() {
      this.postForm.courseId = this.courseId
      this.postForm.fileId = this.fileId
      this.postForm.replyId = this.replyId

      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        saveData(this.postForm).then(() => {
          this.$notify({
            title: '成功',
            message: '问题提交成功！',
            type: 'success',
            duration: 2000
          })
          this.handleClose()
        })
      })
    },

    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('success')
      this.$refs.editor.setContent('')
    }

  }
}
</script>
