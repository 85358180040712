<template>
  <web-socket v-if="fileId!=null" :url="`/api/socket/read/${userId}/${courseId}/${fileId}`" @receive="onMessage" />
</template>

<script>
import { mapGetters } from 'vuex'
import WebSocket from '@/components/WebSocket/index.vue'

export default {
  name: 'ReadSocket',
  components: { WebSocket },
  props: {
    fileId: String,
    courseId: String
  },

  data() {
    return {
      msgShow: false
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },

  watch: {
    fileId: {
      handler() {
        this.msgShow = false
      }
    },
    courseId: {
      handler() {
        this.msgShow = false
      }
    }
  },

  methods: {

    // 收到消息
    onMessage(data) {
      console.log('+++++阅读消息：' + data)

      const json = JSON.parse(data)

      // 学习时间变化
      this.$emit('change', json)

      if (json.stopped) {
        this.$message.error(json.stopMsg)
        this.msgShow = true
        setTimeout(() => {
          this.$emit('break', json)
        }, 3500)
        return
      }

      if (json.all && !this.msgShow) {
        this.$message.success('恭喜，您已经学完全部课程啦！')
        this.msgShow = true
        this.$emit('finish', json)
        return
      }

      if (json.current && !this.msgShow) {
        this.$message.success('恭喜，您已学完本课件！')
        this.$emit('finish', json)
        this.msgShow = true
      }
    }
  }
}
</script>
