import { post } from '@/utils/request'

/**
 * 单纯校验验证码
 * @param data
 * @returns {Promise}
 */
export function checkCode(data) {
  return post('/api/common/captcha/check', data)
}
