<template>
  <el-image
    :src="src"
    :style="`width:${size}px; height:${size}px; border:${border}`"
    class="yf-avatar"
    fit="cover">
    <div slot="error">
      <el-image :src="defaultAvatar" />
    </div>
  </el-image>
</template>

<script>
export default {
  name: 'YfAvatar',
  components: { },
  props: {
    src: String,
    size: {
      type: Number,
      default: 40
    },
    border: {
      type: String,
      default: 'none'
    }
  },
  data() {
    return {
      defaultAvatar: require('@/assets/web/avatar.png')
    }
  }
}
</script>

<style scoped>
.yf-avatar{
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background: #C0C4CC;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer
}
.yf-avatar > img{
  width: 100%;
  height: 100%;
}
</style>
