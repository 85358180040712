import { post } from '@/utils/request'

/**
 * 查找评价列表，各个纬度的
 * @param courseId
 * @returns {Promise}
 */
export function listScore(courseId) {
  return post('/api/course/score/total/list-by-course', { id: courseId })
}

/**
 * 列出全部纬度的字段
 * @returns {Promise}
 */
export function listFields() {
  return post('/api/course/score/field/list', { })
}

/**
 * 保存评价
 * @param data
 * @returns {Promise}
 */
export function saveScore(data) {
  return post('/api/course/score/record/save', data)
}

/**
 * 查找我的评价记录
 * @param courseId
 * @returns {Promise}
 */
export function listMyScore(courseId) {
  return post('/api/course/score/record/list-my-score', { id: courseId })
}


