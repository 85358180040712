<template>

  <div>

    <el-tag
      v-for="(item,index) in tagList"
      :key="index"
      closable
      style="margin-right: 10px"
      @close="closeTag(item)">{{ item }}</el-tag>
    <el-button type="primary" size="mini" @click="showAdd">+ 添加</el-button>

    <el-dialog
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="400px"
      @close="dialogVisible=false">

      <el-time-picker
        v-model="rangeValue"
        :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }"
        is-range
        value-format="HH:mm"
        format="HH:mm"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"/>

      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAdd">确 定</el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>

export default {
  name: 'TimeRangePicker',
  props: {
    value: String
  },
  data() {
    return {
      dialogVisible: false,
      // 下拉选项值
      rangeValue: ['00:00', '23:59'],
      tagList: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler(val) {
        this.fillValue(val)
      }
    },
    tagList: {
      handler(val) {
        this.subChange(val)
      }
    }
  },
  created() {
    this.fillValue(this.value)
  },
  methods: {

    // 初始化值
    fillValue(val) {
      if (!val) {
        return
      }
      this.tagList = val.split('\n')
    },

    // 回调值
    subChange(val) {
      this.$emit('input', val.join('\n'))
    },

    // 打开添加
    showAdd() {
      this.rangeValue = ['00:00', '23:59']
      this.dialogVisible = true
    },

    // 确认添加
    confirmAdd() {
      this.tagList.push(this.rangeValue.join('-'))
      this.dialogVisible = false
    },

    // 移除标签
    closeTag(tag) {
      for (let i = 0; i<this.tagList.length; i++) {
        if (this.tagList[i] === tag) {
          console.log('移除他', this.tagList[i])
          this.tagList.splice(i, 1)
          return
        }
      }
    }
  }
}
</script>
