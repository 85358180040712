var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "保存媒体",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "120px",
          },
        },
        [
          !_vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "课件类型", prop: "fileType" } },
                [
                  _c("dic-list-select", {
                    attrs: { "dic-code": "media_file_type" },
                    model: {
                      value: _vm.postForm.fileType,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "fileType", $$v)
                      },
                      expression: "postForm.fileType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.fileType && !_vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "上传文件", prop: "fileUrl" } },
                [
                  _c("file-upload", {
                    attrs: {
                      limit: 1,
                      tips: _vm.tips,
                      accept: _vm.accept,
                      "list-type": "file",
                    },
                    on: { select: _vm.fileSelected },
                    model: {
                      value: _vm.postForm.fileUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "fileUrl", $$v)
                      },
                      expression: "postForm.fileUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "课件名称", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.postForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.postForm, "title", $$v)
                  },
                  expression: "postForm.title",
                },
              }),
            ],
            1
          ),
          _vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "文件地址", prop: "fileUrl" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.postForm.fileUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "fileUrl", $$v)
                      },
                      expression: "postForm.fileUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "预览地址" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.postForm.viewUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.postForm, "viewUrl", $$v)
                      },
                      expression: "postForm.viewUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.isEdit
            ? _c(
                "el-alert",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "success" },
                },
                [_vm._v(" 课件保存后，您可以从列表来预览它！ ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }