var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": false,
        title: "人脸认证",
        width: "540px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "face-box" },
        [
          _vm.dialogVisible
            ? _c("tracking-cam", {
                ref: "cam",
                attrs: { tracking: true },
                on: {
                  "tracking-error": _vm.showManual,
                  tracked: _vm.handleTracked,
                },
              })
            : _vm._e(),
          _vm.faceInfo.faceToken != null && _vm.faceInfo.faceToken !== ""
            ? _c("div", { staticClass: "scanTip" }, [
                _c("div", [_vm._v("姓名：" + _vm._s(_vm.faceInfo.realName))]),
                _c("div", [_vm._v("身份证：" + _vm._s(_vm.faceInfo.idCard))]),
                _c("div", [_vm._v("识别成功，请继续下一步操作")]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.manualVisible
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleManual } },
                [_vm._v("拍照识别")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }