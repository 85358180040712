<template>


  <div>
    <div class="flex-bt">
      <h3>基本信息</h3>
      <div style="margin-top: -20px">
        <el-button type="primary" icon="el-icon-check" @click="handleSave('0')">保存</el-button>
        <el-button type="danger" icon="el-icon-s-promotion" @click="handleSave('1')">保存并提审</el-button>
      </div>

    </div>

    <div class="content-box">

      <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="120px">

        <el-form-item label="课程名称" prop="title">
          <el-input v-model="postForm.title" style="width: 100%" size="small" />
        </el-form-item>

        <el-form-item label="课程分类" prop="catId">
          <dic-catalog-tree v-model="postForm.catId" dic-code="course_catalog" />
        </el-form-item>

        <el-form-item label="课程封面" prop="cover">
          <file-upload
            v-model="postForm.cover"
            :limit="1"
            :compress="500"
            tips="请上传大于2:1的宽图"
            list-type="picture-card"
            accept=".jpg,.jpeg,.png"/>
        </el-form-item>

        <el-form-item label="课程定价" prop="price">
          <el-input-number v-model="postForm.price" :min="0" :max="9999999" :precision="2" size="small" />
          <tool-tip msg="如果定价为0，则为公开课程，任何人都可以进行学习" />
        </el-form-item>

        <el-form-item label="课程描述" prop="content">
          <Tinymce ref="editor" v-model="postForm.content" :height="200" />
        </el-form-item>

      </el-form>

    </div>

  </div>

</template>

<script>
import { auditSubmit, preCreate } from '@/api/lecturer/course'
import { simpleDetail } from '@/api/course/course'

export default {
  name: 'CourseEditBase',
  components: { },
  props: {
    courseId: String
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      postForm: {

      },
      rules: {
        title: [{ required: true, message: '课程标题不能为空！' }],
        catId: [{ required: true, message: '课程类目必须选择！' }],
        cover: [{ required: true, message: '课程封面必须上传！' }],
        price: [{ required: true, message: '课程定价不能为空！' }],
        content: [{ required: true, message: '课程描述不能为空！' }]
      }
    }
  },

  watch: {
    id: {
      handler(val) {
        this.showDetail(val)
      }
    }
  },

  created() {
    this.showDetail()
  },

  methods: {

    // 查找详情
    showDetail() {
      if (!this.courseId) {
        return
      }
      simpleDetail(this.courseId).then(res => {
        this.postForm = res.data
        this.$refs.editor.setContent(res.data.content)
      })
    },

    // 保存数据
    handleSave(next) {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        preCreate(this.postForm).then(() => {
          if (next === '1') {
            this.actionAudit()
          } else {
            this.$message.success('保存成功！')
            setTimeout(() => {
              this.$router.push({ name: 'LecturerCourse' })
            }, 1500)
          }
        })
      })
    },

    // 提交审核
    actionAudit() {
      this.$confirm('请确保课程信息完整，定价设置合理，审核通过后将无法再次修改！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        auditSubmit({ id: this.courseId, audit: true }).then(() => {
          this.$message.success('提审成功！')
          setTimeout(() => {
            this.$router.push({ name: 'LecturerCourse' })
          }, 1500)
        })
      })
    }
  }
}
</script>

<style scoped>
.content-box{
  border: #efefef 1px solid;
  border-radius: 5px;
  padding: 20px 15px;
}
</style>
