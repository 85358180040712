var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px", "label-position": "left" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户编号" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", disabled: "" },
                        model: {
                          value: _vm.postForm.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "id", $$v)
                          },
                          expression: "postForm.id",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", disabled: "" },
                        model: {
                          value: _vm.postForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "userName", $$v)
                          },
                          expression: "postForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.postForm.realPerson,
                          size: "small",
                        },
                        model: {
                          value: _vm.postForm.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "realName", $$v)
                          },
                          expression: "postForm.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属部门" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", disabled: "" },
                        model: {
                          value: _vm.postForm.deptCode_dictText,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "deptCode_dictText", $$v)
                          },
                          expression: "postForm.deptCode_dictText",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.postForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "email", $$v)
                          },
                          expression: "postForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.siteData.props.faceLogin
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "真实姓名" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.postForm.realName,
                              callback: function ($$v) {
                                _vm.$set(_vm.postForm, "realName", $$v)
                              },
                              expression: "postForm.realName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.siteData.props.faceLogin
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份证号" } },
                        [
                          _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.postForm.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.postForm, "idCard", $$v)
                              },
                              expression: "postForm.idCard",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户头像" } },
                    [
                      _c("file-upload", {
                        attrs: {
                          limit: 1,
                          compress: 5000,
                          "list-type": "picture-card",
                          tips: "请上传jpg或png格式图片",
                          accept: ".jpg,.png,.jpeg",
                        },
                        model: {
                          value: _vm.postForm.avatar,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm, "avatar", $$v)
                          },
                          expression: "postForm.avatar",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.updateInfo },
                },
                [_vm._v("更新资料")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }