<template>

  <data-table
    ref="pagingTable"
    :options="options"
    :list-query="listQuery"
  >
    <template #filter-content>
      <dic-list-select v-model="listQuery.params.payState" style="width: 100px" dic-code="pay_state_simple" title="支付状态"/>
      <dic-list-select v-model="listQuery.params.billState" style="width: 100px" dic-code="bill_state" title="账单状态"/>
      <date-time-picker
        :start-time.sync="listQuery.params.startTime"
        :end-time.sync="listQuery.params.endTime"
        start-holder="下单时间"
        class="filter-item"/>
      <el-input v-model="listQuery.params.title" style="width: 200px" size="small" placeholder="搜索标题" class="filter-item" />
    </template>

    <template #data-columns>

      <el-table-column
        show-overflow-tooltip
        label="标题"
        prop="title"
      />

      <el-table-column
        align="center"
        label="下单时间"
        prop="createTime"
      />
      <el-table-column
        align="center"
        label="下单用户"
        prop="userId_dictText"
      />

      <el-table-column
        align="center"
        label="总金额"
        prop="amount"
        width="120px"
      />


      <el-table-column
        align="center"
        label="结算比例"
        width="120px"
      >
        <template v-slot="scope">
          {{ scope.row.settleRate }} %
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="预计收益"
        prop="settleAmount"
        width="120px"
      />

      <el-table-column
        align="center"
        label="账单状态"
        prop="billState_dictText"
        width="120px"
      />

      <el-table-column
        align="center"
        label="订单状态"
        width="120px"
      >
        <template v-slot="scope">
          <span>{{ scope.row.payState_dictText }}</span>
        </template>
      </el-table-column>


    </template>

  </data-table>

</template>

<script>

export default {
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
          payState: 1
        }
      },

      options: {
        showSummary: true,
        multi: true,
        // 列表请求URL
        listUrl: '/api/settle/detail/lecturer-paging'
      }
    }
  },
  activated() {
    this.$refs.pagingTable.refresh()
  }
}
</script>
