var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    {
      staticClass: "tip-item",
      attrs: { content: _vm.msg, effect: "dark", placement: "top" },
    },
    [
      _c("i", {
        staticClass: "el-icon-question",
        staticStyle: { "font-size": "18px" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }