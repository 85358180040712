<template>

  <div>

    <div style="display: flex; justify-content: space-between;">
      <div style="width: 53%">
        <mall-address v-model="postForm.addrId"/>
        <div v-if="!detail.requireAddr">
          <div style="margin: 20px 0;font-size: 12px; color: #DD4A68">
            虚拟物品可以不选择地址，其他信息可以通过下方备注提交！
          </div>
        </div>

        <div style="margin: 20px 0">
          <div style="font-size: 18px; font-weight: 700; margin-bottom: 10px">订单备注</div>
          <el-input v-model="postForm.remark" type="textarea" rows="5" />
        </div>

        <div>
          <el-button :loading="loading" :disabled="!detail.orderVerify" type="danger" style="width: 100%" @click="handleSubmit">提交订单</el-button>
        </div>
      </div>

      <div class="right-box">

        <div style="font-size: 14px; line-height: 22px; padding: 10px; background: #FFF7E6; margin-bottom: 20px; color: #666">
          使用积分+实付款的商品，提交订单后将立即扣除积分；如订单超过30分钟未完成支付，已扣除的积分系统自动退回到您的账号！
        </div>

        <div class="goods-box">

          <div v-for="item in detail.goodsList" :key="item.id">
            <div v-if="!item.buyVerify.verify" class="verify-msg">
              {{ item.buyVerify.msg }}
            </div>

            <div class="list">
              <img :src="item.goodsImage" class="img">
              <div class="desc-box">
                <div style="display: flex">
                  <div class="title">{{ item.goodsTitle }}</div>
                  <div class="num">*1</div>
                </div>
                <div style="display: flex">
                  <div class="sku-t">{{ item.skuTitle }}</div>
                  <div class="sku-p">
                    <price-show :cost-points="item.costPoints" :sale-price="item.salePrice"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <el-divider/>

          <div class="settle">

            <div v-if="detail.totalPoints>0" style="display: flex; font-size: 14px; font-weight: 700; color: #333">
              <div style="flex-grow: 1">积分支付</div>
              <div style="font-style: italic; color: #333">
                {{ detail.totalPoints }}积分
              </div>
            </div>

            <div v-if="detail.totalAmount > 0" style="display: flex; font-size: 14px; font-weight: 700; color: #333">
              <div style="flex-grow: 1">现金支付</div>
              <div style="font-style: italic; color: #333">
                ￥{{ detail.totalAmount }}
              </div>
            </div>

            <div style="display: flex; font-size: 14px; font-weight: 700; color: #333">
              <div style="flex-grow: 1">发货方式</div>
              <div style="font-style: italic; color: #333">{{ detail.delivery }}</div>
            </div>

          </div>

        </div>

      </div>

    </div>

    <!-- 支付弹窗 -->
    <pay-dialog
      :visible.sync="payVisible"
      :order-id="orderId"
      @close="payBack"
      @success="payBack" />
  </div>

</template>

<script>
import { previewOrder, submitOrder } from '@/api/mall/order'

export default {
  data() {
    return {
      loading: false,
      skuId: '',
      detail: {},
      postForm: {
        addrId: '',
        items: []
      },
      payVisible: false,
      orderId: ''
    }
  },

  created() {
    this.postForm.items = [
      {
        skuId: this.$route.params.sku,
        buyCount: 1
      }
    ]

    previewOrder(this.postForm).then(res => {
      this.detail = res.data
    })
  },
  methods: {
    handleSubmit() {
      if (this.orderId) {
        this.payVisible = true
        return
      }

      this.loading = true
      submitOrder(this.postForm).then(res => {
        const data = res.data

        if (data.paid) {
          this.$message.success('商品购买成功！')
          setTimeout(() => {
            this.$router.push({ name: 'MallOrder' })
          }, 1200)
        } else {
          this.orderId = data.payId
          this.payVisible = true
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    payBack() {
      this.$router.push('/pages/uc?to=order&state=null')
    }
  }
}
</script>

<style scoped>

.right-box{
  display: flex;
  flex-direction: column;
  width: 44%;
}

.goods-box{
  display: flex;
  flex-direction: column;
  background: #f6f5f5;
  padding: 20px;
  overflow-y: auto
}

.goods-box .verify-msg{
  background: #fae6af;
  color: #666; padding: 10px;
  font-size: 12px;
  margin: 5px 0
}

.goods-box .list{
  display: flex;
}

.goods-box .list .img{
  width: 100px;
  height: 100px;
  border: #eee 1px solid;
  background: #fff;
}

.goods-box .list .desc-box{
  margin: 0 10px;
  line-height: 30px;
  flex-grow: 1;
}

.goods-box .list .desc-box .title{
  font-weight: 700;
  flex-grow: 1
}

.goods-box .list .desc-box .num{
  width: 100px;
  text-align: right;
  font-weight: 700;
  font-size: 22px;
}

.goods-box .list .desc-box .sku-t{
  flex-grow: 1;
  color: #666;
  font-size: 16px;
  font-weight: 700
}

.goods-box .list .desc-box .sku-p{
  width: 200px;
  text-align: right;
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  color: #e67e23;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'sans-serif';
}

.goods-box .settle{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  line-height: 32px;
}

</style>
