var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-range-slider" }, [
    _c(
      "div",
      {
        staticClass: "mrs-runway",
        on: {
          dblclick: function ($event) {
            $event.stopPropagation()
            return _vm.onAddPoint($event)
          },
        },
      },
      _vm._l(_vm.info, function (u, x) {
        return _c(
          "div",
          {
            key: u.code,
            staticClass: "mrs-li",
            style: { width: u.percent, left: u.startPos },
            on: {
              dblclick: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "el-tooltip",
              {
                ref: "T1",
                refInFor: true,
                attrs: { content: u.startValue, placement: "top" },
              },
              [
                _c("div", {
                  staticClass: "mrs-btn l",
                  on: {
                    dblclick: function ($event) {
                      return _vm.onDeletePoint(x, true)
                    },
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.onDragStart($event, x, "T1")
                    },
                  },
                }),
              ]
            ),
            _c("div", {
              staticClass: "mrs-bar",
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.onDragStart($event, x)
                },
              },
            }),
            _c(
              "el-tooltip",
              {
                ref: "T2",
                refInFor: true,
                attrs: { content: u.endValue, placement: "top" },
              },
              [
                _c("div", {
                  staticClass: "mrs-btn r",
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.onDragStart($event, x, "T2")
                    },
                    dblclick: function ($event) {
                      return _vm.onDeletePoint(x)
                    },
                  },
                }),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }