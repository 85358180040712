var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-grid",
        {
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [_c("course-card", { attrs: { data: data } })]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c("course-catalog", {
                model: {
                  value: _vm.listQuery.params.catId,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "catId", $$v)
                  },
                  expression: "listQuery.params.catId",
                },
              }),
              _c("el-divider"),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "padding-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "500",
                        color: "#303133",
                      },
                    },
                    [_vm._v("课程搜索： ")]
                  ),
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px", "margin-left": "10px" },
                        attrs: {
                          placeholder: "搜索课程名称",
                          "prefix-icon": "el-icon-search",
                          size: "small",
                        },
                        model: {
                          value: _vm.listQuery.params.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery.params, "title", $$v)
                          },
                          expression: "listQuery.params.title",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }