var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-bt" }, [
      _c("h3", [_vm._v("基本信息")]),
      _c(
        "div",
        { staticStyle: { "margin-top": "-20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-check" },
              on: {
                click: function ($event) {
                  return _vm.handleSave("0")
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", icon: "el-icon-s-promotion" },
              on: {
                click: function ($event) {
                  return _vm.handleSave("1")
                },
              },
            },
            [_vm._v("保存并提审")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _c(
          "el-form",
          {
            ref: "postForm",
            attrs: {
              model: _vm.postForm,
              rules: _vm.rules,
              "label-position": "left",
              "label-width": "120px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "课程名称", prop: "title" } },
              [
                _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "title", $$v)
                    },
                    expression: "postForm.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "课程分类", prop: "catId" } },
              [
                _c("dic-catalog-tree", {
                  attrs: { "dic-code": "course_catalog" },
                  model: {
                    value: _vm.postForm.catId,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "catId", $$v)
                    },
                    expression: "postForm.catId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "课程封面", prop: "cover" } },
              [
                _c("file-upload", {
                  attrs: {
                    limit: 1,
                    compress: 500,
                    tips: "请上传大于2:1的宽图",
                    "list-type": "picture-card",
                    accept: ".jpg,.jpeg,.png",
                  },
                  model: {
                    value: _vm.postForm.cover,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "cover", $$v)
                    },
                    expression: "postForm.cover",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "课程定价", prop: "price" } },
              [
                _c("el-input-number", {
                  attrs: { min: 0, max: 9999999, precision: 2, size: "small" },
                  model: {
                    value: _vm.postForm.price,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "price", $$v)
                    },
                    expression: "postForm.price",
                  },
                }),
                _c("tool-tip", {
                  attrs: {
                    msg: "如果定价为0，则为公开课程，任何人都可以进行学习",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "课程描述", prop: "content" } },
              [
                _c("Tinymce", {
                  ref: "editor",
                  attrs: { height: 200 },
                  model: {
                    value: _vm.postForm.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "content", $$v)
                    },
                    expression: "postForm.content",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }