<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    title="选择用户"
    width="60%"
    @close="handleClose"
  >

    <div class="app-container">
      <data-table
        ref="pagingTable"
        :options="options"
        :list-query="listQuery"
        @select-changed="handleSelected"
      >
        <template #filter-content>

          <dic-list-select v-model="listQuery.params.state" dic-code="user_state" style="width: 150px; margin-bottom: 0"/>
          <depart-tree-select v-model="listQuery.params.deptCode" class="filter-item" style="width: 200px;" />
          <el-input v-model="listQuery.params.userName" size="small" style="width: 200px;" placeholder="账号/姓名" class="filter-item" />
          <el-button type="primary" class="filter-item" size="small" @click="handleConfirm">{{ selectedLabel }}</el-button>

        </template>

        <template #data-columns>

          <el-table-column
            label="账号"
            align="center"
            prop="userName"
          />

          <el-table-column
            label="姓名"
            align="center"
            prop="realName"
          />

          <el-table-column
            label="手机号"
            align="center"
            prop="mobile"
          />

          <el-table-column
            label="邮箱"
            align="center"
            prop="email"
          />

          <el-table-column
            label="状态"
            align="center"
            prop="state_dictText"
          />

        </template>

      </data-table>

    </div>

  </el-dialog>

</template>

<script>

export default {
  name: 'UserSelectDialog',
  props: {
    excludes: Array,
    excludeDeparts: Array,
    excludeGroups: Array,
    dialogShow: {
      type: Boolean,
      default: false
    },
    selections: Array
  },
  data() {
    return {
      dialogVisible: false,
      selectedLabel: '请勾选用户',
      listQuery: {
        current: 1,
        size: 10,
        params: {
          state: '0',
          excludes: [],
          excludeDeparts: [],
          excludeGroups: []
        }
      },
      // 选定的数据列表
      selectedList: [],
      selectedObjs: [],
      options: {
        // 可批量操作
        multi: true,
        multiShow: false,
        keyId: 'id',
        // 列表请求URL
        listUrl: '/api/sys/user/paging'
      }
    }
  },

  watch: {

    // 检测查询变化
    dialogShow: {
      handler() {
        this.dialogVisible = this.dialogShow
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },

    excludes: {
      handler() {
        this.listQuery.params.excludes = this.excludes
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    excludeDeparts: {
      handler() {
        this.listQuery.params.excludeDeparts = this.excludeDeparts
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    excludeGroups: {
      handler() {
        this.listQuery.params.excludeGroups = this.excludeGroups
        if (this.$refs.pagingTable) {
          this.$refs.pagingTable.getList()
        }
      },
      deep: true
    },
    selectedList: {
      handler(val) {
        this.selectedLabel = '确认' + val.length + '项'
      }
    }
  },

  created() {
    this.dialogVisible = this.dialogShow
    this.listQuery.params.excludes = this.excludes
    this.listQuery.params.excludeDeparts = this.excludeDeparts
    this.listQuery.params.excludeGroups = this.excludeGroups
  },

  methods: {

    // 选定回调
    handleSelected(data) {
      if (data) {
        this.selectedList = data.ids
        this.selectedObjs = data.objs
      }
    },

    // 关闭选择框
    handleClose() {
      this.$emit('update:dialogShow', false)
      this.$refs.pagingTable.clearSelection()
      this.selectedList = []
      this.selectedObjs = []
    },

    handleConfirm() {
      if (this.selectedList === null || this.selectedList.length === 0) {
        this.$message({
          type: 'error',
          message: '请至少勾选一个用户！!'
        })
        return
      }

      this.$emit('select', this.selectedList, this.selectedObjs)

      // 关闭
      this.handleClose()
    }

  }
}
</script>

<style scoped>

  ::v-deep
  .el-dialog__body{
    padding: 0;
  }
</style>
