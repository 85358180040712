var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "container-bg" },
    [
      _c(
        "el-header",
        { staticStyle: { padding: "0", height: "60px" } },
        [_c("web-header")],
        1
      ),
      _c("el-main", { staticStyle: { padding: "0" } }, [
        _c("div", { staticClass: "container main-inner" }, [
          _c(
            "div",
            { staticStyle: { width: "160px" } },
            [
              _c("user-banner"),
              _c(
                "el-menu",
                {
                  attrs: {
                    router: true,
                    "default-active": _vm.defaultActive,
                    "default-openeds": ["uc"],
                    "text-color": "#333",
                    "unique-opened": "",
                    "active-text-color": "#fff",
                  },
                },
                [
                  _c(
                    "el-submenu",
                    { attrs: { index: "uc" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _c("span", [_vm._v("个人中心")]),
                      ]),
                      _c("el-menu-item", { attrs: { index: "/pages/uc" } }, [
                        _vm._v("个人信息"),
                      ]),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/info/update" } },
                        [_vm._v("资料修改")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/info/pass" } },
                        [_vm._v("密码安全")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/info/bind" } },
                        [_vm._v("账号绑定")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/info/real" } },
                        [_vm._v("实名认证")]
                      ),
                      _c("el-menu-item", { attrs: { index: "/pages/uc/im" } }, [
                        _vm._v("站内消息"),
                      ]),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/points" } },
                        [_vm._v("积分日志")]
                      ),
                    ],
                    2
                  ),
                  _vm.isLecturer
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "lecturer" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { staticClass: "el-icon-medal" }),
                            _c("span", [_vm._v("讲师中心")]),
                          ]),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "/pages/lecturer/account" } },
                            [_vm._v("账户总览")]
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "/pages/lecturer/course" } },
                            [_vm._v("课程发布")]
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "/pages/lecturer/media" } },
                            [_vm._v("媒体中心")]
                          ),
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "/pages/lecturer/settle/detail" },
                            },
                            [_vm._v("收益明细")]
                          ),
                          _c(
                            "el-menu-item",
                            {
                              attrs: { index: "/pages/lecturer/settle/order" },
                            },
                            [_vm._v("收益结算")]
                          ),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "/pages/lecturer/cash" } },
                            [_vm._v("提现管理")]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "el-submenu",
                    { attrs: { index: "course" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-reading" }),
                        _c("span", [_vm._v("课程学习")]),
                      ]),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/course/progress" } },
                        [_vm._v("学习记录")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/course/buy" } },
                        [_vm._v("已购课程")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/course/fav" } },
                        [_vm._v("收藏课程")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/course/qa" } },
                        [_vm._v("课程问答")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/course/cert" } },
                        [_vm._v("我的证书")]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-submenu",
                    { attrs: { index: "mall" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "el-icon-goods" }),
                        _c("span", [_vm._v("积分商城")]),
                      ]),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/mall/order" } },
                        [_vm._v("我的订单")]
                      ),
                      _c(
                        "el-menu-item",
                        { attrs: { index: "/pages/uc/mall/addr" } },
                        [_vm._v("收货地址")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "flex-grow": "1",
                "margin-left": "20px",
                background: "#fff",
                padding: "20px",
                border: "#dedede 1px solid",
              },
            },
            [_c("app-main")],
            1
          ),
        ]),
      ]),
      _vm.siteData.copyRight
        ? _c("el-footer", { staticClass: "footer-copy" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }