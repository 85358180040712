<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="提现"
    width="30%"
    @close="handleClose"
  >

    <el-form ref="postForm" :rules="rules" :model="postForm" label-width="100px" label-position="left">

      <el-form-item label="提现金额" prop="totalAmount">
        <el-input-number v-model="postForm.totalAmount" :max="availAmount" size="small" placeholder="输入提现金额" style="width: 200px"/>
        <small style="margin-left: 10px">账户可提现金额：{{ availAmount }}</small>
      </el-form-item>

      <el-form-item label="银行名称" prop="bankName">
        <el-input v-model="postForm.bankName" size="small" placeholder="银行名称，如中国银行、农业银行"/>
      </el-form-item>

      <el-form-item label="分行名称" prop="bankBranch">
        <el-input v-model="postForm.bankBranch" size="small" placeholder="分行和支行名称，如：北京分行XX支行" />
      </el-form-item>

      <el-form-item label="银行卡号" prop="bankCard">
        <el-input v-model="postForm.bankCard" size="small" placeholder="银行卡号" />
      </el-form-item>

      <el-form-item label="开户姓名" prop="bankHolder">
        <el-input v-model="postForm.bankHolder" size="small" placeholder="持卡人的真实姓名" />
      </el-form-item>


      <el-form-item>
        <el-button :loading="loading" type="primary" @click="saveCash">提交</el-button>
      </el-form-item>


  </el-form></el-dialog>

</template>

<script>

import { cashApply } from '@/api/lecturer/account'

export default {
  name: 'CashDialog',
  props: {
    visible: { type: Boolean },
    availAmount: { type: Number }
  },
  data() {
    return {
      dialogVisible: false,
      rules: {
        totalAmount: [{ required: true, message: '提现金额不能为空' }],
        bankName: [{ required: true, message: '银行名称不能为空' }],
        bankBranch: [{ required: true, message: '分行和支行名称不能为空' }],
        bankCard: [{ required: true, message: '银行卡号' }],
        bankHolder: [{ required: true, message: '持卡人的真实姓名不能为空' }]
      },
      postForm: {},
      loading: false
    }
  },

  watch: {

    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },

  methods: {

    // 提交提现申请
    saveCash() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }

        cashApply(this.postForm).then(() => {
          this.$message.success('提现提交成功，请耐心等待资金到账！')
          this.handleClose()
          this.$emit('success')
        })
      })
    },


    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('success')
    }

  }
}
</script>
