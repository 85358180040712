<template>

  <data-grid
    :options="options"
    :list-query="listQuery"
    empty-text="还没有收藏记录，赶紧去课程库看看吧~"
  >
    <template v-slot:data-item="{ data }">
      <course-card
        :data="data"
        :price="false"
        :show-try="false"/>
      <course-fav
        :course-id="data.id"
        size="18px"
        style="float: right;
        margin-top: -32px"/>
    </template>

  </data-grid>

</template>

<script>

import DataGrid from '@/components/DataGrid/index.vue'
import CourseCard from '@/components/CourseCard/index.vue'
import CourseFav from '@/views/pages/course/components/CourseFav/index.vue'

export default {
  components: { CourseFav, CourseCard, DataGrid },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
        params: {
        }
      },
      options: {
        // 列表请求URL
        listUrl: '/api/course/fav/paging',
        lineCount: 4
      }
    }
  }
}
</script>

