var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "u-title" }, [_vm._v("用户信息")]),
      _c(
        "div",
        [
          _c(
            "el-descriptions",
            [
              _c("el-descriptions-item", { attrs: { label: "编号" } }, [
                _vm._v(_vm._s(_vm.userData.id)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                _vm._v(_vm._s(_vm.userData.realName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "部门" } }, [
                _vm._v(_vm._s(_vm.userData.deptCode_dictText)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "积分" } }, [
                _vm._v(_vm._s(_vm.userData.points)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticStyle: { "margin-top": "30px" } }, [
      _c("div", { staticClass: "u-title" }, [_vm._v("最近学习")]),
      _c("div", [
        _vm.progressList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.progressList, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "dt-item" },
                  [_c("learn-ps-card", { attrs: { data: item } })],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              [
                _c("el-empty", {
                  attrs: {
                    description: "最近没有学习记录哦，快去课程库学习吧！",
                  },
                }),
              ],
              1
            ),
      ]),
    ]),
    _c("div", { staticStyle: { "margin-top": "30px" } }, [
      _c("div", { staticClass: "u-title" }, [_vm._v("积分获得")]),
      _c("div", [
        _vm.logList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.logList, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "dt-item" },
                  [_c("points-log-card", { attrs: { data: item } })],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              [
                _c("el-empty", {
                  attrs: {
                    description: "暂无积分记录，通过学习可以获得积分噢！",
                  },
                }),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }