var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "媒体预览",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _vm.dialogVisible
        ? _c(
            "div",
            [
              _vm.fileType === "video"
                ? _c("video-player", {
                    attrs: { "ref-id": _vm.refId },
                    model: {
                      value: _vm.viewUrl,
                      callback: function ($$v) {
                        _vm.viewUrl = $$v
                      },
                      expression: "viewUrl",
                    },
                  })
                : _vm._e(),
              _vm.fileType === "office" || _vm.fileType === "pdf"
                ? _c("pdf-reader", { attrs: { src: _vm.viewUrl } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }