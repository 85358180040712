<template>

  <div>

    <data-table
      ref="pagingTable"
      :options="options"
      :list-query="listQuery"
    >
      <template #filter-content>

        <dic-list-select v-model="listQuery.params.state" style="margin-right: 10px" dic-code="cash_state" title="提现状态" />
        <date-time-picker
          :start-time.sync="listQuery.params.startTime"
          :end-time.sync="listQuery.params.endTime"
          start-holder="提现时间"
        />
      </template>

      <template #data-columns>

        <el-table-column
          prop="createTime"
          label="提现时间"
          width="160px"
          show-overflow-tooltip
        />

        <el-table-column
          label="提现金额"
          prop="totalAmount"
          align="center"
          width="100px"
        />

        <el-table-column
          label="手续费"
          align="center"
          width="120px"
        >

          <template v-slot="{row}">
            <span>{{ row.taxAmount }} ({{ row.taxRate }}%)</span>
          </template>

        </el-table-column>


        <el-table-column
          label="实际应付"
          prop="payableAmount"
          align="center"
        />

        <el-table-column
          label="收款人"
          prop="bankHolder"
          align="center"
          show-overflow-tooltip
        />

        <el-table-column
          label="提现状态"
          prop="state_dictText"
          align="center"
        />

        <el-table-column
          label="支付时间"
          align="center"
          width="160px"
        >

          <template v-slot="{row}">
            <span v-if="row.payTime">{{ row.payTime }}</span>
            <span v-else>--</span>
          </template>

        </el-table-column>

        <el-table-column
          label="详情"
          align="center"
          width="120px"
        >

          <template v-slot="{row}">
            <el-link type="primary" icon="el-icon-view" @click="showDetail(row.id)">详情</el-link>
          </template>

        </el-table-column>

      </template>
    </data-table>

    <cash-detail-dialog :visible.sync="dialogVisible" :id="cashId" />

  </div>

</template>

<script>

import CashDetailDialog from '@/views/pages/uc/lecturer/cash/components/CashDetailDialog.vue'
import DateTimePicker from '@/components/DateTimePicker/index.vue'
import DicListSelect from '@/components/DicListSelect/index.vue'

export default {
  components: { DicListSelect, DateTimePicker, CashDetailDialog },
  data() {
    return {
      dialogVisible: false,
      cashId: '',
      listQuery: {
        current: 1,
        size: 10,
        params: {

        }
      },

      options: {
        // 可批量操作
        multi: false,
        // 列表请求URL
        listUrl: '/api/lecturer/cash/lecturer-paging'
      }
    }
  },

  methods: {

    // 显示详情
    showDetail(id) {
      this.cashId = id
      this.dialogVisible = true
    }

  }
}
</script>
