<template>

  <div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="选择支付方式"
      width="660px"
      @close="handleClose"
    >

      <div style="text-align: center">

        <div v-if="prepay">

          <div class="pay-box">

            <template v-for="item in payList">
              <div v-if="item.enabled" :key="item.name" class="pay-item" @click="tradePay(item.name)">
                <div :class="`pay-icon-${item.name}`" class="pay-icon" />
                <span v-if="item.name === 'alipay'" class="pay-text">支付宝支付</span>
                <span v-if="item.name === 'wechat'" class="pay-text">微信支付</span>
                <span v-if="item.name === 'paypal'" class="pay-text">Paypal支付</span>
              </div>
            </template>
          </div>

        </div>

        <div v-else>
          <div v-if="payMethod === 'wechat'" style="line-height: 50px; text-align: center">
            <vue-qr :text="weQr" :margin="0" width="300px" />
            <div>请使用微信扫码支付</div>
          </div>

          <div v-if="payMethod === 'alipay'" style="line-height: 50px; text-align: center">
            <iframe
              :srcdoc="aliForm"
              class="ali-frame"
            />
            <div>请打开支付宝APP扫码支付</div>
          </div>

          <div v-if="payMethod === 'paypal'" style="line-height: 50px; text-align: center">
            请在新窗口完成支付！
          </div>

          <div>
            <el-button type="warning" @click="changePay()">更换支付方式</el-button>
            <el-button type="primary" @click="checkState()">我已支付成功</el-button>
          </div>

        </div>

      </div>

    </el-dialog>

    <web-socket v-if="socket" :url="`/api/socket/pay/${orderId}`" :beat-interval="2000" @receive="onMessage" />

  </div>

</template>

<script>

import vueQr from 'vue-qr'
import { payConfig } from '@/api/ability/pay/config'
import { wechatTrade } from '@/api/ability/pay/wechat'
import { alipayTrade } from '@/api/ability/pay/alipay'
import { paypalTrade } from '@/api/ability/pay/paypal'
import { checkOrder } from '@/api/order/order'
import { Loading } from 'element-ui'
import WebSocket from '../WebSocket'

export default {
  name: 'PayDialog',
  components: { WebSocket, vueQr },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: String
  },
  data() {
    return {
      // 支付方式
      payList: [],
      dialogVisible: false,
      // 选择支付方式
      prepay: true,
      // 微信支付
      payMethod: '',
      weQr: '',
      // 支付宝相关
      aliForm: '',
      socket: false
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
        this.changePay()
      },
      deep: true
    }
  },

  created() {
    this.dialogVisible = this.visible

    // 查找支付方式
    payConfig().then(res => {
      this.payList = res.data
    })
  },

  methods: {

    tradePay(name) {
      // 当前支付方式
      this.payMethod = name

      if (name === 'wechat') {
        this.wechatPay()
      }

      if (name === 'alipay') {
        this.alipayPay()
      }

      if (name === 'paypal') {
        this.paypalPay()
      }
    },

    // 微信支付
    wechatPay() {
      // 打开
      const loading = Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      // 支付过程
      wechatTrade({ id: this.orderId }).then(res => {
        this.weQr = res.data
        this.prepay = false
        this.socket = true
        loading.close()
      }).catch(() => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
    },

    // 支付宝支付
    alipayPay() {
      // 支付过程
      const loading = Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      alipayTrade({ id: this.orderId }).then(res => {
        loading.close()
        this.prepay = false
        this.socket = true
        this.aliForm = res.data
      }).catch(() => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
    },

    // paypal支付
    paypalPay() {
      // 支付过程
      const loading = Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      paypalTrade({ id: this.orderId }).then(res => {
        loading.close()
        this.prepay = false
        this.socket = true
        this.paypalUrl = res.data
        window.open(this.paypalUrl)
      }).catch(() => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
    },

    // 检查订单是否成功
    checkState() {
      checkOrder(this.orderId).then(res => {
        const data = res.data

        // 校验订单状态
        if (data.orderState === 2) {
          this.$message.success('支付成功！')
          // 支付成功
          this.$emit('success')
          this.$emit('update:visible', false)
        } else {
          this.$message.warning('订单尚未支付成功，请稍后刷新..')
        }
      })
    },

    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },

    changePay() {
      this.prepay = true
      this.payMethod = ''
      this.weQr = null
      this.aliForm = ''
    },

    // 支付结果回调
    onMessage(msg) {
      if (msg === 'success' && this.socket) {
        this.socket = false
        this.$message.success('支付成功！')
        this.$emit('success')
        this.$emit('update:visible', false)
      }
    }

  }
}
</script>

<style scoped>

::v-deep .el-dialog__body {
  padding: 20px;
}

.pay-box{
  padding: 20px 5px
}

.pay-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.pay-icon-alipay {
  background: url("~@/assets/web/images/alipay.png");
  background-size: 100%;
}

.pay-icon-wechat {
  background: url("~@/assets/web/images/wechat.png");
  background-size: 100%;
}

.pay-icon-paypal {
  background: url("~@/assets/web/images/paypal.png");
  background-size: 100%;
}

.pay-text {
  font-size: 20px;
}

.pay-item {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #d7dae2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.pay-item:hover {
  border: 1px solid #e67e23;
  color: #e67e23;
}

.ali-frame{
  border: none;
  width: 600px;
  height: 300px;
  overflow: hidden;
}
</style>
