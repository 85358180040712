<template>
  <div>
    <div v-if="$slots['filter-content']" class="filter-box">
      <slot name="filter-content"/>
    </div>
    <div v-if="dataList.total>0">

      <el-checkbox-group v-model="selectedIds">
        <div v-loading="loading" :class="`grid-box-${options.lineCount}`" class="grid-box">
          <template v-for="item in dataList.records">
            <div :key="item.id" :class="{'flex': options.multi}" class="grid-box-inner">
              <el-checkbox v-if="options.multi" :label="item[options.keyId || 'id']"><span/></el-checkbox>
              <slot :data="item" name="data-item"/>
            </div>
          </template>
        </div>
      </el-checkbox-group>

      <div class="paging-box">
        <pagination
          v-show="dataList.pages > 1"
          :total="dataList.total"
          :page.sync="listQuery.current"
          :auto-scroll="autoScroll"
          :limit.sync="listQuery.size"
          @pagination="getList" />
      </div>
    </div>
    <div v-else class="empty-box">
      <el-empty :description="emptyText"/>
    </div>

  </div>
</template>

<script>
import { postData } from '@/api/common'

export default {
  name: 'DataGrid',
  // 组件入参
  props: {
    emptyText: {
      type: String,
      default: '暂无相关数据！'
    },
    options: {
      type: Object,
      default: () => {
        return {
          keyId: 'id',
          lineCount: 6,
          // 列表请求URL
          listUrl: '',
          // 可批量操作
          multi: false
        }
      }
    },

    // 列表查询参数
    listQuery: {
      type: Object,
      default: () => {
        return {
          current: 1,
          size: 30,
          params: {},
          t: 0
        }
      }
    },

    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 接口数据返回
      dataList: {
        total: 0
      },
      // 数据加载标识
      loading: true,
      // 选定和批量操作
      selectedIds: []
    }
  },
  watch: {

    // 检测查询变化
    listQuery: {
      handler() {
        this.getList()
      },
      deep: true
    },
    // 检测查询变化
    selectedIds: {
      handler(val) {
        this.$emit('select-changed', val)
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {

    /**
     * 查询数据列表
     */
    getList() {
      this.loading = true
      this.listQuery.t = new Date().getTime()
      postData(this.options.listUrl, this.listQuery).then(response => {
        this.dataList = response.data
        this.loading = false
      })
    },

    // 下一页
    nextPage() {
      let current = this.listQuery.current
      if (current < this.dataList.pages) {
        current += 1
      } else {
        // 重头再来
        current = 1
      }
      this.listQuery.current = current
    }
  }
}
</script>

<style scoped>


.filter-box {
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  background: #f3f5fa;
  border:#dcdfe6 1px solid;
  border-radius: 3px;
}

.paging-box {
  text-align: center;
  display: flex;
  justify-content: center;
}

.empty-box {
  display: flex;
  align-items: center;
  height: 60vh;
  justify-content: center;
}

::v-deep .filter-box .filter-item {
  margin-left: 10px;
}

::v-deep .filter-box .filter-item:first-child {
  margin-left: 0;
}

::v-deep .pagination-container {
  background: transparent !important;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #C6302B !important;
  color: #FFFFFF;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next,
::v-deep .el-pagination.is-background .el-pager li {
  background-color: #e4e4e5 !important;
}

::v-deep .el-checkbox-group {
  font-size: 14px;
}

</style>
