// 知识竞赛模块
import WebLayout from '@/layout/WebLayout'

const examRouter = {
  path: '/',
  component: WebLayout,
  hidden: true,
  children: [
    {
      path: '/pages/index',
      name: 'WebIndex',
      component: () => import('@/views/pages/home'),
      meta: { title: '首页', noCache: true, activeMenu: '/pages/index' }
    },
    {
      path: '/pages/notice',
      component: () => import('@/views/pages/notice'),
      name: 'NoticeList',
      meta: { title: '系统公告', noCache: true, activeMenu: '/pages/notice' }
    },
    {
      path: '/pages/notice/detail/:id',
      component: () => import('@/views/pages/notice/detail'),
      name: 'NoticeDetail',
      meta: { title: '公告详情', noCache: true, activeMenu: '/pages/notice' }
    }
  ]
}
export default examRouter
