<template>

  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="保存媒体"
    width="50%"
    @close="handleClose"
  >
    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="120px">

      <el-form-item v-if="!isEdit" label="课件类型" prop="fileType">
        <dic-list-select v-model="postForm.fileType" dic-code="media_file_type" />
      </el-form-item>

      <el-form-item v-if="postForm.fileType && !isEdit" label="上传文件" prop="fileUrl">
        <file-upload v-model="postForm.fileUrl" :limit="1" :tips="tips" :accept="accept" list-type="file" @select="fileSelected" />
      </el-form-item>

      <el-form-item label="课件名称" prop="title">
        <el-input v-model="postForm.title" />
      </el-form-item>

      <el-form-item v-if="isEdit" label="文件地址" prop="fileUrl">
        <el-input v-model="postForm.fileUrl" disabled />
      </el-form-item>

      <el-form-item v-if="isEdit" label="预览地址">
        <el-input v-model="postForm.viewUrl" disabled />
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-alert
        v-if="!isEdit"
        type="success"
        style="margin-bottom: 20px"
      >
        课件保存后，您可以从列表来预览它！
      </el-alert>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>

</template>

<script>
import { detail, save } from '@/api/media/file'

export default {
  name: 'MediaSaveDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    fileId: String,
    spaceId: String,
    catId: String
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      postForm: {},
      rules: {
        title: [{ required: true, message: '课件名称不能为空！' }],
        fileType: [{ required: true, message: '课件类型不能为空！' }],
        fileUrl: [{ required: true, message: '课件文件必须上传！' }]
      },
      isEdit: false,
      tips: '',
      accept: '',
      data: {}
    }
  },

  watch: {
    // 检测查询变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
        if (this.dialogVisible) {
          this.initDialog()
        }
      }
    },
    'postForm.fileType': {
      handler(val) {
        if (val === 'office') {
          this.tips = '包含doc,docx,xls,xlsx,ppt,pptx等Office类型文件'
          this.accept = '.doc, .docx, .xls, .xlsx, .ppt, .pptx'
          this.data = {}
        }
        if (val === 'pdf') {
          this.tips = 'PDF文件可以直接在线预览'
          this.accept = '.pdf'
          this.data = {}
        }
        if (val === 'video') {
          this.tips = '视频文件,尽可能使用mp4格式兼容性较好，也支持flv,mkv,rm,rmvb等格式'
          this.accept = '.mp4, .mov, .flv, .mkv, .rm, .rmvb, .webm, .ogv, .avi'
          this.data = {}
        }
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
    this.initDialog()
  },

  methods: {

    initDialog() {
      if (!this.dialogVisible) {
        return
      }

      if (this.fileId) {
        this.handleEdit()
      } else {
        this.handleAdd()
      }
    },

    handleAdd() {
      this.postForm = { spaceId: this.spaceId, catId: this.catId, title: '' }
      this.dialogVisible = true
      this.isEdit = false
      this.$nextTick(() => {
        if (this.$refs.postForm) {
          this.$refs.postForm.clearValidate()
        }
      })
    },

    handleEdit() {
      // 先清理表单
      this.postForm = {}
      this.isEdit = true
      this.dialogVisible = true
      detail({ id: this.fileId }).then(res => {
        this.postForm = res.data
      })
    },

    handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        save(this.postForm).then(() => {
          this.$message({
            type: 'success',
            message: '课件保存成功!'
          })
          this.dialogVisible = false
          this.loading = false
          this.$emit('success')
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      })
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.dialogVisible = false
    }

  }
}
</script>

<style scoped>
</style>
