<template>


  <div>
    <div>
      <course-edit-base :course-id="courseId"/>
    </div>


    <h3>课程安排</h3>
    <div class="content-box" style="display: flex">
      <div style="width: 300px;">
        <course-edit-tree :course-id="courseId" :dir-id="dirId" @checked="dirChange"/>
      </div>


      <div style="flex-grow: 1; border-left: #f5f5f5 1px solid">
        <course-edit-file :course-id="courseId" :dir-id="dirId"/>
      </div>
    </div>

  </div>

</template>

<script>
import CourseEditTree from '@/views/pages/uc/lecturer/course/components/CourseEditTree/index.vue'
import CourseEditFile from '@/views/pages/uc/lecturer/course/components/CourseEditFile/index.vue'
import CourseEditBase from '@/views/pages/uc/lecturer/course/components/CourseEditBase/index.vue'

export default {
  components: { CourseEditBase, CourseEditFile, CourseEditTree },
  data() {
    return {
      courseId: null,
      dirId: null,
      dirKey: null
    }
  },

  created() {
    this.courseId = this.$route.query.courseId

    if (this.courseId) {
      this.dirKey = 'dir-' + this.courseId
    }

    // 取缓存
    const dirId = localStorage.getItem(this.dirKey)
    if (dirId) {
      this.dirId = dirId
    }
  },

  methods: {

    dirChange(dirId) {
      this.dirId = dirId
      localStorage.setItem(this.dirKey, this.dirId)
    }
  }
}
</script>

<style scoped>
.content-box{
  border: #efefef 1px solid;
  border-radius: 5px;
  padding: 20px 15px;
}
</style>
