var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-inner",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.data.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "image-3x2" },
        [
          _c("el-image", { attrs: { src: _vm.data.cover } }, [
            _c(
              "div",
              { attrs: { slot: "error" }, slot: "error" },
              [_c("el-image", { attrs: { src: _vm.defaultCover } })],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "font-title c-link",
          staticStyle: { "padding-top": "10px" },
        },
        [_vm._v(_vm._s(_vm.data.title))]
      ),
      _c("div", { staticClass: "flex-bt" }, [
        _c("span", { staticClass: "font-second" }, [
          _vm._v("共" + _vm._s(_vm.data.fileCount) + "节课"),
        ]),
        _vm.data.tryLearn && _vm.showTry
          ? _c("span", { staticClass: "try-btn try-tag" }, [_vm._v("试学")])
          : _vm._e(),
      ]),
      _vm.price ? _c("el-divider") : _vm._e(),
      _vm.price
        ? _c("div", { staticClass: "flex-bt" }, [
            _c(
              "div",
              {
                staticClass: "font-small c-link",
                on: {
                  click: function ($event) {
                    return _vm.toCatalog(_vm.data.catId)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.data.catId_dictText || "所有课程"))]
            ),
            _c("div", { staticClass: "price-show" }, [
              _vm._v("￥" + _vm._s(_vm.data.price)),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }