<template>
  <div>

    <el-row :gutter="20">

      <el-col :span="24">
        <h3>系统公告</h3>
      </el-col>

      <el-col :span="24">
        <el-card :class="{'size-normal': size==='normal', 'size-small': size==='small'}">
          <el-table
            :data="tableData"
            :show-header="false"
            style="width: 100%">
            <el-table-column
              label="公告标题"
              prop="title"
              show-overflow-tooltip>

              <template v-slot="scope">
                <detail-link :id="scope.row.id" :title="scope.row.title" @click="toDetail(scope.row.id)" />
              </template>

            </el-table-column>

          </el-table>
        </el-card>

      </el-col>

    </el-row>

  </div>

</template>

<script>
import permission from '@/directive/permission'
import { postData } from '@/api/common'

export default {
  name: 'CardNoticeList',
  directives: { permission },
  props: {
    size: String
  },
  data() {
    return {
      tableData: [],
      listQuery: {
        current: 1,
        size: 4,
        params: {
          state: 0,
          title: ''
        }
      },
      pageShow: false
    }
  },
  watch: {
    size: {
      handler() {
        setTimeout(() => {
          this.fetchData()
        }, 100)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {

    // 获得最新公告
    async fetchData() {
      this.listQuery.size = this.size === 'small' ? 4 : 6
      this.tableData = []
      postData('/api/sys/notice/paging', this.listQuery).then(res => {
        this.tableData = res.data.records
      })
    },

    // 新页面打开详情
    toDetail(id) {
      window.open(`/web/notice/detail/${id}`)
    }
  }
}
</script>

<style scoped>
.t-link a{
  color: #1890ff;
  font-weight: 700;
}

.t-link a:hover{
  color: #ff8000;
}

.size-normal{
  height: 326px
}
.size-small{
  height: 231px
}
</style>
