import { post } from '@/utils/request'

/**
 * 增加积分
 * @param data
 * @returns {Promise}
 */
export function incrPoints(data) {
  return post('/api/user/points/incr', data)
}

/**
 * 扣减积分
 * @param data
 * @returns {Promise}
 */
export function subPoints(data) {
  return post('/api/user/points/sub', data)
}

/**
 * 积分日志分页
 * @param data
 * @returns {Promise}
 */
export function logPaging(data) {
  return post('/api/user/points/my-paging', data)
}

