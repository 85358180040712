<template>
  <div class="box-inner" @click="toLecturerHome(data.id)">
    <div class="image-2x3">
      <el-image :src="data.image">
        <div slot="error">
          <el-image :src="defaultCover" />
        </div>
      </el-image>
    </div>
    <div style="padding-top: 10px" @click="toLecturerHome(data.id)">
      <div class="font-title c-link">{{ data.realName }}</div>
      <div class="font-small">{{ data.title }}</div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'LecturerCard',
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      // 默认封面
      defaultCover: require('@/assets/web/default_cs.png')
    }
  },

  created() {

  },
  methods: {
    // 讲师页面
    toLecturerHome(id) {
      this.$router.push({ name: 'LecturerHome', params: { id: id }})
    }
  }
}
</script>

<style scoped>
.image-2x3{
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 120%;
  cursor: pointer;
  .el-image{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}
</style>
