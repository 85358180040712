<template>

  <el-tabs v-model="activeCat" style="width: 100%" @tab-click="rootClick">
    <el-tab-pane v-for="item in listData" :name="item.value" :key="item.value" :label="item.title">

      <div style="display: flex; align-items: center">
        <div class="sub-item">
          <a :class="{'active': !lastSub }" href="#" @click="allClick">全部</a>
        </div>

        <div v-for="child in item.children" :key="child.value" class="sub-item">
          <a :class="{'active': lastSub === child.value }" @click="subClick(child.value)">{{ child.title }}</a>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>

</template>

<script>

import { fetchTree } from '@/api/sys/dict/value'


export default {
  name: 'CourseCatalog',
  props: {
    value: String
  },
  data() {
    return {
      activeCat: '',
      lastRoot: '',
      lastSub: '',
      listData: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.backFill(val)
      }
    }
  },

  created() {
    this.initData()
  },
  methods: {
    // 查找轮播图
    initData() {
      fetchTree({ dicCode: 'course_catalog' }).then(res => {
        this.listData = res.data
        if (this.listData.length > 0) {
          this.activeCat = this.listData[0].value
        }
        // 回填
        this.backFill(this.value)
      })
    },

    // 回显数据
    backFill(val) {
      if (!val) {
        return
      }

      for (let i = 0; i < this.listData.length; i++) {
        const root = this.listData[i]
        if (root.value === val) {
          this.lastRoot = root.value
          this.activeCat = root.value
        }

        const children = root.children
        if (children && children.length > 0) {
          for (let j = 0; j< children.length; j++) {
            const child = children[j]
            if (child.value === val) {
              this.lastSub = child.value
              this.lastRoot = root.value
              this.activeCat = root.value
            }
          }
        }
      }

      this.$forceUpdate()
    },

    // 主类点击
    rootClick(tab) {
      if (this.lastRoot !== tab.name) {
        this.$emit('input', tab.name)
      }
      this.lastRoot = tab.name
      this.lastSub = ''
    },

    // 全部点击
    allClick() {
      this.$emit('input', this.lastRoot)
      this.lastSub = ''
    },

    // 子项点击
    subClick(value) {
      this.$emit('input', value)
      this.lastSub = value
    }
  }
}
</script>

<style scoped>

.sub-item{
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
}

.sub-item a{
  color: #666;
  line-height: 32px;
}

.sub-item a:hover{
  color: #C6302B;
}

.sub-item > .active{
  font-weight: 700;
  background: #C6302B;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
}

.sub-item > .active:hover{
  color: #fbbe0b;
}

</style>
