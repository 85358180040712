var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-grid",
        {
          ref: "dataGrid",
          attrs: { options: _vm.options, "list-query": _vm.listQuery },
          scopedSlots: _vm._u([
            {
              key: "data-item",
              fn: function (ref) {
                var data = ref.data
                return [_c("goods-card", { attrs: { data: data } })]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "filter-content" },
            [
              _c("dic-catalog-tree", {
                staticClass: "filter-item",
                attrs: {
                  "dic-code": "mall_goods_catalog",
                  title: "搜索分类",
                  width: "200",
                },
                model: {
                  value: _vm.listQuery.params.catId,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "catId", $$v)
                  },
                  expression: "listQuery.params.catId",
                },
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px", "margin-left": "10px" },
                attrs: { size: "small", placeholder: "搜索商品" },
                model: {
                  value: _vm.listQuery.params.q,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery.params, "q", $$v)
                  },
                  expression: "listQuery.params.q",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }