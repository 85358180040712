import { post } from '@/utils/request'


/**
 * 提交申请
 * @param data
 */
export function saveApply(data) {
  return post('/api/lecturer/lecturer/save-apply', data)
}

/**
 * 查找申请
 * @param data
 */
export function checkApply() {
  return post('/api/lecturer/lecturer/check-apply', {})
}

/**
 * 添加讲师
 * @param data
 */
export function add(data) {
  return post('/api/lecturer/lecturer/add', data)
}

/**
 * 添加或修改讲师
 * @param data
 */
export function save(data) {
  return post('/api/lecturer/lecturer/save', data)
}


/**
 * 审核讲师
 * @param data
 */
export function audit(data) {
  return post('/api/lecturer/lecturer/audit', data)
}


/**
 * 启用
 * @param data
 */
export function enable(data) {
  return post('/api/lecturer/lecturer/enable', data)
}


/**
 * 禁用
 * @param data
 */
export function disable(data) {
  return post('/api/lecturer/lecturer/disable', data)
}


/**
 * 查找分页数据
 * @param data
 */
export function paging(data) {
  return post('/api/lecturer/lecturer/paging', data)
}


/**
 * 讲师详情
 * @param id
 */
export function lecturerDetail(id) {
  return post('/api/lecturer/lecturer/detail', { id: id })
}
