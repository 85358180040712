import { post } from '@/utils/request'

export function fetchDetail(type, provider) {
  if (!provider) {
    provider = ''
  }

  return post('/api/sys/config/prop/detail', { type: type, provider: provider })
}

export function saveData(data) {
  return post('/api/sys/config/prop/save', data)
}

/**
 * 切换服务商
 * @param data
 * @returns {Promise}
 */
export function change(data) {
  return post('/api/sys/config/prop/change', data)
}
