import store from '@/store'

export default {
  inserted(el, binding, vnode) {
    const { value } = binding

    // 空白表示无需权限
    if (value.join('') === '') {
      return true
    }

    const permissions = store.getters && store.getters.permissions

    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value

      const hasPermission = permissions.some(role => {
        return permissionRoles.includes(role)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('无相关权限！')
    }
  }
}
