var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "white-box", staticStyle: { "line-height": "32px" } },
      [
        _c("div", { staticClass: "font-title" }, [
          _vm._v(_vm._s(_vm.detail.title)),
        ]),
        _c("div", { staticClass: "font-second flex" }, [
          _c("div", [
            _c("span", { staticStyle: { "font-weight": "700" } }, [
              _vm._v("正在学习："),
            ]),
            _vm._v(" " + _vm._s(_vm.current.title) + " "),
          ]),
          _c("div", { staticStyle: { "margin-left": "50px" } }, [
            _c("span", { staticStyle: { "font-weight": "700" } }, [
              _vm._v("已学时长："),
            ]),
            _vm._v(" " + _vm._s(_vm.current.learnSec) + " "),
          ]),
          _c("div", { staticStyle: { "margin-left": "50px" } }, [
            _c("span", { staticStyle: { "font-weight": "700" } }, [
              _vm._v("总体进度："),
            ]),
            _vm._v(" " + _vm._s(_vm.calcPercent(_vm.current)) + "% "),
          ]),
        ]),
        _c("el-progress", {
          attrs: {
            percentage: _vm.calcPercent(_vm.current),
            "show-text": false,
            "stroke-width": 3,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "flex-bt white-box",
        staticStyle: { "margin-top": "10px", "align-items": "flex-start" },
      },
      [
        _c(
          "div",
          { staticClass: "box-content" },
          [
            _vm.current.fileType === "office" || _vm.current.fileType === "pdf"
              ? _c("pdf-frame", {
                  attrs: {
                    course: _vm.detail,
                    check: _vm.detail.checkData,
                    file: _vm.current,
                  },
                  on: { change: _vm.learnChange, finish: _vm.learnFinish },
                })
              : _vm._e(),
            _vm.current.fileType === "video"
              ? _c("file-player", {
                  attrs: {
                    course: _vm.detail,
                    check: _vm.detail.checkData,
                    file: _vm.current,
                  },
                  on: { change: _vm.learnChange, finish: _vm.learnFinish },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box-catalog" },
          _vm._l(_vm.detail.dirList, function (dir) {
            return _c(
              "div",
              { key: dir.id },
              [
                _c("course-dir-learn", {
                  attrs: {
                    dir: dir,
                    "file-id": _vm.fileId,
                    "course-id": _vm.courseId,
                  },
                  on: { select: _vm.toFile },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "white-box", staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.tabActive,
              callback: function ($$v) {
                _vm.tabActive = $$v
              },
              expression: "tabActive",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "课程问答", name: "detail" } },
              [
                _c("course-qa", {
                  attrs: { "course-id": _vm.courseId, "file-id": _vm.fileId },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }