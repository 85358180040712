var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "show-close": true,
        title: "订单详情",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "all-box" }, [
        _c(
          "div",
          { staticClass: "left-box" },
          [
            _vm.detail.delivery && _vm.detail.delivery.id
              ? _c(
                  "el-card",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, border: "", title: "订单信息" } },
                      [
                        _vm.detail.delivery.sendType === 1
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "物流名称" } },
                              [_vm._v(_vm._s(_vm.detail.delivery.expressName))]
                            )
                          : _vm._e(),
                        _vm.detail.delivery.sendType === 1
                          ? _c(
                              "el-descriptions-item",
                              { attrs: { label: "运单编号" } },
                              [_vm._v(_vm._s(_vm.detail.delivery.expressNo))]
                            )
                          : _vm._e(),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "发货信息" } },
                          [_vm._v(_vm._s(_vm.detail.delivery.remark))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.detail.contact || _vm.detail.mobile || _vm.detail.address
              ? _c(
                  "el-card",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c(
                      "el-descriptions",
                      { attrs: { column: 1, border: "", title: "收货信息" } },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "联系人" } },
                          [_vm._v(_vm._s(_vm.detail.contact))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "手机号" } },
                          [_vm._v(_vm._s(_vm.detail.mobile))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "地址" } },
                          [_vm._v(_vm._s(_vm.detail.address))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-card",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "el-descriptions",
                  { attrs: { column: 1, border: "", title: "订单详情" } },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "订单编号" } },
                      [_vm._v(_vm._s(_vm.detail.id))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "下单时间" } },
                      [_vm._v(_vm._s(_vm.detail.createTime))]
                    ),
                    _vm.detail.remark
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "订单备注" } },
                          [_vm._v(_vm._s(_vm.detail.remark))]
                        )
                      : _vm._e(),
                    _vm.detail.state === 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "取消时间" } },
                          [_vm._v(_vm._s(_vm.detail.cancelTime))]
                        )
                      : _vm._e(),
                    _vm.detail.state === 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "取消说明" } },
                          [_vm._v(_vm._s(_vm.detail.cancelRemark))]
                        )
                      : _vm._e(),
                    _vm.detail.state > 0 && _vm.detail.state < 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付方式" } },
                          [_vm._v(_vm._s(_vm.detail.payMethod_dictText))]
                        )
                      : _vm._e(),
                    _vm.detail.state > 0 && _vm.detail.state < 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付时间" } },
                          [_vm._v(_vm._s(_vm.detail.payTime))]
                        )
                      : _vm._e(),
                    _vm.detail.state > 0 && _vm.detail.state < 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付积分" } },
                          [_vm._v(_vm._s(_vm.detail.totalPoints))]
                        )
                      : _vm._e(),
                    _vm.detail.state > 0 && _vm.detail.state < 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "支付价格" } },
                          [_vm._v(_vm._s(_vm.detail.totalAmount))]
                        )
                      : _vm._e(),
                    _vm.detail.state > 1 && _vm.detail.state < 100
                      ? _c(
                          "el-descriptions-item",
                          { attrs: { label: "发货时间" } },
                          [_vm._v(_vm._s(_vm.detail.sendTime))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "right-box" }, [
          _c(
            "div",
            { staticClass: "goods-box" },
            _vm._l(_vm.detail.goodsList, function (item) {
              return _c("div", { key: item.id }, [
                _c("div", { staticClass: "list" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: item.goodsImage },
                  }),
                  _c("div", { staticClass: "desc-box" }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.goodsTitle)),
                      ]),
                      _c("div", { staticClass: "num" }, [
                        _vm._v("*" + _vm._s(item.totalCount)),
                      ]),
                    ]),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticClass: "sku-t" }, [
                        _vm._v(_vm._s(item.skuTitle)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "sku-p" },
                        [
                          _c("price-show", {
                            attrs: {
                              "cost-points": item.totalPoints,
                              "sale-price": item.totalAmount,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }