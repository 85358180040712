var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.visible
        ? _c(
            "div",
            [
              _c("video-player", {
                ref: "player",
                attrs: {
                  drag: _vm.check.videoDrag,
                  "ref-id": "read_" + _vm.course.id + "_" + _vm.file.fileId,
                },
                on: {
                  play: _vm.onPlay,
                  pause: _vm.onPause,
                  ended: _vm.onEnded,
                },
                model: {
                  value: _vm.file.viewUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.file, "viewUrl", $$v)
                  },
                  expression: "file.viewUrl",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.file.fileId && _vm.visible && _vm.playing
        ? _c("read-socket", {
            attrs: { "file-id": _vm.file.fileId, "course-id": _vm.course.id },
            on: {
              change: _vm.changeLearnMin,
              finish: _vm.handleFinish,
              break: _vm.checkBreak,
            },
          })
        : _vm._e(),
      _vm.file.fileId && _vm.visible && _vm.playing && _vm.check.checkOn
        ? _c("read-check", {
            attrs: { "time-min": _vm.check.checkSec },
            on: { break: _vm.checkBreak },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }