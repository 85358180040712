<template>


  <div>

    <div class="white-box" style="line-height: 32px">
      <div class="font-title">{{ detail.title }}</div>
      <div class="font-second flex">
        <div>
          <span style="font-weight: 700">正在学习：</span>
          {{ current.title }}
        </div>

        <div style="margin-left: 50px">
          <span style="font-weight: 700">已学时长：</span>
          {{ current.learnSec }}
        </div>

        <div style="margin-left: 50px">
          <span style="font-weight: 700">总体进度：</span>
          {{ calcPercent(current) }}%
        </div>

      </div>
      <el-progress :percentage="calcPercent(current)" :show-text="false" :stroke-width="3"/>
    </div>

    <div class="flex-bt white-box" style="margin-top: 10px; align-items: flex-start">

      <!-- 课件阅读区域 -->
      <div class="box-content">
        <pdf-frame
          v-if="current.fileType==='office' || current.fileType==='pdf' "
          :course="detail"
          :check="detail.checkData"
          :file="current"
          @change="learnChange"
          @finish="learnFinish"
        />

        <file-player
          v-if="current.fileType==='video'"
          :course="detail"
          :check="detail.checkData"
          :file="current"
          @change="learnChange"
          @finish="learnFinish"
        />

      </div>

      <!-- 课件目录结构 -->
      <div class="box-catalog">
        <div v-for="dir in detail.dirList" :key="dir.id">
          <course-dir-learn
            :dir="dir"
            :file-id="fileId"
            :course-id="courseId"
            @select="toFile" />
        </div>

      </div>

    </div>

    <div class="white-box" style="margin-top: 20px">
      <el-tabs v-model="tabActive">
        <el-tab-pane label="课程问答" name="detail">
          <course-qa :course-id="courseId" :file-id="fileId"/>
        </el-tab-pane>
      </el-tabs>
    </div>


  </div>

</template>

<script>

import { detailForLearn, fileForLearn } from '@/api/course/course'
import CourseQa from './components/CourseQa/index.vue'
import { mapGetters } from 'vuex'
import PdfFrame from '@/views/pages/course/components/PdfFrame/index.vue'
import CourseDirShow from '@/views/pages/course/components/CourseDirShow/index.vue'
import CourseDirLearn from '@/views/pages/course/components/CourseDirLearn/index.vue'
import FilePlayer from '@/views/pages/course/components/FilePlayer/index.vue'
import CourseScore from '@/views/pages/course/components/CourseScore/index.vue'


export default {
  components: { CourseScore, FilePlayer, CourseDirLearn, CourseDirShow, PdfFrame, CourseQa },
  data() {
    return {
      tabActive: 'detail',
      detail: {
        dirList: []
      },
      current: {},
      courseId: null,
      fileId: null,
      focus: ''
    }
  },
  computed: {
    ...mapGetters([
      'userId'
    ])
  },

  watch: {
    // 参数变化重新获取文件
    '$route.query.fileId'(val) {
      this.fileId = val
      this.fetchFile()
    }
  },

  created() {
    // 课程ID
    this.courseId = this.$route.query.courseId
    this.fileId = this.$route.query.fileId
    this.focus = this.$route.query.focus
    if (!this.focus) {
      this.focus = 'first'
    }

    if (this.courseId) {
      this.fetchCourse()
    }

    // 设置分类和预览区域高度一致
    this.$nextTick(() => {
      setTimeout(() => {
        const rect = document.querySelector('.box-content').getBoundingClientRect()
        const cat = document.querySelector('.box-catalog')
        cat.style.height = rect.height + 'px'
      }, 500)
    })
  },
  methods: {

    // 学习进度变化
    learnChange(data) {
      const file = this.findFile(this.fileId)
      // 同步进度
      this.$set(file, 'learnSec', data.learnSec)
      this.current.learnSec = data.learnSec
    },

    // 完成某个课件
    learnFinish(data) {
      // 当前课件学完
      if (data.current) {
        // 进度100%
        const file = this.findFile(this.fileId)
        file.learnSec = file.needLearn
        this.current.learnSec = this.current.needLearn
      }
      // 全部学完
      if (data.all) {
        this.detail.finished = true
      }
      // 解锁下一个课件
      if (data.unlockFile) {
        const next = this.findFile(data.unlockFile)
        if (next) {
          next.unlocked = true
        }
      }
    },

    calcPercent(file) {
      let rate = 0
      if (file.learnSec > 0 && file.needLearn > 0) {
        rate = file.learnSec * 100 / file.needLearn
      }
      if (rate > 100) {
        rate = 100
      }
      return parseInt(rate)
    },


    // 查找课程信息
    fetchCourse() {
      detailForLearn(this.courseId).then(res => {
        // 查找课程概览
        this.detail = res.data
        // 查找单个课件
        this.fetchFile()
      })
    },

    // 查找课件
    fetchFile() {
      if (!this.fileId) {
        // 跳转到首个
        const file = this.findFile(this.focus)
        if (!file) {
          this.$message.error('没有可以学习的课程喽！')
          setTimeout(() => {
            this.$router.push('/')
          }, 1500)
        } else {
          this.toFile(file)
        }
        return
      }

      // 查询详情
      fileForLearn(this.courseId, this.fileId).then(res => {
        this.current = res.data
      }).catch(() => {
        // 可能无权限学习
        this.fileId = ''
        this.fetchFile()
      })
    },

    // 跳转到页面去学习
    toFile(file) {
      if (!file || (!file.unlocked && !file.tryLearn)) {
        return
      }
      this.$router.push({ name: 'CourseLearn', query: { courseId: this.courseId, fileId: file.fileId }})
    },

    /**
     * 在文件目录结构中查询对应文件ID的文件
     * @param fileId
     * @returns {Promise<*|undefined>}
     */
    findFile(fileId) {
      return this.cycleFile(this.detail.dirList, fileId)
    },

    /**
     * 递归查找文件
     * @param dirList
     * @param fileId
     * @returns {Promise<*|undefined>}
     */
    cycleFile(dirList, fileId) {
      let last = null

      for (let i = 0; i < dirList.length; i++) {
        const fileList = dirList[i].fileList
        if (fileList && fileList.length > 0) {
          for (let j = 0; j < fileList.length; j++) {
            const file = fileList[j]
            if (file.fileId === fileId) {
              return file
            }
            if (fileId === 'first' && (file.unlocked || file.tryLearn)) {
              return file
            }
            if (fileId === 'last' && (file.unlocked || file.tryLearn)) {
              last = file
            }
          }
        }
        const subDirs = dirList[i].children
        if (subDirs && subDirs.length > 0) {
          const file = this.cycleFile(subDirs, fileId)
          if (file) {
            last = file
          }
        }
      }
      return last
    }


  }
}
</script>

<style scoped>

.box-catalog{
  overflow: auto;
  width: 300px;
  background: #363636;
  line-height: 32px;
  padding: 10px;
  margin-left: 10px;
  min-height: 30vh;
}

.box-content{
  flex-grow: 1;
  min-height: 30vh;
}

</style>
