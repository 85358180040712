<template>
  <div style="flex-grow: 1">
    <div class="nv-title">网校讲师</div>
    <div class="grid-box grid-box-4">
      <div v-for="item in listData" :key="item.id" class="grid-box-inner">
        <lecturer-card :data="item" />
      </div>
    </div>
  </div>
</template>

<script>

import { listLecturer } from '@/api/home/recommend'
import LecturerCard from '@/components/LecturerCard/index.vue'
import CourseCard from '@/components/CourseCard/index.vue'


export default {
  name: 'HomeLecturer',
  components: { CourseCard, LecturerCard },
  data() {
    return {
      listData: []
    }
  },

  created() {
    this.initData()
  },
  methods: {
    initData() {
      listLecturer({ position: 'home_lecturer', size: 4 }).then(res => {
        this.listData = res.data
      })
    }
  }
}
</script>
