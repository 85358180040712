<template>

  <div>
    <tracking-cam
      ref="cam"
      :tracking="true"
      :track-capture="false"
      :show-track-tips="false"
      @error="handleCamError"
      @face-out="faceOut"
      @started="startTimer"
      @capture="handleCapture"/>
  </div>

</template>

<script>
import { saveCapture } from '@/api/course/face'
import { localToBase64 } from '@/utils/base64'

export default {
  name: 'CourseCam',
  components: {
  },
  props: {
    faceInterval: {
      type: Number,
      default: 0
    },
    courseId: {
      type: String,
      default: ''
    },
    fileId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 定时截图参数
      timer: null,
      intervalSec: 999,
      camError: false
    }
  },

  watch: {
    camError: {
      handler() {
        // 错误也重新计时
        console.log('摄像头错误...')
        this.startTimer()
      }
    }
  },

  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  },

  created() {
    // 操作检测
    this.fill()
  },

  methods: {

    fill() {
      // 操作检测
      if (this.faceInterval > 0) {
        this.intervalSec = this.faceInterval * 60000
      }
    },

    // 开启定时截屏
    startTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        // 摄像头出错
        if (this.camError) {
          this.fillEmpty()
        } else {
          this.$refs.cam.capture()
        }
      }, this.intervalSec)
    },

    handleCapture(base64) {
      // 保存数据
      const data = { courseId: this.courseId, fileId: this.fileId, base64: base64 }
      saveCapture(data).then(res => {
        console.log('++++++++++ capture saved', res)
        const data = res.data

        // 达到上限
        if (data.isBreak) {
          this.$emit('break')
          this.$message.error(`连续${data.failCount}次人脸识别失败！`)
          return
        }

        // 还有机会
        if (data.failCount > 0) {
          this.$message.error(`人脸识别已失败${data.failCount}次，连续失败${data.faceChance}次将退出学习！`)
        }
      })
    },

    faceOut() {
      this.$message.error(`请认真学习，确保人脸在摄像头中央！`)
    },
    // 摄像头错误
    handleCamError() {
      this.camError = true
      console.log('摄像头错误了！')
    },

    // 填充空白数据
    fillEmpty() {
      const file = require('@/assets/web/no-cam.png')
      localToBase64(file).then(res => {
        this.handleCapture(res)
      })
    }

  }
}
</script>

