import { post } from '@/utils/request'

/**
 * 证书详情
 * @param id
 * @returns {*}
 */
export function fetchDetail(id) {
  return post('/api/cert/cert/detail', { id: id })
}

/**
 * 证书分页列表
 * @param id
 * @returns {*}
 */
export function fetchPaging(data) {
  return post('/api/cert/cert/paging', data)
}

/**
 * 保存证书
 * @param data
 */
export function saveData(data) {
  return post('/api/cert/cert/save', data)
}
