var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    attrs: {
      "start-placeholder": _vm.startHolder || "开始时间",
      "end-placeholder": _vm.endHolder || "结束时间",
      size: "small",
      format: "yyyy-MM-dd HH:mm:ss",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      type: "datetimerange",
      "range-separator": "至",
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v
      },
      expression: "dateRange",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }