<template>

  <el-container class="container-bg">

    <el-header style="padding: 0; height: 60px">
      <web-header />
    </el-header>

    <el-main style="padding: 0;">
      <div class="container main-inner">
        <app-main />
      </div>
    </el-main>
    <el-footer v-if="siteData.copyRight" class="footer-copy" style="height: auto">

      <div class="container">
        <div class="footer-box">
          <div class="footer-box-inner">
            <el-image :src="siteData.frontLogo" style="height: 50px"/>
          </div>
          <div class="footer-box-inner">
            <div style="color: #fff; font-weight: 700; font-size: 16px">系统指引</div>
            <div style="color: #ccc">
              <a href="/pages/lecturer/apply">讲师入驻</a>
            </div>
            <div style="color: #ccc">
              <a href="/pages/notice">系统公告</a>
            </div>
          </div>
          <div class="footer-box-inner">
            <div style="color: #fff; font-weight: 700; font-size: 16px">联系我们</div>
            <div style="color: #ccc">电话：18710213152（杨）18603038204（郭）</div>
            <div style="color: #ccc">邮箱：626264481@qq.com</div>
            <div style="color: #ccc">地址：北京市朝阳区十里堡路1号未来时大厦4层</div>
          </div>
          <div class="footer-box-inner">
            <div style="color: #fff; font-weight: 700; font-size: 16px">关注我们</div>
            <div class="flex-bt">
              <el-image :src="siteData.mpCode" style="height: 100px"/>
            </div>

          </div>
        </div>

        <div style="text-align: center; margin-top: 20px; font-size: 14px" v-html="siteData.copyRight">
          &copy;2024
        </div>


      </div>


    </el-footer>
  </el-container>

</template>

<script>
import { mapGetters } from 'vuex'
import { AppMain, WebHeader } from './components'

export default {
  name: 'WebLayout',
  components: {
    AppMain,
    WebHeader
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  }
}
</script>

<style scoped>

  ::v-deep .app-container{
    padding: 0;
  }

  /*页面背景*/
  .container-bg{
    background: #F7F9FE
  }

  /*内容页面样式*/
  .app-main {
    min-height: calc(100vh - 190px);
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  ::v-deep .el-pagination{
    text-align: center;
  }

  ::v-deep .el-card__header{
    font-size: 14px;
    font-weight: 700;
  }
  ::v-deep .el-card__body{
    font-size: 14px;
    line-height: 24px;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #C6302B
  }

  ::v-deep .el-tabs__active-bar{
    background-color: #C6302B
  }

  .main-inner {
    margin-top: 20px;
  }


  ::v-deep a:hover{
    color: #ff0000;
  }

  .footer-copy{
    display:flex;
    align-items:center;
    justify-content: center;
    color: #A3A8B0;
    font-size: 16px;
    background: #333;
    padding:30px 0;
    margin-top: 20px;
  }
  .footer-box{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .footer-box-inner{
    line-height: 36px;
    padding: 10px 0;
    font-size: 14px;
  }
  .footer-box-4{
    grid-template-columns: repeat(4, calc((100% - 45px) / 4))
  }


</style>

