<template>

  <div v-if="images && images.length > 0">
    <div>
      <VueSlickCarousel
        ref="c1"
        :as-nav-for="c2"
        :focus-on-select="true">
        <img v-for="item in images" :src="item" :key="item" >
      </VueSlickCarousel>
    </div>

    <div class="sub-c">
      <VueSlickCarousel
        ref="c2"
        :as-nav-for="c1"
        :slides-to-show="8"
        :focus-on-select="true"
      >
        <img v-for="item in images" :key="item" :src="item" class="thumbnail-item" >
      </VueSlickCarousel>
    </div>
  </div>

</template>

<script>

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'CarouselShow',
  components: { VueSlickCarousel },
  props: {
    images: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      c1: null,
      c2: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.c1 = this.$refs.c1
        this.c2 = this.$refs.c2
      }, 500)
    })
  }
}
</script>

<style scoped>

::v-deep .slick-prev:before, ::v-deep .slick-next:before{
  color: #888 !important;
  font-size: 40px;
  z-index: 9999;
}

::v-deep .slick-prev, ::v-deep .slick-next{
  z-index: 999999;
  width: 100px;
}

::v-deep .slick-slide, ::v-deep .slick-track, ::v-deep .slick-slide > div{
  display: flex;
  align-items: center;
  justify-content: center !important;
}

::v-deep .sub-c .slick-current img{
  border: #e67e23 1px solid;
}

.sub-c{
  padding: 5px 0
}

.sub-c .thumbnail-item{
  border: #f1f1f1 1px solid;
  cursor: pointer;
}

::v-deep .sub-c .slick-slide > div{
  display: flex;
  align-items: center;
  justify-content: center !important;
  padding-right: 10px;
}
</style>
