<template>
  <div style="flex-grow: 1">
    <el-carousel :interval="4000" trigger="click" height="350px" arrow="always">
      <el-carousel-item v-for="item in listData" :key="item.id">
        <el-image :src="item.banner" class="banner-img" fit="cover" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

import { fetchPaging } from '@/api/home/banner'


export default {
  name: 'HomeBanner',
  data() {
    return {
      listData: []
    }
  },

  created() {
    this.initData()
  },
  methods: {
    // 查找轮播图
    initData() {
      fetchPaging({ current: 1, size: 10, params: { platform: 'pc' }}).then(res => {
        this.listData = res.data.records
      })
    }
  }
}
</script>

<style scoped>
.banner-img{
  height: 350px;
  width: 100%;
  border-radius: 0 3px 3px 0;
}
</style>
