import { post } from '@/utils/request'


/**
 * 讲师创建课程
 * @param data
 * @returns {*}
 */
export function preCreate(data) {
  return post('/api/course/course/pre-create', data)
}

/**
 * 提审或撤回审核
 * @param data
 * @returns {*}
 */
export function auditSubmit(data) {
  return post('/api/course/audit/submit', data)
}


/**
 * 最近的审核信息
 * @param id
 * @returns {*}
 */
export function auditMsg(id) {
  return post('/api/course/audit/last-info', { id: id })
}

/**
 * 课程上下架处理
 * @param data
 * @returns {*}
 */
export function putOnSell(data) {
  return post('/api/course/course/put-on-sell', data)
}


