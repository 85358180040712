<template>

  <div>
    <data-table
      ref="pagingTable"
      :options="options"
      :list-query="listQuery"
    >
      <template #filter-content>
        <dic-list-select v-model="listQuery.params.settleState" dic-code="settle_state" title="结算状态" />
        <date-time-picker
          :start-time.sync="listQuery.params.startTime"
          :end-time.sync="listQuery.params.endTime"
          start-holder="结算时间"
          class="filter-item"/>
      </template>

      <template #data-columns>


        <el-table-column
          label="结算周期"
          align="center"
          width="300px"
        >

          <template v-slot="{row}">
            {{ row.startTime }} - {{ row.endTime }}
          </template>

        </el-table-column>


        <el-table-column
          label="订单数量"
          align="center"
          width="100px"
        >

          <template v-slot="{row}">
            <el-link type="primary" @click="showDetail(row.id)">{{ row.totalCount }}</el-link>
          </template>

        </el-table-column>

        <el-table-column
          label="订单总金额"
          prop="totalAmount"
          align="center"
          width="100px"
        />

        <el-table-column
          label="应结算金额"
          prop="settleAmount"
          align="center"
          width="100px"
        />

        <el-table-column
          label="结算状态"
          align="center"
          prop="settleState_dictText"
          width="100px"
        />


        <el-table-column
          label="结算时间"
          align="center"
          show-overflow-tooltip
        >

          <template v-slot="{row}">
            <span v-if="row.settleTime">{{ row.settleTime }}</span>
            <span v-else>--</span>
          </template>

        </el-table-column>

      </template>
    </data-table>

    <settle-detail-dialog :visible.sync="dialogVisible" :bill-id="billId" />
  </div>

</template>

<script>
import DicListSelect from '@/components/DicListSelect/index.vue'
import CourseSelect from '@/components/CourseSelect/index.vue'
import SettleDetailDialog from '@/views/admin/lecturer/settle/components/SettleDetailDialog.vue'
import DateTimePicker from '@/components/DateTimePicker/index.vue'

export default {
  components: { DateTimePicker, SettleDetailDialog, CourseSelect, DicListSelect },
  data() {
    return {
      billId: null,
      dialogVisible: false,

      listQuery: {
        current: 1,
        size: 10,
        params: {
          settleState: '',
          startTime: '',
          endTime: ''
        }
      },

      options: {

        showSummary: true,
        // 可批量操作
        multi: true,

        // 列表请求URL
        listUrl: '/api/settle/order/lecturer-paging'
      }
    }
  },
  methods: {
    // 显示账单
    showDetail(id) {
      this.billId = id
      this.dialogVisible = true
    }
  }
}
</script>
