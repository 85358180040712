<template>

  <div class="flex-bt">
    <div>
      <div class="font-title">{{ data.refRemark }}</div>
      <div class="font-small">{{ data.createTime }}</div>
    </div>
    <div style="font-size: 16px">
      <span v-if="data.points > 0" class="points points-in">
        + {{ data.points }}
      </span>
      <span v-else class="points points-out">
        {{ data.points }}
      </span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'PointsLogCard',
  props: {
    data: Object
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.points{
  width: 100px;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
}

.points-in{
  color: #FBBE0F;
}

.points-out{
  color: #ff0000;
}
</style>
