<template>

  <div>

    <el-tabs v-model="fileType">
      <el-tab-pane label="全部" name="all" />
      <el-tab-pane label="视频" name="video" />
      <el-tab-pane label="文档" name="office" />
      <el-tab-pane label="PDF" name="pdf" />
    </el-tabs>


    <div style="display: flex;">
      <div style="width: 300px">
        <media-catalog-edit ref="tree" :space-id="userId" @checked="catalogChecked"/>
      </div>
      <div style="flex-grow: 1; padding-left: 10px; overflow-x: auto">
        <media-file-edit :space-id="userId" :cat-id="catId" :file-type="fileType" />
      </div>
    </div>

  </div>

</template>

<script>
import MediaCatalogEdit from '@/components/MediaManage/MediaCatalogEdit/index.vue'
import { mapGetters } from 'vuex'
import MediaFileEdit from '@/components/MediaManage/MediaFileEdit/index.vue'

export default {
  name: 'MediaManage',
  components: { MediaFileEdit, MediaCatalogEdit },
  props: {
    spaceId: String
  },
  data() {
    return {
      catId: '',
      fileType: 'all'
    }
  },


  computed: {
    ...mapGetters([
      'userId'
    ])
  },

  watch: {

  },

  created() {

  },

  methods: {

    catalogChecked(id) {
      this.catId = id
    }
  }
}
</script>

<style scoped>

</style>
