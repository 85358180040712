var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, title: "审核记录", width: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("data-table", {
        ref: "dataTable",
        attrs: { options: _vm.options, "list-query": _vm.listQuery },
        scopedSlots: _vm._u([
          {
            key: "data-columns",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: { label: "审核人员", prop: "auditBy_dictText" },
                }),
                _c("el-table-column", {
                  attrs: { label: "审核时间", prop: "auditTime" },
                }),
                _c("el-table-column", {
                  attrs: { label: "审核结果", prop: "auditState_dictText" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "审核消息",
                    prop: "auditMsg",
                    "show-overflow-tooltip": "",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }