var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "postForm",
          attrs: {
            model: _vm.postForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "150px",
          },
        },
        [
          _c("h3", [_vm._v("存储配置")]),
          _c("el-alert", {
            attrs: {
              type: "error",
              title:
                "请务必提前确定好服务商，投入使用后切换服务商无法自动迁移文件，并且可能造成部分服务异常，请谨慎操作！",
            },
          }),
          _c(
            "el-form-item",
            { attrs: { label: "方案类型", prop: "provider" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.postForm.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.postForm, "provider", $$v)
                    },
                    expression: "postForm.provider",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "local" } }, [
                    _vm._v("本地存储"),
                  ]),
                  _c("el-radio", { attrs: { label: "aliyun" } }, [
                    _vm._v("阿里云OSS"),
                  ]),
                  _c("el-radio", { attrs: { label: "qcloud" } }, [
                    _vm._v("腾讯云"),
                  ]),
                  _c("el-radio", { attrs: { label: "qiniu" } }, [
                    _vm._v("七牛云"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.postForm.provider === "local"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "本地目录", prop: "data.localDir" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "本地目录地址，如：/data/upload/ 或 D:/data/file/",
                        },
                        model: {
                          value: _vm.postForm.data.localDir,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "localDir", $$v)
                          },
                          expression: "postForm.data.localDir",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            size: "small",
                            placeholder:
                              "以https://或http://开头的本地接口域名或CDN加速域名，以/结尾",
                          },
                          model: {
                            value: _vm.postForm.data.url,
                            callback: function ($$v) {
                              _vm.$set(_vm.postForm.data, "url", $$v)
                            },
                            expression: "postForm.data.url",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("upload/file/"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "aliyun"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c("el-alert", {
                        attrs: {
                          type: "error",
                          title:
                            "必须使用RAM账号，账号拥有AliyunSTSAssumeRoleAccess、AliyunMTSFullAccess、AliyunIMMFullAccess权限，文档：https://ram.console.aliyun.com/roles",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "EndPoint", prop: "data.endpoint" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "aliyun_upload_endpoins" },
                        model: {
                          value: _vm.postForm.data.endpoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "endpoint", $$v)
                          },
                          expression: "postForm.data.endpoint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Bucket", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "阿里云存储容器" },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "AccessKeyId", prop: "data.accessKeyId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "授权ID" },
                        model: {
                          value: _vm.postForm.data.accessKeyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeyId", $$v)
                          },
                          expression: "postForm.data.accessKeyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "AccessKeySecret",
                        prop: "data.accessKeySecret",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "授权秘钥" },
                        model: {
                          value: _vm.postForm.data.accessKeySecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeySecret", $$v)
                          },
                          expression: "postForm.data.accessKeySecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色ARN", prop: "data.arn" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "角色ARN，此角色应拥有写入新文件的权限：AliyunOSSFullAccess或oss:PutObject",
                        },
                        model: {
                          value: _vm.postForm.data.arn,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "arn", $$v)
                          },
                          expression: "postForm.data.arn",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "OSS访问域名或CDN加速域名，必须以/结尾，如：https://xxxx.oss-cn-beijing.aliyuncs.com/",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "qiniu"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储空间位置", prop: "data.endpoint" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "qiniu_upload_areas" },
                        model: {
                          value: _vm.postForm.data.endpoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "endpoint", $$v)
                          },
                          expression: "postForm.data.endpoint",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储空间名称", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "阿里云存储容器" },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "AccessKeyId", prop: "data.accessKeyId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "AccessKeyId" },
                        model: {
                          value: _vm.postForm.data.accessKeyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeyId", $$v)
                          },
                          expression: "postForm.data.accessKeyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "AccessKeySecret",
                        prop: "data.accessKeySecret",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "AccessKeySecret",
                        },
                        model: {
                          value: _vm.postForm.data.accessKeySecret,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "accessKeySecret", $$v)
                          },
                          expression: "postForm.data.accessKeySecret",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "外链域名，必须以/结尾，如：http://qozlc75sb.hn-bkt.clouddn.com/",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.postForm.provider === "qcloud"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属地域", prop: "data.region" } },
                    [
                      _c("dic-list-select", {
                        staticStyle: { width: "100%" },
                        attrs: { "dic-code": "qcloud_upload_endpoints" },
                        model: {
                          value: _vm.postForm.data.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "region", $$v)
                          },
                          expression: "postForm.data.region",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储桶名称", prop: "data.bucket" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "腾讯云存储桶" },
                        model: {
                          value: _vm.postForm.data.bucket,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "bucket", $$v)
                          },
                          expression: "postForm.data.bucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretId", prop: "data.secretId" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "secretId" },
                        model: {
                          value: _vm.postForm.data.secretId,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretId", $$v)
                          },
                          expression: "postForm.data.secretId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SecretKey", prop: "data.secretKey" } },
                    [
                      _c("el-input", {
                        attrs: { size: "small", placeholder: "secretKey" },
                        model: {
                          value: _vm.postForm.data.secretKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "secretKey", $$v)
                          },
                          expression: "postForm.data.secretKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问URL", prop: "data.url" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder:
                            "COS访问域名或CDN加速域名，必须以/结尾，如：https://yfhl-1252868612.cos.ap-beijing.myqcloud.com/",
                        },
                        model: {
                          value: _vm.postForm.data.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.postForm.data, "url", $$v)
                          },
                          expression: "postForm.data.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "module-course" },
            [
              _c("el-divider"),
              _c("h3", [_vm._v("视频转码")]),
              _vm.postForm.provider === "local"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "转码方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.postForm.data.encodeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postForm.data, "encodeType", $$v)
                                },
                                expression: "postForm.data.encodeType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "none" } }, [
                                _vm._v("不转码"),
                              ]),
                              _c("el-radio", { attrs: { label: "ffmpeg" } }, [
                                _vm._v("FFmpeg本地转码"),
                              ]),
                            ],
                            1
                          ),
                          _vm.postForm.data.encodeType === "none"
                            ? _c("el-alert", {
                                attrs: {
                                  type: "error",
                                  title:
                                    "支持的视频格式视浏览器而定，一般通用的都支持：mp4、webm 和 ogg，建议上传H264编码的mp4文件；",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.postForm.data.encodeType === "ffmpeg"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "FFmpeg路径" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "small",
                                      placeholder:
                                        "FFmpeg安装目录，如：/opt/homebrew/bin/ffmpeg",
                                    },
                                    model: {
                                      value: _vm.postForm.data.ffmpegHome,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm.data,
                                          "ffmpegHome",
                                          $$v
                                        )
                                      },
                                      expression: "postForm.data.ffmpegHome",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            href: "https://ffmpeg.org/download.html",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "必须在服务器安装ffmpeg才能使用转码,下载地址为：https://ffmpeg.org/download.html"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "转码方式" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "small" },
                                      model: {
                                        value: _vm.postForm.data.videoEncode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm.data,
                                            "videoEncode",
                                            $$v
                                          )
                                        },
                                        expression: "postForm.data.videoEncode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "mp4" } },
                                        [_vm._v("MP4")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "m3u8" } },
                                        [_vm._v("HLS(M3U8)")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "m3u8_encode" } },
                                        [_vm._v("HLS(M3U8)加密")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.postForm.data.videoEncode === "mp4"
                                    ? _c("el-alert", {
                                        attrs: {
                                          type: "error",
                                          title:
                                            "转换为H264编码的mp4格式，兼容性最好，网页小程序都能流畅播放；",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.postForm.data.videoEncode === "m3u8"
                                    ? _c("el-alert", {
                                        attrs: {
                                          type: "error",
                                          title:
                                            "对文件进行切片，转换为m3u8格式，可以一定程度防止下载；",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.postForm.data.videoEncode ===
                                  "m3u8_encode"
                                    ? _c("el-alert", {
                                        attrs: {
                                          type: "error",
                                          title:
                                            "对文件进行切片，并转换为AES-128加密的m3u8格式，安全性最高；注意：小程序和IOS设备无法播放，要兼容请勿选择此方案",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.postForm.data.videoEncode === "m3u8_encode"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "视频解密地址" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.postForm.data.decodeUrl,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm.data,
                                                  "decodeUrl",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.data.decodeUrl",
                                            },
                                          }),
                                          _c("el-alert", {
                                            attrs: {
                                              type: "info",
                                              title:
                                                "应为：/api/course/file/decrypt 或 https://您的域名/api/course/file/decrypt",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "视频加密文件" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.postForm.data.encodeKey,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm.data,
                                                  "encodeKey",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.data.encodeKey",
                                            },
                                          }),
                                          _c("el-alert", {
                                            attrs: {
                                              type: "info",
                                              title:
                                                "使用命令【openssl rand 16 > enc.key】命令生成一个文件，上传到服务器，并填写此地址，如：/upload/enc.key",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "视频加密秘钥" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.postForm.data.encodeIv,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm.data,
                                                  "encodeIv",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.data.encodeIv",
                                            },
                                          }),
                                          _c("el-alert", {
                                            attrs: {
                                              type: "info",
                                              title:
                                                "加密随机秘钥，使用命令【openssl rand -hex 16】生成",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "播放解密AES-KEY" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder:
                                                "16位解密KEY，前端播放器使用",
                                            },
                                            model: {
                                              value: _vm.postForm.data.aesKey,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm.data,
                                                  "aesKey",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "postForm.data.aesKey",
                                            },
                                          }),
                                          _c("el-alert", {
                                            attrs: {
                                              type: "info",
                                              title:
                                                "16位AES解密KEY，前端播放器使用",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "播放解密AES-IV" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.postForm.data.aesIv,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.postForm.data,
                                                  "aesIv",
                                                  $$v
                                                )
                                              },
                                              expression: "postForm.data.aesIv",
                                            },
                                          }),
                                          _c("el-alert", {
                                            attrs: {
                                              type: "info",
                                              title:
                                                "16位AES-解密IV，前端播放器使用",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "转码偏好" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "small" },
                                      model: {
                                        value: _vm.postForm.data.videoQuality,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postForm.data,
                                            "videoQuality",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postForm.data.videoQuality",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "original" } },
                                        [_vm._v("与原视频一致")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "480p" } },
                                        [_vm._v("标清(854x480)")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "720p" } },
                                        [_vm._v("高清(1280x720)")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1080p" } },
                                        [_vm._v("全高清(1920x1080)")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "2k" } },
                                        [_vm._v("2K(2048x1152)")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.postForm.provider === "aliyun" ||
              _vm.postForm.provider === "qcloud" ||
              _vm.postForm.provider === "qiniu"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "转码方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.postForm.data.encodeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.postForm.data, "encodeType", $$v)
                                },
                                expression: "postForm.data.encodeType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "none" } }, [
                                _vm._v("不转码"),
                              ]),
                              _c("el-radio", { attrs: { label: "online" } }, [
                                _vm._v("云转码"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.postForm.data.encodeType === "online"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "转码方式" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.postForm.data.videoEncode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.postForm.data,
                                        "videoEncode",
                                        $$v
                                      )
                                    },
                                    expression: "postForm.data.videoEncode",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "mp4" } }, [
                                    _vm._v("MP4"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "m3u8" } }, [
                                    _vm._v("HLS(M3U8)"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.postForm.provider === "aliyun"
                ? _c(
                    "div",
                    [
                      _vm.postForm.data.encodeType === "online"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "转码样式" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.postForm.data.videoQuality,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.postForm.data,
                                        "videoQuality",
                                        $$v
                                      )
                                    },
                                    expression: "postForm.data.videoQuality",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "original" } },
                                    [_vm._v("与原视频一致")]
                                  ),
                                  _c("el-radio", { attrs: { label: "480p" } }, [
                                    _vm._v("标清(854x480)"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "720p" } }, [
                                    _vm._v("高清(1280x720)"),
                                  ]),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "1080p" } },
                                    [_vm._v("全高清(1920x1080)")]
                                  ),
                                  _c("el-radio", { attrs: { label: "2k" } }, [
                                    _vm._v("2K(2048x1152)"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.postForm.provider === "qcloud"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "媒体处理列队ID" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: "用于视频转换的媒体处理列队ID",
                            },
                            model: {
                              value: _vm.postForm.data.mediaQueue,
                              callback: function ($$v) {
                                _vm.$set(_vm.postForm.data, "mediaQueue", $$v)
                              },
                              expression: "postForm.data.mediaQueue",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.postForm.data.encodeType === "online"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "转码样式" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.postForm.data.videoQuality,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.postForm.data,
                                        "videoQuality",
                                        $$v
                                      )
                                    },
                                    expression: "postForm.data.videoQuality",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "480p" } }, [
                                    _vm._v("标清(720*等比例高)"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "720p" } }, [
                                    _vm._v("高清(1280*等比例高)"),
                                  ]),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "1080p" } },
                                    [_vm._v("全高清(1920*等比例高)")]
                                  ),
                                  _c("el-radio", { attrs: { label: "2k" } }, [
                                    _vm._v("2K(2048*等比例高)"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.postForm.provider === "qiniu"
                ? _c(
                    "div",
                    [
                      _vm.postForm.data.encodeType === "online"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "转码样式" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.postForm.data.videoQuality,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.postForm.data,
                                        "videoQuality",
                                        $$v
                                      )
                                    },
                                    expression: "postForm.data.videoQuality",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "original" } },
                                    [_vm._v("与原视频一致")]
                                  ),
                                  _c("el-radio", { attrs: { label: "480p" } }, [
                                    _vm._v("标清(854x480)"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "720p" } }, [
                                    _vm._v("高清(1280x720)"),
                                  ]),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "1080p" } },
                                    [_vm._v("全高清(1920x1080)")]
                                  ),
                                  _c("el-radio", { attrs: { label: "2k" } }, [
                                    _vm._v("2K(2048x1152)"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "el-icon-refresh",
                    type: "danger",
                    size: "small",
                  },
                  on: { click: _vm.changProvider },
                },
                [_vm._v("使用此服务商")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    icon: "el-icon-folder",
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("保存配置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }