var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.value === "video"
        ? _c("svg-icon", {
            attrs: { title: "视频文件", "icon-class": "file-video" },
          })
        : _vm.value === "pdf"
        ? _c("svg-icon", { attrs: { "icon-class": "file-pdf" } })
        : _vm.value === "office"
        ? _c("svg-icon", { attrs: { "icon-class": "file-office" } })
        : _vm.value === "image"
        ? _c("svg-icon", { attrs: { "icon-class": "file-image" } })
        : _c("svg-icon", { attrs: { "icon-class": "file-other" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }