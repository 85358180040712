var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        title: "提现详情",
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-descriptions",
        { staticClass: "margin-top", attrs: { column: 1, border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "提现时间" } }, [
            _vm._v(" " + _vm._s(_vm.detail.createTime) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "提现金额" } }, [
            _vm._v(" " + _vm._s(_vm.detail.totalAmount) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手续费" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.detail.taxAmount) +
                " (" +
                _vm._s(_vm.detail.taxRate) +
                " %) "
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "实际应付" } }, [
            _vm._v(" " + _vm._s(_vm.detail.payableAmount) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "银行名称" } }, [
            _vm._v(" " + _vm._s(_vm.detail.bankName) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "分行名称" } }, [
            _vm._v(" " + _vm._s(_vm.detail.bankBranch) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "银行卡号" } }, [
            _vm._v(" " + _vm._s(_vm.detail.bankCard) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "持卡姓名" } }, [
            _vm._v(" " + _vm._s(_vm.detail.bankHolder) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "提现状态" } }, [
            _vm._v(" " + _vm._s(_vm.detail.state_dictText) + " "),
          ]),
          _c("el-descriptions-item", { attrs: { label: "支付时间" } }, [
            _vm.detail.payTime
              ? _c("span", [_vm._v(_vm._s(_vm.detail.payTime))])
              : _c("span", [_vm._v("--")]),
          ]),
          _vm.detail.state === 2
            ? _c("el-descriptions-item", { attrs: { label: "拒付理由" } }, [
                _c("span", { staticStyle: { color: "#ff3333" } }, [
                  _vm._v(_vm._s(_vm.detail.remark)),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }