var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fileId != null
    ? _c("web-socket", {
        attrs: {
          url:
            "/api/socket/read/" +
            _vm.userId +
            "/" +
            _vm.courseId +
            "/" +
            _vm.fileId,
        },
        on: { receive: _vm.onMessage },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }