var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "flex-grow": "1" } },
    [
      _c(
        "el-carousel",
        {
          attrs: {
            interval: 4000,
            trigger: "click",
            height: "350px",
            arrow: "always",
          },
        },
        _vm._l(_vm.listData, function (item) {
          return _c(
            "el-carousel-item",
            { key: item.id },
            [
              _c("el-image", {
                staticClass: "banner-img",
                attrs: { src: item.banner, fit: "cover" },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }