var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [_c("course-edit-base", { attrs: { "course-id": _vm.courseId } })],
      1
    ),
    _c("h3", [_vm._v("课程安排")]),
    _c(
      "div",
      { staticClass: "content-box", staticStyle: { display: "flex" } },
      [
        _c(
          "div",
          { staticStyle: { width: "300px" } },
          [
            _c("course-edit-tree", {
              attrs: { "course-id": _vm.courseId, "dir-id": _vm.dirId },
              on: { checked: _vm.dirChange },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "flex-grow": "1",
              "border-left": "#f5f5f5 1px solid",
            },
          },
          [
            _c("course-edit-file", {
              attrs: { "course-id": _vm.courseId, "dir-id": _vm.dirId },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }