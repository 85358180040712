<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="创建课程"
    width="50%"
    @close="handleClose"
  >

    <el-form ref="postForm" :model="postForm" :rules="rules" label-position="left" label-width="120px">

      <el-form-item label="课程名称" prop="title">
        <el-input v-model="postForm.title" style="width: 100%" size="small" />
      </el-form-item>

      <el-form-item label="课程分类" prop="catId">
        <dic-catalog-tree v-model="postForm.catId" dic-code="course_catalog" />
      </el-form-item>

      <el-form-item label="课程封面" prop="cover">
        <file-upload
          v-model="postForm.cover"
          :limit="1"
          :compress="500"
          tips="请上传大于2:1的宽图"
          list-type="picture-card"
          accept=".jpg,.jpeg,.png"/>
      </el-form-item>

      <el-form-item label="课程定价" prop="price">
        <el-input-number v-model="postForm.price" :min="0.1" :max="9999999" :precision="2" size="small" />
        <tool-tip msg="如果定价为0，则为公开课程，任何人都可以进行学习" />
      </el-form-item>

      <el-form-item label="课程描述" prop="content">
        <Tinymce ref="editor" v-model="postForm.content" :height="200" />
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleSave">确 定</el-button>
    </div>

  </el-dialog>

</template>

<script>
import { preCreate } from '@/api/lecturer/course'

export default {
  name: 'CourseCreateDialog',
  props: {
    visible: Boolean
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      postForm: {

      },
      rules: {
        title: [{ required: true, message: '课程标题不能为空！' }],
        catId: [{ required: true, message: '课程类目必须选择！' }],
        cover: [{ required: true, message: '课程封面必须上传！' }],
        price: [{ required: true, message: '课程定价不能为空！' }],
        content: [{ required: true, message: '课程描述不能为空！' }]
      }
    }
  },

  watch: {
    // 属性变化
    visible: {
      handler() {
        this.dialogVisible = this.visible
      }
    }
  },

  created() {
    this.dialogVisible = this.visible
  },

  methods: {


    handleClose() {
      this.$emit('update:visible', false)
    },

    // 保存数据
    handleSave() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        preCreate(this.postForm).then(res => {
          this.$message.success('添加成功，正在进入排课页面..')
          this.toEdit(res.data)
        })
      })
    },

    toEdit(courseId) {
      setTimeout(() => {
        this.handleClose()

        // 清理并跳转
        this.postForm = {}
        this.$refs.postForm.clearValidate()

        this.$router.push({ name: 'LecturerCourseEdit', query: { courseId: courseId }})
      }, 1500)
    }

  }
}
</script>
