<template>
  <div class="cat-box">
    <div v-for="item in listData" :key="item.value" class="cat-item">
      <a @click="toCourseList(item.value)">{{ item.title }}</a>
      <div v-if="item.children && item.children.length > 0" class="child-box">
        <a v-for="child in item.children" :key="child.value" @click="toCourseList(child.value)">{{ child.title }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import { fetchTree } from '@/api/sys/dict/value'


export default {
  name: 'HomeCatalog',
  data() {
    return {
      listData: []
    }
  },

  created() {
    this.initData()
  },
  methods: {
    // 查找轮播图
    initData() {
      fetchTree({ dicCode: 'course_catalog' }).then(res => {
        this.listData = res.data
      })
    },
    toCourseList(catId) {
      this.$router.push({ name: 'CourseIndex', query: { catId: catId }})
    }
  }
}
</script>

<style scoped>
.cat-box{
  width: 250px;
  background: #353535;
  height: 350px;
  overflow-y: auto;
  border-radius: 3px 0 0 3px;
}
.cat-item{
  border-bottom: #6666 1px solid;
  line-height: 32px;
  color: #eee;
  padding: 5px 15px;
  font-weight: 700;
  font-size: 14px;
}

.cat-item a{
  color: #fff;
}

.cat-item a:hover{
  color: #FBBE0F;
}

.cat-item:hover{
  background: #C6302B;
  border-bottom: #E70013 1px solid;
}

.cat-item > .child-box{
  display: flex;
  flex-wrap: wrap;
  line-height: 24px;
  justify-content: space-between;
}

.cat-item > .child-box a{
  font-size: 12px;
  color: #888;
  font-weight: normal;
  margin-right: 5px;
  flex-shrink: 0;
}

.cat-item > .child-box a:hover{
  color: #FBBE0F;
}
</style>
