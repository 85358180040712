import { post } from '@/utils/request'

/**
 * 收藏或取消
 * @param courseId
 * @returns {*}
 */
export function saveFav(courseId) {
  return post('/api/course/fav/save', { courseId: courseId })
}

/**
 * 查找收藏
 * @param courseId
 * @returns {*}
 */
export function findFav(courseId) {
  return post('/api/course/fav/find', { courseId: courseId })
}
