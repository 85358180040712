<template>

  <!-- 支付弹窗 -->
  <pay-dialog
    :visible.sync="dialogVisible"
    :order-id="payOrder"
    @close="dialogClose"
    @success="payBack"
  />

</template>

<script>

import { buy } from '@/api/course/course'

export default {
  name: 'CourseBuy',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    courseId: String
  },
  data() {
    return {
      dialogVisible: false,
      payOrder: null
    }
  },

  watch: {

    // 检测查询变化
    visible: {
      handler(val) {
        this.dialogVisible = val
        if (this.dialogVisible) {
          this.handleBuy()
        }
      },
      deep: true
    }
  },

  created() {

  },
  methods: {

    // 创建预付费订单
    handleBuy() {
      // 不重复创建订单
      if (this.payOrder) {
        this.dialogVisible = true
        return
      }

      buy(this.courseId).then(res => {
        this.payOrder = res.data
        this.dialogVisible = true
      })
    },
    dialogClose() {
      this.$emit('update:visible', false)
    },
    payBack() {
      // 支付成功回调
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }

  }
}
</script>
