var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.fileType,
            callback: function ($$v) {
              _vm.fileType = $$v
            },
            expression: "fileType",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "全部", name: "all" } }),
          _c("el-tab-pane", { attrs: { label: "视频", name: "video" } }),
          _c("el-tab-pane", { attrs: { label: "文档", name: "office" } }),
          _c("el-tab-pane", { attrs: { label: "PDF", name: "pdf" } }),
        ],
        1
      ),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { staticStyle: { width: "300px" } },
          [
            _c("media-catalog-edit", {
              ref: "tree",
              attrs: { "space-id": _vm.userId },
              on: { checked: _vm.catalogChecked },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "flex-grow": "1",
              "padding-left": "10px",
              "overflow-x": "auto",
            },
          },
          [
            _c("media-file-edit", {
              attrs: {
                "space-id": _vm.userId,
                "cat-id": _vm.catId,
                "file-type": _vm.fileType,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }