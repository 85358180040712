<template>

  <div>

    <el-row>

      <el-col :lg="16" :md="10" class="left hidden-sm-and-down">
        <img src="../../assets/web/images/login2.png" style="height: 45vh">
      </el-col>

      <el-col :lg="8" :md="14" class="right">

        <div class="box">

          <el-tabs v-model="activeName">
            <el-tab-pane label="注册新账号" name="account">

              <el-form ref="postForm" :model="postForm" :rules="rules">

                <el-form-item v-if="siteData.props.userDeptType===0" prop="deptCode">
                  <reg-depart-select v-model="postForm.deptCode" icon="el-icon-school" />
                </el-form-item>

                <el-form-item prop="userName">
                  <el-input
                    v-model="postForm.userName"
                    style="width: 100%"
                    placeholder="登录账号"
                    prefix-icon="el-icon-mobile"
                  />
                </el-form-item>

                <el-form-item prop="realName">
                  <el-input
                    v-model="postForm.realName"
                    style="width: 100%"
                    placeholder="姓名"
                    prefix-icon="el-icon-user"
                  />
                </el-form-item>

                <el-form-item prop="password">
                  <el-input
                    v-model="postForm.password"
                    style="width: 100%"
                    placeholder="密码"
                    type="password"
                    prefix-icon="el-icon-lock"
                    show-password
                  />
                </el-form-item>

                <yf-captcha ref="captcha" v-model="postForm" />

                <el-form-item>
                  <el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="handleReg">注册</el-button>
                </el-form-item>

              </el-form>

            </el-tab-pane>

          </el-tabs>

          <div style="text-align: right; line-height: 10px">
            <el-link href="/login" style="margin-left: 10px">已有账号？</el-link>
          </div>

        </div>

      </el-col>

    </el-row>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import RegDepartSelect from '@/views/login/components/RegDepartSelect.vue'

export default {
  name: 'Register',
  components: { RegDepartSelect },

  data() {
    return {
      activeName: 'account',
      postForm: {
        captchaKey: '',
        captchaValue: ''
      },
      rules: {
        userName: [{ required: true, message: '账号不能为空！' }],
        password: [{ required: true, message: '登录密码不能为空！' }],
        deptCode: [{ required: true, message: '部门必须选择！' }],
        realName: [{ required: true, message: '姓名不能为空！' }],
        captchaValue: [{ required: true, message: '验证码不能为空' }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {}
    }
  },
  computed: {
    ...mapGetters([
      'siteData'
    ])
  },

  methods: {

    async handleReg() {
      this.$refs.postForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$store.dispatch('user/reg', this.postForm)
          .then(() => {
            // 跳转到首页
            this.$router.push('/')
            this.loading = false
          })
          .catch(err => {
            if (err.message === '10010012') {
              this.$refs.captcha.changeCode()
            }
            this.loading = false
          })
      })
    }
  }
}
</script>
