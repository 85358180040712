<template>

  <el-select
    v-model="currentValue"
    :remote-method="fetchData"
    filterable
    remote
    clearable
    placeholder="选择或搜索模板"
    class="filter-item"
    style="width: 100%"
    @change="handlerChange"
    @clear="fetchData('')"
  >
    <el-option
      v-for="item in dataList"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>

</template>

<script>

import { fetchPaging } from '@/api/notify/tmpl'

export default {
  name: 'MsgTmplSelect',
  props: {
    multi: Boolean,
    value: String
  },
  data() {
    return {
      // 下拉选项值
      dataList: [],
      currentValue: []
    }
  },

  watch: {
    // 检测查询变化
    value: {
      handler() {
        this.currentValue = this.value
      }
    }
  },
  created() {
    this.currentValue = this.value
    this.fetchData()
  },
  methods: {

    fetchData(v) {
      const params = { current: 1, size: 100, params: { title: v }}
      fetchPaging(params).then(response => {
        this.dataList = response.data.records
      })
    },
    handlerChange(e) {
      this.$emit('change', e)
      this.$emit('input', e)
    }
  }
}
</script>
